/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentStatus,
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './PaymentStatus';

/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    uniqueId?: string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof PaymentDto
     */
    status?: PaymentStatus;
}

export function PaymentDtoFromJSON(json: any): PaymentDto {
    return PaymentDtoFromJSONTyped(json, false);
}

export function PaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
        'status': !exists(json, 'status') ? undefined : PaymentStatusFromJSON(json['status']),
    };
}

export function PaymentDtoToJSON(value?: PaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uniqueId': value.uniqueId,
        'status': PaymentStatusToJSON(value.status),
    };
}

