import DisplayNullableData from "components/DisplayNullableData";
import React from "react";
import MaterialIcon from "../MaterialIcon/MaterialIcon";

interface DataLineProps {
  iconPath: string;
  label: string;
  value?: string | number | Date | boolean | null;
}

const DataLine: React.FC<DataLineProps> = ({
  iconPath,
  label,
  value,
  children,
}) => (
  <div className="flex items-end">
    <MaterialIcon
      path={iconPath}
      className="h-4 mr-4 mb-1 relative bottom-px"
    />
    <div>
      <div className="text-xs text-formGroupLabel">{label}</div>
      <DisplayNullableData value={value}>{children}</DisplayNullableData>
    </div>
  </div>
);

export default DataLine;
