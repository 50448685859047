import clsx from "clsx";
import React from "react";
import BackgroundImage from "assets/images/background-circle-curtain-r5.svg";

const DebtCardBg: React.FC = () => {
  return (
    <>
      <div
        className={clsx(
          "absolute h-full w-full top-0 left-0 rounded-xl opacity-25",
          "mix-blend-multiply bg-gradient-to-b from-white to-black pointer-events-none"
        )}
      />
      <img
        src={BackgroundImage}
        alt="Debt"
        className="h-40 max-w-none absolute top-0 left-0 pointer-events-none select-none"
      />
    </>
  );
};

export default DebtCardBg;
