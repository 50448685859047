import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

const DisplayNumberFormat: React.FC<NumberFormatProps> = ({
  thousandSeparator = ".",
  decimalSeparator = ",",
  displayType = "text",
  ...rest
}) => {
  return (
    <NumberFormat
      {...rest}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      displayType={displayType}
    />
  );
};

export default DisplayNumberFormat;
