import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
}

const Radio: React.FC<RadioProps> = ({ children, value, name, ...rest }) => {
  const [field] = useField(name || "");

  return (
    <div className="my-4 ml-4">
      <div className="flex">
        <label className="inline-flex items-start cursor-pointer">
          <input
            {...field}
            {...rest}
            value={value}
            checked={value === field.value}
            type="radio"
            className="h-4.5 w-4.5 rounded-full shadow-formControl border-formControl text-primary cursor-pointer"
          />
          <span className="ml-4 leading-none mt-2px relative top-px">
            {children}
          </span>
        </label>
      </div>
    </div>
  );
};

export default Radio;
