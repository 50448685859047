/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersionDto
 */
export interface VersionDto {
    /**
     * 
     * @type {number}
     * @memberof VersionDto
     */
    major?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionDto
     */
    minor?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionDto
     */
    patch?: number;
}

export function VersionDtoFromJSON(json: any): VersionDto {
    return VersionDtoFromJSONTyped(json, false);
}

export function VersionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'major': !exists(json, 'major') ? undefined : json['major'],
        'minor': !exists(json, 'minor') ? undefined : json['minor'],
        'patch': !exists(json, 'patch') ? undefined : json['patch'],
    };
}

export function VersionDtoToJSON(value?: VersionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'major': value.major,
        'minor': value.minor,
        'patch': value.patch,
    };
}

