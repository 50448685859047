import { combineReducers } from "@reduxjs/toolkit";
import { changePasswordReducer } from "./ChangePassword/changePassword.slice";
import { settingsHomeReducer } from "./Home/settingsHome.slice";
import { preferredCommunicationReducer } from "./PreferredCommunication/preferredCommunication.slice";
import { privacyConsentReducer } from "./PrivacyConsent/privacyConsent.slice";

const settingsReducers = combineReducers({
  changePassword: changePasswordReducer,
  preferredCommunication: preferredCommunicationReducer,
  privacyConsent: privacyConsentReducer,
  settingsHome: settingsHomeReducer,
});

export default settingsReducers;
