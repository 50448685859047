/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePaymentPlanCommand
 */
export interface CreatePaymentPlanCommand {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentPlanCommand
     */
    debtId: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentPlanCommand
     */
    outstandingBalance?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentPlanCommand
     */
    instalmentCount: number;
    /**
     * 
     * @type {Date}
     * @memberof CreatePaymentPlanCommand
     */
    firstInstalmentDueDate: Date;
}

export function CreatePaymentPlanCommandFromJSON(json: any): CreatePaymentPlanCommand {
    return CreatePaymentPlanCommandFromJSONTyped(json, false);
}

export function CreatePaymentPlanCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePaymentPlanCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'debtId': json['debtId'],
        'outstandingBalance': !exists(json, 'outstandingBalance') ? undefined : json['outstandingBalance'],
        'instalmentCount': json['instalmentCount'],
        'firstInstalmentDueDate': (new Date(json['firstInstalmentDueDate'])),
    };
}

export function CreatePaymentPlanCommandToJSON(value?: CreatePaymentPlanCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'debtId': value.debtId,
        'outstandingBalance': value.outstandingBalance,
        'instalmentCount': value.instalmentCount,
        'firstInstalmentDueDate': (value.firstInstalmentDueDate.toISOString()),
    };
}

