import Content from "components/layout/Content";
import Alert from "components/ui/Alert/Alert";
import Button from "components/ui/Button/Button";
import Card from "components/ui/Card/Card";
import { H3 } from "components/ui/Headings/Headings";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { registrationParentPath } from "../RegistrationRouteSwitch";

const RegistrationTimedOut: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <Card
        footer={
          <div className="flex justify-between">
            <Button as={Link} to="/login" variant="outline">
              {t("registration.timedOut.backToLoginButton")}
            </Button>
            <Button as={Link} to={`/${registrationParentPath}`}>
              {t("registration.timedOut.restartRegistrationButton")}
            </Button>
          </div>
        }
      >
        <div className="md:w-80 mx-auto">
          <H3>{t("registration.timedOut.title")}</H3>
          <Alert type="error">{t("registration.timedOut.description")}</Alert>
        </div>
      </Card>
    </Content>
  );
};

export default RegistrationTimedOut;
