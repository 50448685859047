import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import {
  LanguageCode,
  LinkPaymentDebtDto,
  LinkPaymentResolutionDto,
  LinkPaymentStatusDto,
  PaymentStatus,
} from "api/generated/finastic";
import { AppThunk } from "app/store";
import { DebtPaymentFormValues } from "components/debtPayment/DebtPaymentOptions";
import { BackendCode } from "models/BackendCode";
import { FeatureState } from "models/FeatureState";

export interface PaymentLinkState {
  resolvePaymentState: FeatureState;
  getDebtState: FeatureState;
  initiatePaymentState: FeatureState;
  paymentState: FeatureState;
  debt?: LinkPaymentDebtDto;
  paymentData?: LinkPaymentStatusDto;
  backendCode?: BackendCode;
}

const initialState: PaymentLinkState = {
  resolvePaymentState: FeatureState.Initial,
  getDebtState: FeatureState.Initial,
  initiatePaymentState: FeatureState.Initial,
  paymentState: FeatureState.Loading,
  debt: undefined,
  paymentData: undefined,
  backendCode: undefined,
};

const paymentLink = createSlice({
  name: "paymentLink",
  initialState,
  reducers: {
    reset: () => initialState,
    setResolvePaymentState(state, { payload }: PayloadAction<FeatureState>) {
      state.resolvePaymentState = payload;
    },
    setGetDebtState(state, { payload }: PayloadAction<FeatureState>) {
      state.getDebtState = payload;
    },
    setInitiatePaymentState(state, { payload }: PayloadAction<FeatureState>) {
      state.initiatePaymentState = payload;
    },
    setPaymentState(state, { payload }: PayloadAction<FeatureState>) {
      state.paymentState = payload;
    },
    getDebtSuccess(state, { payload }: PayloadAction<LinkPaymentDebtDto>) {
      state.debt = payload;
    },
    getPaymentStatusSuccess(
      state,
      { payload }: PayloadAction<LinkPaymentStatusDto>
    ) {
      state.paymentData = payload;
    },
    setBackendCode(state, { payload }: PayloadAction<BackendCode>) {
      state.backendCode = payload;
    },
  },
});

const {
  reset,
  setResolvePaymentState,
  setGetDebtState,
  getDebtSuccess,
  setInitiatePaymentState,
  setPaymentState,
  getPaymentStatusSuccess,
  setBackendCode,
} = paymentLink.actions;

export const paymentLinkReducer = paymentLink.reducer;

const resolvePaymentLink =
  (
    paymentUuid: string,
    backendCode: string
  ): AppThunk<Promise<LinkPaymentResolutionDto>> =>
  async (dispatch, getState) => {
    dispatch(setResolvePaymentState(FeatureState.Loading));

    return api.finastic.linkPayment
      .apiFrontendV1LinkPaymentResolveGet({
        paymentUuid,
        backendCode,
      })
      .then((data) => {
        dispatch(setResolvePaymentState(FeatureState.Success));

        return data;
      })
      .catch((error) => {
        dispatch(setResolvePaymentState(FeatureState.Error));

        throw error;
      });
  };

const getDebt =
  (
    debtId: string | null,
    backendCode: string
  ): AppThunk<Promise<LinkPaymentDebtDto>> =>
  async (dispatch, getState) => {
    if (typeof debtId !== "string") {
      dispatch(setGetDebtState(FeatureState.Error));

      throw new Error("invalid debt id");
    }

    dispatch(setGetDebtState(FeatureState.Loading));

    return api.finastic.linkPayment
      .apiFrontendV1LinkPaymentDebtGet({ debtId, backendCode })
      .then((data) => {
        dispatch(getDebtSuccess(data));
        dispatch(setGetDebtState(FeatureState.Success));

        return data;
      })
      .catch((error) => {
        dispatch(setGetDebtState(FeatureState.Error));

        throw error;
      });
  };

const initiatePayment =
  (
    { amount, currencyCode, debtId, languageCode }: DebtPaymentFormValues,
    backendCode: string
  ): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    if (
      typeof amount !== "number" ||
      typeof currencyCode !== "string" ||
      typeof debtId !== "string"
    ) {
      return;
    }

    dispatch(setInitiatePaymentState(FeatureState.Loading));

    return api.finastic.linkPayment
      .apiFrontendV1LinkPaymentPost({
        startLinkPaymentCommand: {
          amount,
          currencyCode,
          debtId,
          languageCode: languageCode as LanguageCode,
          backendCode,
        },
      })
      .then((data) => {
        dispatch(setInitiatePaymentState(FeatureState.Success));

        return data;
      })
      .catch(() => dispatch(setInitiatePaymentState(FeatureState.Error)));
  };

const getPaymentStatus =
  (paymentId: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    return api.finastic.linkPayment
      .apiFrontendV1LinkPaymentPaymentIdGet({ paymentId })
      .then((data) => {
        dispatch(getPaymentStatusSuccess(data));

        if (data.status === PaymentStatus.Successful) {
          dispatch(setPaymentState(FeatureState.Success));
        } else if (
          data.status === PaymentStatus.Invalid ||
          data.status === PaymentStatus.Failed
        ) {
          dispatch(setPaymentState(FeatureState.Error));
        } else {
          dispatch(setPaymentState(FeatureState.Loading));
        }
      })
      .catch((error) => {
        console.error(error);

        dispatch(setPaymentState(FeatureState.Error));
      });
  };

export const paymentLinkActions = {
  resolvePaymentLink,
  getDebt,
  initiatePayment,
  reset,
  getPaymentStatus,
  setBackendCode,
};
