import React from "react";
import SidebarMenuItem, { SidebarMenuItemI } from "./SidebarMenuItem";

interface SidebarMenuProps {
  label?: string;
  items: SidebarMenuItemI[];
  isToggled?: boolean;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  items,
  label,
  isToggled = false,
}) => {
  return (
    <div className="pt-4 border-b border-sidebar">
      {label && <div className="text-xs text-label mb-4">{label}</div>}
      <ul>
        {items.map((item, index) => (
          <li key={index} className="mb-5 last:mb-4">
            <SidebarMenuItem {...item} isToggled={isToggled} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarMenu;
