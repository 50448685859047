import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { H6 } from "components/ui/Headings/Headings";
import { Form, Formik } from "formik";
import SliderFormik from "components/forms/SliderFormik";
import { useAppSelector } from "app/store";
import Skeleton from "components/ui/Skeleton/Skeleton";
import { useDispatch } from "react-redux";
import { debtPaymentPlanActions } from "../DebtPaymentPlan.slice";
import { StepWizardChildProps } from "react-step-wizard";
import Button from "components/ui/Button/Button";
import StepNavigation from "./StepNavigation";
import InstalmentNumberData from "./InstalmentNumberData";
import InstalmentCountHiddenField from "./InstalmentCountHiddenField";

export interface Step1FormData {
  instalmentCount: number;
  optionIndex: number[];
}

const Step1: React.FC<Partial<StepWizardChildProps>> = ({
  isActive,
  nextStep,
}) => {
  const { t } = useTranslation();
  const debt = useAppSelector((state) => state.debts.debtDetails.debt);
  const { paymentPlanOptionsData } = useAppSelector(
    (state) => state.debts.debtPaymentPlan
  );
  const dispatch = useDispatch();

  const sliderMinValue = 0;
  const sliderMaxValue =
    paymentPlanOptionsData?.paymentPlanOptions &&
    paymentPlanOptionsData.paymentPlanOptions.length > 1
      ? paymentPlanOptionsData.paymentPlanOptions.length - 1
      : 0;

  // We don't display slider value, but the option that is on the same index as slider's value
  const displayTooltipContent = useCallback(
    (indexes: number[] | undefined) => {
      if (
        !paymentPlanOptionsData?.paymentPlanOptions ||
        !Array.isArray(indexes)
      ) {
        return;
      }

      return paymentPlanOptionsData.paymentPlanOptions[indexes[0]]
        .instalmentCount;
    },
    [paymentPlanOptionsData?.paymentPlanOptions]
  );

  const displayBoundaryValue = useCallback(
    (value: number) => {
      return (
        paymentPlanOptionsData?.paymentPlanOptions?.[value].instalmentCount ??
        value
      );
    },
    [paymentPlanOptionsData?.paymentPlanOptions]
  );

  return (
    <>
      <div className="md:px-8 px-4">
        <H6>{t("debt.createPaymentPlanWizard.step1Title")}</H6>
      </div>
      <div className="mt-12">
        {paymentPlanOptionsData?.paymentPlanOptions ? (
          <Formik<Step1FormData>
            initialValues={{
              optionIndex: [Math.floor((sliderMinValue + sliderMaxValue) / 2)],
              instalmentCount: 0,
            }}
            onSubmit={(values, actions) => {
              dispatch(debtPaymentPlanActions.setForm1Data(values));

              nextStep?.();
            }}
          >
            {(formikProps) => (
              <>
                <div className="md:px-8 px-4 pb-4">
                  <Form>
                    <InstalmentCountHiddenField
                      name="instalmentCount"
                      paymentPlanOptions={
                        paymentPlanOptionsData.paymentPlanOptions
                      }
                    />
                    {isActive && (
                      <SliderFormik
                        min={sliderMinValue}
                        max={sliderMaxValue}
                        name="optionIndex"
                        displayTooltipContent={displayTooltipContent}
                        displayMinContent={displayBoundaryValue}
                        displayMaxContent={displayBoundaryValue}
                      />
                    )}
                  </Form>
                  <div className="mt-12">
                    <InstalmentNumberData
                      instalmentCount={formikProps.values.instalmentCount}
                      currencyCode={debt?.currencyCode}
                      paymentPlanOptions={
                        paymentPlanOptionsData.paymentPlanOptions
                      }
                    />
                  </div>
                </div>

                <StepNavigation
                  firstButton={
                    <Button
                      variant="outline"
                      block
                      onClick={() => dispatch(debtPaymentPlanActions.reset())}
                      type="button"
                    >
                      {t("common.back")}
                    </Button>
                  }
                  secondButton={
                    <Button
                      block
                      type="button"
                      onClick={() => formikProps.submitForm()}
                    >
                      {t("common.next")}
                    </Button>
                  }
                />
              </>
            )}
          </Formik>
        ) : (
          <div className="md:px-8 px-4 pb-4">
            <Skeleton />
          </div>
        )}
      </div>
    </>
  );
};

export default Step1;
