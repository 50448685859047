import clsx from "clsx";
import React from "react";

interface FormGroupLabelProps {
  children: React.ReactNode;
  required?: boolean;
  hasError?: boolean;
}

const FormGroupLabel: React.FC<FormGroupLabelProps> = ({
  children,
  required = false,
  hasError = false,
}) => {
  return (
    <span
      className={clsx("inline-block mb-1 text-xs", {
        "text-error": hasError,
        "text-formGroupLabel": !hasError,
      })}
    >
      {children}
      {required && <span className="text-error inline-block ml-1">*</span>}
    </span>
  );
};

export default FormGroupLabel;
