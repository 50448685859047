export enum LocalStorageKeys {
  Locale = "finastic-locale",
  AccessToken = "finastic-access-token",
  RefreshToken = "finastic-refresh-token",
  User = "finastic-user",
  UserId = "finastic-user-id",
}

export const getLocalStorageValue = (key: LocalStorageKeys): string | null =>
  localStorage.getItem(key);

export const setLocalStorageValue = (
  key: LocalStorageKeys,
  value: string
): void => localStorage.setItem(key, value);

export const removeLocalStorageValue = (key: LocalStorageKeys): void =>
  localStorage.removeItem(key);
