import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";
import { Step1FormData } from "./components/Step1";
import { Step2FormData } from "./components/Step2";
import { api } from "api";
import { PaymentPlanOptionsDto } from "api/generated/finastic";
import { RequestErrorBody } from "api/middleware/errorHandlingMiddleware";
import { Step3FormData } from "./components/Step3";

export enum CreatePlaymentErrorKey {
  InvalidInstalmentCount = "InvalidInstalmentCount",
  PaymentPlanCreationForbidden = "PaymentPlanCreationForbidden",
  InvalidInstalmentDate = "InvalidInstalmentDate",
  BackendValidationFailed = "BackendValidationFailed",
  PaymentPlanAlreadyExists = "PaymentPlanAlreadyExists",
  DebtBalanceMismatch = "DebtBalanceMismatch",
}

export interface DebtPaymentPlanState {
  showWizard: boolean;
  step1FormData?: Step1FormData;
  step2FormData?: Step2FormData;
  paymentPlanOptionsData?: PaymentPlanOptionsDto;
  paymentPlanOptionsDataState: FeatureState;
  createPlaymentPlanState: FeatureState;
  createPlaymentErrorKey?: CreatePlaymentErrorKey;
  debtId?: string;
}

const initialState: DebtPaymentPlanState = {
  showWizard: false,
  step1FormData: undefined,
  step2FormData: undefined,
  paymentPlanOptionsData: undefined,
  paymentPlanOptionsDataState: FeatureState.Initial,
  createPlaymentPlanState: FeatureState.Initial,
  createPlaymentErrorKey: undefined,
  debtId: undefined,
};

const debtPaymentPlan = createSlice({
  name: "debtPaymentPlan",
  initialState,
  reducers: {
    reset: () => initialState,
    setForm1Data(state, { payload }: PayloadAction<Step1FormData>) {
      state.step1FormData = payload;
    },
    setForm2Data(state, { payload }: PayloadAction<Step2FormData>) {
      state.step2FormData = payload;
    },
    setDebtId(state, { payload }: PayloadAction<string>) {
      state.debtId = payload;
    },
    setShowWizard(state, { payload }: PayloadAction<boolean>) {
      state.showWizard = payload;
    },
    setPaymentPlanOptionsDataState(
      state,
      { payload }: PayloadAction<FeatureState>
    ) {
      state.paymentPlanOptionsDataState = payload;
    },
    getPaymentPlanOptionsSuccess(
      state,
      { payload }: PayloadAction<PaymentPlanOptionsDto>
    ) {
      state.paymentPlanOptionsData = payload;
    },
    setCreatePlaymentPlanState(
      state,
      { payload }: PayloadAction<FeatureState>
    ) {
      state.createPlaymentPlanState = payload;
    },
    setCreatePlaymentErrorKey(
      state,
      { payload }: PayloadAction<CreatePlaymentErrorKey>
    ) {
      state.createPlaymentErrorKey = payload;
    },
  },
});

const {
  reset,
  setForm1Data,
  setForm2Data,
  setPaymentPlanOptionsDataState,
  getPaymentPlanOptionsSuccess,
  setShowWizard,
  setCreatePlaymentPlanState,
  setDebtId,
  setCreatePlaymentErrorKey,
} = debtPaymentPlan.actions;

export const debtPaymentPlanReducer = debtPaymentPlan.reducer;

const handleFeatureError = async (
  error: any,
  dispatch: AppDispatch
): Promise<void> => {
  const responseText = await error.response?.text();

  if (responseText === "") {
    throw error;
  }

  const errorJson: RequestErrorBody = JSON.parse(responseText);

  if (!errorJson.errors) {
    throw error;
  }

  Object.entries(CreatePlaymentErrorKey).forEach(([key, value]) => {
    if (Object.keys(errorJson.errors).includes(key)) {
      dispatch(setCreatePlaymentErrorKey(value));
    }
  });
};

const getPaymentPlanOptions =
  (debtId: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch(setPaymentPlanOptionsDataState(FeatureState.Loading));

    return api.finastic.paymentPlan
      .apiFrontendV1PaymentPlanOptionsGet({ debtId })
      .then((value) => {
        dispatch(getPaymentPlanOptionsSuccess(value));
        dispatch(setDebtId(debtId));

        if (value.firstPossibleInstalmentDueDate) {
          dispatch(
            setForm2Data({
              firstInstalmentDueDate: value.firstPossibleInstalmentDueDate,
            })
          );
        }

        dispatch(setPaymentPlanOptionsDataState(FeatureState.Success));
      })
      .catch((error) => {
        console.error(error);

        dispatch(setPaymentPlanOptionsDataState(FeatureState.Error));

        handleFeatureError(error, dispatch);

        throw error;
      });
  };

const createPlaymentPlan =
  ({ outstandingBalance }: Step3FormData): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    const { step1FormData, step2FormData, debtId } =
      getState().debts.debtPaymentPlan;

    if (!step1FormData || !step2FormData || !debtId) {
      throw Error("invalid");
    }

    dispatch(setCreatePlaymentPlanState(FeatureState.Loading));

    return api.finastic.paymentPlan
      .apiFrontendV1PaymentPlanPost({
        createPaymentPlanCommand: {
          firstInstalmentDueDate: step2FormData.firstInstalmentDueDate,
          instalmentCount: step1FormData.instalmentCount,
          debtId,
          outstandingBalance,
        },
      })
      .then(() => {
        dispatch(setCreatePlaymentPlanState(FeatureState.Success));
      })
      .catch((error) => {
        console.error(error);

        dispatch(setCreatePlaymentPlanState(FeatureState.Error));

        handleFeatureError(error, dispatch);

        throw error;
      });
  };

export const debtPaymentPlanActions = {
  setForm1Data,
  setForm2Data,
  getPaymentPlanOptions,
  reset,
  setShowWizard,
  createPlaymentPlan,
};
