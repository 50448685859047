import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { H3, H5 } from "../Headings/Headings";
import Button, { ButtonProps } from "../Button/Button";
import MaterialIcon from "../MaterialIcon/MaterialIcon";
import { mdiCheckBold, mdiAlert, mdiInformationOutline } from "@mdi/js";

export type ButtonPropsWithoutChildren = Omit<ButtonProps, "children">;

export interface ModalProps {
  type?: "default" | "alert";
  title?: string;
  description?: string;
  children?: React.ReactNode;
  size?: "auto" | "small" | "medium" | "large" | "xlarge";
  allowCloseOnOverlayClick?: boolean;
  isOpen: boolean;
  showCancelButton?: boolean;
  cancelButtonProps?: ButtonPropsWithoutChildren;
  okButtonProps?: ButtonPropsWithoutChildren;
  cancelText?: React.ReactNode;
  okText: React.ReactNode;
  alertType?: "success" | "error" | "info" | "warning";
  onOk?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const alertIcons = {
  success: mdiCheckBold,
  error: mdiAlert,
  info: mdiInformationOutline,
  warning: mdiAlert,
};

const Modal: React.FC<ModalProps> = ({
  type = "default",
  title,
  description,
  children,
  size = "small",
  allowCloseOnOverlayClick = false,
  isOpen = false,
  showCancelButton = false,
  cancelButtonProps,
  okButtonProps,
  cancelText,
  okText,
  alertType = "info",
  onOk,
  onCancel,
}) => {
  const [innerIsOpen, setInnerIsOpen] = useState(false);
  const okButtonRef = useRef(null);

  useEffect(() => {
    setInnerIsOpen(isOpen);
  }, [isOpen]);

  return (
    <Transition appear show={innerIsOpen} as={Fragment}>
      <Dialog
        open={innerIsOpen}
        onClose={() => allowCloseOnOverlayClick && setInnerIsOpen(false)}
        className="z-99999999999 fixed flex justify-center items-center inset-0 overflow-hidden py-4"
        initialFocus={okButtonRef}
      >
        <Dialog.Overlay className="opacity-75 fixed bg-modalOverlay inset-0" />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              "relative bg-white rounded-xl border border-main shadow mx-4 max-w-full w-full",
              "overflow-x-hidden overflow-y-auto max-h-full",
              {
                "md:max-w-cardSm": size === "small",
                "md:max-w-lg": size === "medium",
                "md:max-w-screen-sm": size === "large",
                "md:max-w-screen-md": size === "xlarge",
                "md:w-auto": size === "auto",
              }
            )}
          >
            <div className="divide-y divide-main">
              <div className="px-8 pt-8 pb-4">
                {type === "default" && (
                  <>
                    {title && <Dialog.Title as={H3}>{title}</Dialog.Title>}
                    {description && (
                      <Dialog.Description>{description}</Dialog.Description>
                    )}
                  </>
                )}
                {type === "alert" && (
                  <div className="flex">
                    <div className="mr-4">
                      <MaterialIcon
                        className={clsx("h-6 relative top-2px", {
                          "text-success": alertType === "success",
                          "text-error": alertType === "error",
                          "text-info": alertType === "info",
                          "text-warning": alertType === "warning",
                        })}
                        path={alertIcons[alertType]}
                      />
                    </div>
                    <div>
                      {title && <Dialog.Title as={H5}>{title}</Dialog.Title>}
                      {description && (
                        <Dialog.Description>{description}</Dialog.Description>
                      )}
                    </div>
                  </div>
                )}

                {children}
              </div>
              <div className="p-4 px-8 flex flex-wrap justify-between">
                {showCancelButton ? (
                  <div className="mr-8 my-2 min-w-cardButton">
                    <Button
                      variant="outline"
                      {...cancelButtonProps}
                      onClick={(e) => onCancel?.(e)}
                      block
                    >
                      {cancelText}
                    </Button>
                  </div>
                ) : (
                  <div />
                )}

                <div className="my-2 min-w-cardButton">
                  <Button
                    {...okButtonProps}
                    ref={okButtonRef}
                    onClick={(e) => onOk?.(e)}
                    block
                  >
                    {okText}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Modal;
