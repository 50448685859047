import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { DebtPaymentFormValues } from "components/debtPayment/DebtPaymentOptions";
import { FeatureState } from "models/FeatureState";

export interface DebtPaymentState {
  featureState: FeatureState;
}

const initialState: DebtPaymentState = {
  featureState: FeatureState.Initial,
};

const debtPayment = createSlice({
  name: "debtPayment",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
  },
});

const { reset, setState } = debtPayment.actions;

export const debtPaymentReducer = debtPayment.reducer;

const initiatePayment =
  ({
    amount,
    currencyCode,
    debtId,
  }: DebtPaymentFormValues): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    if (
      typeof amount !== "number" ||
      typeof currencyCode !== "string" ||
      typeof debtId !== "string"
    ) {
      return;
    }

    dispatch(setState(FeatureState.Loading));

    return api.finastic.payment
      .apiFrontendV1PaymentPost({
        startPaymentCommand: {
          amount,
          currencyCode,
          debtId,
        },
      })
      .then((data) => {
        dispatch(setState(FeatureState.Success));

        return data;
      })
      .catch(() => dispatch(setState(FeatureState.Error)));
  };

export const debtPaymentActions = {
  reset,
  initiatePayment,
};
