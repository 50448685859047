import { useAppSelector } from "app/store";
import Modal from "components/ui/Modal/Modal";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import StepWizard from "react-step-wizard";
import { debtDetailsActions } from "../DebtDetails/debtDetails.slice";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import {
  CreatePlaymentErrorKey,
  debtPaymentPlanActions,
} from "./DebtPaymentPlan.slice";

const DebtPaymentPlanCreationWizard: React.FC = () => {
  const dispatch = useDispatch();
  const { createPlaymentErrorKey } = useAppSelector(
    (state) => state.debts.debtPaymentPlan
  );
  const debt = useAppSelector((state) => state.debts.debtDetails.debt);
  const { t } = useTranslation();

  const errorModalTitle = useMemo(() => {
    // need to write it out for i18n-scanner
    switch (createPlaymentErrorKey) {
      case CreatePlaymentErrorKey.InvalidInstalmentCount:
        return t(
          "debt.createPaymentPlanWizard.errorModal.invalidInstalmentCount.title"
        );

      case CreatePlaymentErrorKey.InvalidInstalmentDate:
        return t(
          "debt.createPaymentPlanWizard.errorModal.invalidInstalmentDate.title"
        );

      case CreatePlaymentErrorKey.PaymentPlanCreationForbidden:
        return t(
          "debt.createPaymentPlanWizard.errorModal.paymentPlanCreationForbidden.title"
        );

      case CreatePlaymentErrorKey.BackendValidationFailed:
        return t(
          "debt.createPaymentPlanWizard.errorModal.backendValidationFailed.title"
        );

      case CreatePlaymentErrorKey.PaymentPlanAlreadyExists:
        return t(
          "debt.createPaymentPlanWizard.errorModal.paymentPlanAlreadyExists.title"
        );

      case CreatePlaymentErrorKey.DebtBalanceMismatch:
        return t(
          "debt.createPaymentPlanWizard.errorModal.debtBalanceMismatch.title"
        );

      default:
        return "";
    }
  }, [createPlaymentErrorKey, t]);

  const refreshDebtDataAndCloseWizard = useCallback(() => {
    if (debt?.debtId) {
      dispatch(debtDetailsActions.getDebt(debt.debtId));
    }

    dispatch(debtPaymentPlanActions.setShowWizard(false));
  }, [debt?.debtId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(debtPaymentPlanActions.reset());
    };
  }, [dispatch]);

  return (
    <div className="pt-4">
      <StepWizard transitions={{}}>
        <Step1 />
        <Step2 />
        <Step3 />
      </StepWizard>

      <Modal
        isOpen={!!createPlaymentErrorKey}
        title={errorModalTitle}
        okText={t("common.ok")}
        type="alert"
        alertType="error"
        onOk={refreshDebtDataAndCloseWizard}
      />
    </div>
  );
};

export default DebtPaymentPlanCreationWizard;
