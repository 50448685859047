import { SubRouteLayout } from "components/layout/SubRouteLayout";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "router/PrivateRoute";
import { RouteItem, RouteItemTypes } from "router/useRoutes";
import ChangePassword from "./ChangePassword/ChangePassword";
import Settings from "./Home/Settings";
import PreferredCommunication from "./PreferredCommunication/PreferredCommunication";
import PrivacyConsent from "./PrivacyConsent/PrivacyConsent";

const SettingsRouteSwitch: React.FC = () => {
  const { path: currentPath } = useRouteMatch();
  const { t } = useTranslation();

  const routes = useMemo<RouteItem[]>(
    () => [
      {
        path: "",
        title: t("pageTitle.settings.home"),
        component: Settings,
        type: RouteItemTypes.Private,
        exact: true,
      },
      {
        path: "/preferred-communication",
        title: t("pageTitle.settings.preferredCommunication"),
        component: PreferredCommunication,
        type: RouteItemTypes.Private,
        exact: true,
      },
      {
        path: "/change-password",
        title: t("pageTitle.settings.changePassword"),
        component: ChangePassword,
        type: RouteItemTypes.Private,
      },
      {
        path: "/privacy-consent",
        title: t("pageTitle.settings.privacyConsent"),
        component: PrivacyConsent,
        type: RouteItemTypes.Private,
      },
    ],
    [t]
  );

  return (
    <Switch>
      {routes.map(({ path, ...rest }) => (
        <PrivateRoute
          {...rest}
          key={path}
          path={`${currentPath}${path}`}
          layout={SubRouteLayout}
        />
      ))}
      <Redirect to={`${currentPath}${routes[0].path}`} />
    </Switch>
  );
};

export default SettingsRouteSwitch;
