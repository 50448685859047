import Content from "components/layout/Content";
import Alert from "components/ui/Alert/Alert";
import Card from "components/ui/Card/Card";
import { H3 } from "components/ui/Headings/Headings";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mdiInformation } from "@mdi/js";
import RouterPrompt from "components/RouterPrompt";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/store";
import { FeatureState } from "models/FeatureState";
import useQuery from "hooks/useQuery";
import Button from "components/ui/Button/Button";
import { Link } from "react-router-dom";
import { paymentLinkActions } from "./paymentLink.slice";
import ContentLoadingSpinner from "components/ui/LoadingSpinner/ContentLoadingSpinner";

const PaymentResult: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const paymentId = query.get("paymentId");
  const [remainintTryCount, setRemainintTryCount] = useState(24); // 24 * 5 sec = 120 sec = 2 min

  const { paymentState, paymentData } = useAppSelector(
    (state) => state.paymentLink
  );

  const needToPoll = remainintTryCount > 0;

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (paymentState === FeatureState.Loading && paymentId && needToPoll) {
      dispatch(paymentLinkActions.getPaymentStatus(paymentId));

      interval = setInterval(() => {
        dispatch(paymentLinkActions.getPaymentStatus(paymentId));
        setRemainintTryCount((oldValue) => oldValue - 1);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [dispatch, needToPoll, paymentId, paymentState]);

  return (
    <Content>
      <RouterPrompt
        when={
          paymentState === FeatureState.Loading && !!paymentId && needToPoll
        }
        title={t("payment.leaveModal.title")}
        cancelText={t("payment.leaveModal.cancelText")}
        okText={t("payment.leaveModal.okText")}
        onOK={() => true}
        onCancel={() => false}
      />
      <Card
        footer={
          (paymentState === FeatureState.Success ||
            paymentState === FeatureState.Error ||
            !paymentId ||
            !needToPoll) && (
            <>
              {paymentState === FeatureState.Success &&
                (paymentData?.isFinasticUser ? (
                  <div>
                    <Link to="/login">
                      {t("paymentLink.result.success.loginLinkText")}
                    </Link>{" "}
                    {t("paymentLink.result.success.loginText")}
                  </div>
                ) : (
                  <div>
                    <Link to="/registration">
                      {t("paymentLink.result.success.registrationLinkText")}
                    </Link>{" "}
                    {t("paymentLink.result.success.registrationText")}
                  </div>
                ))}

              {(paymentState === FeatureState.Error ||
                !paymentId ||
                !needToPoll) && (
                <div className="flex justify-end">
                  <div className="my-2 min-w-cardButton">
                    {!paymentData?.debtId ? (
                      <Button as={Link} to="/" block>
                        {t("paymentLink.result.backToDashboardText")}
                      </Button>
                    ) : (
                      <Button
                        as={Link}
                        to={`/link-payment/pay?debtId=${paymentData.debtId}`}
                        block
                      >
                        {t("paymentLink.result.tryAgainText")}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </>
          )
        }
      >
        <div className="md:w-80 mx-auto">
          <H3>{t("payment.title")}</H3>

          {!needToPoll ? (
            <Alert type="error">{t("payment.paymentStatusTimeout")}</Alert>
          ) : !paymentId ? (
            <Alert type="error">{t("payment.noOrder")}</Alert>
          ) : paymentState === FeatureState.Loading ? (
            <>
              <Alert icon={mdiInformation}>
                {t("payment.paymentInProgress")}
              </Alert>
              <ContentLoadingSpinner />
            </>
          ) : paymentState === FeatureState.Success ? (
            <Alert type="success">{t("payment.paymentSuccessful")}</Alert>
          ) : (
            <Alert type="error">{t("payment.paymentFailed")}</Alert>
          )}
        </div>
      </Card>
    </Content>
  );
};

export default PaymentResult;
