/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryCode,
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';
import {
    InstalmentDto,
    InstalmentDtoFromJSON,
    InstalmentDtoFromJSONTyped,
    InstalmentDtoToJSON,
} from './InstalmentDto';

/**
 * 
 * @export
 * @interface DebtSummaryDto
 */
export interface DebtSummaryDto {
    /**
     * 
     * @type {CountryCode}
     * @memberof DebtSummaryDto
     */
    countryCode?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof DebtSummaryDto
     */
    debtId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtSummaryDto
     */
    creditorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtSummaryDto
     */
    currencyCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DebtSummaryDto
     */
    outstandingBalance?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DebtSummaryDto
     */
    hasInstalmentPlan?: boolean;
    /**
     * 
     * @type {InstalmentDto}
     * @memberof DebtSummaryDto
     */
    nextInstalment?: InstalmentDto;
    /**
     * 
     * @type {number}
     * @memberof DebtSummaryDto
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DebtSummaryDto
     */
    isDueToBePaid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DebtSummaryDto
     */
    isPaymentInProgress?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DebtSummaryDto
     */
    canCreatePaymentPlan?: boolean;
}

export function DebtSummaryDtoFromJSON(json: any): DebtSummaryDto {
    return DebtSummaryDtoFromJSONTyped(json, false);
}

export function DebtSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebtSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': !exists(json, 'countryCode') ? undefined : CountryCodeFromJSON(json['countryCode']),
        'debtId': !exists(json, 'debtId') ? undefined : json['debtId'],
        'creditorName': !exists(json, 'creditorName') ? undefined : json['creditorName'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
        'outstandingBalance': !exists(json, 'outstandingBalance') ? undefined : json['outstandingBalance'],
        'hasInstalmentPlan': !exists(json, 'hasInstalmentPlan') ? undefined : json['hasInstalmentPlan'],
        'nextInstalment': !exists(json, 'nextInstalment') ? undefined : InstalmentDtoFromJSON(json['nextInstalment']),
        'nextPaymentAmount': !exists(json, 'nextPaymentAmount') ? undefined : json['nextPaymentAmount'],
        'isDueToBePaid': !exists(json, 'isDueToBePaid') ? undefined : json['isDueToBePaid'],
        'isPaymentInProgress': !exists(json, 'isPaymentInProgress') ? undefined : json['isPaymentInProgress'],
        'canCreatePaymentPlan': !exists(json, 'canCreatePaymentPlan') ? undefined : json['canCreatePaymentPlan'],
    };
}

export function DebtSummaryDtoToJSON(value?: DebtSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': CountryCodeToJSON(value.countryCode),
        'debtId': value.debtId,
        'creditorName': value.creditorName,
        'currencyCode': value.currencyCode,
        'outstandingBalance': value.outstandingBalance,
        'hasInstalmentPlan': value.hasInstalmentPlan,
        'nextInstalment': InstalmentDtoToJSON(value.nextInstalment),
        'nextPaymentAmount': value.nextPaymentAmount,
        'isDueToBePaid': value.isDueToBePaid,
        'isPaymentInProgress': value.isPaymentInProgress,
        'canCreatePaymentPlan': value.canCreatePaymentPlan,
    };
}

