import React from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Barcode from "react-barcode";
import clsx from "clsx";
import Button from "components/ui/Button/Button";

const BarcodePage: React.FC = () => {
  const { barcodeId } = useParams<{ barcodeId: string }>();
  const { t } = useTranslation();
  const history = useHistory();

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );

  return (
    <div className="flex flex-col justify-center items-center py-10">
      <Barcode value={barcodeId} format="CODE128" />
      <div className="py-10">{t("debt.repayment.barcodePrintMessage")}</div>
      <div className="flex flex-col-reverse md:flex-row gap-8 justify-center items-center w-96 my-8 print-display-none">
        <div className="w-40">
          <Button block onClick={history.goBack} variant="outline">
            {t("common.back")}
          </Button>
        </div>
        <div
          className={clsx("w-40", {
            hidden: isMobile,
          })}
        >
          <Button block onClick={window.print}>
            {t("debt.repayment.printButtonText")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BarcodePage;
