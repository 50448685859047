import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";
import { ChangePasswordFormValues } from "./ChangePassword";

export interface ChangePasswordState {
  featureState: FeatureState;
}

const initialState: ChangePasswordState = {
  featureState: FeatureState.Initial,
};

const changePassword = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
  },
});

const { reset, setState } = changePassword.actions;

export const changePasswordReducer = changePassword.reducer;

const setPassword =
  ({
    currentPassword,
    newPassword,
  }: ChangePasswordFormValues): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch(setState(FeatureState.Loading));

    return api.finastic.user
      .apiFrontendV1UserPasswordPut({
        updatePasswordCommand: {
          currentPassword,
          newPassword,
        },
      })
      .then(() => dispatch(setState(FeatureState.Success)))
      .catch(() => dispatch(setState(FeatureState.Error)));
  };

export const changePasswordActions = {
  reset,
  setPassword,
};
