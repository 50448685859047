/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentPlanOptionDto,
    PaymentPlanOptionDtoFromJSON,
    PaymentPlanOptionDtoFromJSONTyped,
    PaymentPlanOptionDtoToJSON,
} from './PaymentPlanOptionDto';

/**
 * 
 * @export
 * @interface PaymentPlanOptionsDto
 */
export interface PaymentPlanOptionsDto {
    /**
     * 
     * @type {Date}
     * @memberof PaymentPlanOptionsDto
     */
    firstPossibleInstalmentDueDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentPlanOptionsDto
     */
    lastPossibleInstalmentDueDate?: Date;
    /**
     * 
     * @type {Array<PaymentPlanOptionDto>}
     * @memberof PaymentPlanOptionsDto
     */
    paymentPlanOptions?: Array<PaymentPlanOptionDto> | null;
}

export function PaymentPlanOptionsDtoFromJSON(json: any): PaymentPlanOptionsDto {
    return PaymentPlanOptionsDtoFromJSONTyped(json, false);
}

export function PaymentPlanOptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentPlanOptionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstPossibleInstalmentDueDate': !exists(json, 'firstPossibleInstalmentDueDate') ? undefined : (new Date(json['firstPossibleInstalmentDueDate'])),
        'lastPossibleInstalmentDueDate': !exists(json, 'lastPossibleInstalmentDueDate') ? undefined : (new Date(json['lastPossibleInstalmentDueDate'])),
        'paymentPlanOptions': !exists(json, 'paymentPlanOptions') ? undefined : (json['paymentPlanOptions'] === null ? null : (json['paymentPlanOptions'] as Array<any>).map(PaymentPlanOptionDtoFromJSON)),
    };
}

export function PaymentPlanOptionsDtoToJSON(value?: PaymentPlanOptionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstPossibleInstalmentDueDate': value.firstPossibleInstalmentDueDate === undefined ? undefined : (value.firstPossibleInstalmentDueDate.toISOString()),
        'lastPossibleInstalmentDueDate': value.lastPossibleInstalmentDueDate === undefined ? undefined : (value.lastPossibleInstalmentDueDate.toISOString()),
        'paymentPlanOptions': value.paymentPlanOptions === undefined ? undefined : (value.paymentPlanOptions === null ? null : (value.paymentPlanOptions as Array<any>).map(PaymentPlanOptionDtoToJSON)),
    };
}

