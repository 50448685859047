import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface DisplayNullableDataProps {
  value?: string | number | Date | boolean | null;
  isOnDarkBg?: boolean; // if data is displayed on a dark background
}

const DisplayNullableData: React.FC<DisplayNullableDataProps> = ({
  value,
  isOnDarkBg,
  children,
}) => {
  const { t } = useTranslation();

  return typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    typeof value === "object" ? (
    <>{children || <span>{value}</span>}</>
  ) : (
    <span
      className={clsx(
        "italic",
        `${isOnDarkBg ? "text-dataNotAvailableDark" : "text-dataNotAvailable"}`
      )}
    >
      {t("common.notAvailable")}
    </span>
  );
};

export default DisplayNullableData;
