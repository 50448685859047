/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    VersionDto,
    VersionDtoFromJSON,
    VersionDtoToJSON,
} from '../models';

/**
 * 
 */
export class ConfigurationApi extends runtime.BaseAPI {

    /**
     */
    async apiFrontendV1ConfigurationMinimumAppVersionGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<VersionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Configuration/MinimumAppVersion`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1ConfigurationMinimumAppVersionGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<VersionDto> {
        const response = await this.apiFrontendV1ConfigurationMinimumAppVersionGetRaw(initOverrides);
        return await response.value();
    }

}
