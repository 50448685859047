/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CheckCustomerCommand,
    CheckCustomerCommandFromJSON,
    CheckCustomerCommandToJSON,
    CheckRegistrationOtpCommand,
    CheckRegistrationOtpCommandFromJSON,
    CheckRegistrationOtpCommandToJSON,
    ConfirmEmailAddressCommand,
    ConfirmEmailAddressCommandFromJSON,
    ConfirmEmailAddressCommandToJSON,
    RegisterCustomerCommand,
    RegisterCustomerCommandFromJSON,
    RegisterCustomerCommandToJSON,
    RegistrationTokenDto,
    RegistrationTokenDtoFromJSON,
    RegistrationTokenDtoToJSON,
} from '../models';

export interface ApiFrontendV1RegistrationCheckCustomerPutRequest {
    checkCustomerCommand?: CheckCustomerCommand;
}

export interface ApiFrontendV1RegistrationCheckOtpPutRequest {
    checkRegistrationOtpCommand?: CheckRegistrationOtpCommand;
}

export interface ApiFrontendV1RegistrationConfirmEmailAddressPostRequest {
    confirmEmailAddressCommand?: ConfirmEmailAddressCommand;
}

export interface ApiFrontendV1RegistrationRegisterCustomerPostRequest {
    registerCustomerCommand?: RegisterCustomerCommand;
}

/**
 * 
 */
export class RegistrationApi extends runtime.BaseAPI {

    /**
     */
    async apiFrontendV1RegistrationCheckCustomerPutRaw(requestParameters: ApiFrontendV1RegistrationCheckCustomerPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Registration/CheckCustomer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CheckCustomerCommandToJSON(requestParameters.checkCustomerCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1RegistrationCheckCustomerPut(requestParameters: ApiFrontendV1RegistrationCheckCustomerPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1RegistrationCheckCustomerPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFrontendV1RegistrationCheckOtpPutRaw(requestParameters: ApiFrontendV1RegistrationCheckOtpPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<RegistrationTokenDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Registration/CheckOtp`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CheckRegistrationOtpCommandToJSON(requestParameters.checkRegistrationOtpCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationTokenDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1RegistrationCheckOtpPut(requestParameters: ApiFrontendV1RegistrationCheckOtpPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<RegistrationTokenDto> {
        const response = await this.apiFrontendV1RegistrationCheckOtpPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1RegistrationConfirmEmailAddressPostRaw(requestParameters: ApiFrontendV1RegistrationConfirmEmailAddressPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Registration/ConfirmEmailAddress`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmEmailAddressCommandToJSON(requestParameters.confirmEmailAddressCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1RegistrationConfirmEmailAddressPost(requestParameters: ApiFrontendV1RegistrationConfirmEmailAddressPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1RegistrationConfirmEmailAddressPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFrontendV1RegistrationRegisterCustomerPostRaw(requestParameters: ApiFrontendV1RegistrationRegisterCustomerPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Registration/RegisterCustomer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterCustomerCommandToJSON(requestParameters.registerCustomerCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1RegistrationRegisterCustomerPost(requestParameters: ApiFrontendV1RegistrationRegisterCustomerPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1RegistrationRegisterCustomerPostRaw(requestParameters, initOverrides);
    }

}
