import { useAppSelector } from "app/store";
import React from "react";
import { useTranslation } from "react-i18next";
import Card from "components/ui/Card/Card";
import { H3, H6 } from "components/ui/Headings/Headings";
import Button from "components/ui/Button/Button";
import ForgotPasswordTimedOut from "../components/ForgotPasswordTimedOut";
import Alert from "components/ui/Alert/Alert";
import { Link } from "react-router-dom";
import Content from "components/layout/Content";

export interface ForgotPasswordEmailValidationFormValues {
  otp: number | null;
}

const EmailValidation: React.FC = () => {
  const { formValues: previousStepFormValues } = useAppSelector(
    (state) => state.forgotPassword.home
  );

  const { t } = useTranslation();

  return previousStepFormValues ? (
    <Content>
      <Card
        footer={
          <>
            <div className="flex">
              <div className="min-w-cardButton">
                <Button variant="outline" as={Link} to="/login" block>
                  {t("forgotPassword.emailValidation.backToLoginButtonText")}
                </Button>
              </div>
            </div>
          </>
        }
        isDense
      >
        <H6>{t("forgotPassword.emailValidation.subtitle")}</H6>
        <H3>{t("forgotPassword.emailValidation.title")}</H3>

        <Alert type="success">
          {t("forgotPassword.emailValidation.mailSentToAddress")}
          <span className="font-bold inline-block ml-1 break-all">
            {previousStepFormValues.emailAddress}
          </span>
        </Alert>

        <p className="mt-4">
          {t("forgotPassword.emailValidation.description")}
        </p>
      </Card>
    </Content>
  ) : (
    <ForgotPasswordTimedOut />
  );
};

export default EmailValidation;
