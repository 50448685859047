import React from "react";
import { FieldInputProps } from "formik";
import clsx from "clsx";
import NumberFormat, { NumberFormatProps } from "react-number-format";

interface InputNumberProps extends NumberFormatProps {
  hasError?: boolean;
  field?: FieldInputProps<number>;
  textAlignRight?: boolean;
  hasPrependText?: boolean;
}

const InputNumber: React.FC<InputNumberProps> = ({
  required,
  hasError,
  field,
  textAlignRight,
  thousandSeparator = ".",
  decimalSeparator = ",",
  inputMode = "numeric",
  hasPrependText,
  ...rest
}) => {
  return (
    <NumberFormat
      {...field}
      {...rest}
      required={required}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      inputMode={inputMode}
      // input custom class to share style between Input and InputNumber
      className={clsx(
        "input",
        {
          "border-error": hasError,
          "border-formControl": !hasError,
          "text-right": textAlignRight,
        },
        { "pl-5": hasPrependText }
      )}
    />
  );
};

export default InputNumber;
