import React from "react";

interface FooterSectionTitleProps {
  children: React.ReactNode;
}

const FooterSectionTitle: React.FC<FooterSectionTitleProps> = ({
  children,
}) => {
  return (
    <div className="text-xs text-white border-dotted border-b border-footer pb-1 mb-2">
      {children}
    </div>
  );
};

export default FooterSectionTitle;
