import clsx from "clsx";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Logo from "assets/images/axfina_logo_long_white.svg";
import LogoMinified from "assets/images/axfina_logo_short_white.svg";
import SidebarMenu from "./SidebarMenu";
import { useTranslation } from "react-i18next";
import useAuth from "features/auth/useAuth";
import { useDetectClickOutside } from "react-detect-click-outside";
import useBreakpoint from "hooks/useBreakpoint";
import {
  mdiArrowCollapseLeft,
  mdiUpload,
  mdiCardAccountDetailsOutline,
  mdiNotebookEditOutline,
  mdiFrequentlyAskedQuestions,
  mdiLogout,
} from "@mdi/js";
import { SidebarMenuItemI } from "./SidebarMenuItem";
import { useDispatch } from "react-redux";
import { authSliceActions } from "features/auth/authSlice";
import { Link } from "react-router-dom";
import OuterLink from "components/OuterLink";
import Modal from "components/ui/Modal/Modal";
import { useAppSelector } from "app/store";
import { appVersionDtoToString } from "features/appVersion/utils";
import { useHistory } from "react-router";

interface SidebarProps {
  onToggledChange?: (newValue: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onToggledChange = (newValue: boolean) => {},
}) => {
  const { t } = useTranslation();
  const [isToggled, setIsToggled] = useState(false);
  const { user } = useAuth();
  const { currentBreakpoint, getCurrentBreakpoint } = useBreakpoint();
  const dispatch = useDispatch();
  const [isLogoutConfirmModalOpen, setIsLogoutConfirmModalOpen] =
    useState(false);
  const { appVersion } = useAppSelector((state) => state.appVersion);
  const history = useHistory();

  const ref = useDetectClickOutside({
    onTriggered: () => {
      const breakpoint = getCurrentBreakpoint();

      if (breakpoint === "xs" || breakpoint === "sm") {
        setIsToggled(true);
      }
    },
  });

  useEffect(() => {
    if (currentBreakpoint === "xs" || currentBreakpoint === "sm") {
      setIsToggled(true);
    } else {
      setIsToggled(false);
    }
  }, [currentBreakpoint]);

  const userName = useMemo(() => {
    // If toggled, get only first character
    const firstName = isToggled ? `${user?.firstName?.[0]}.` : user?.firstName;
    const lastName = isToggled ? `${user?.lastName?.[0]}.` : user?.lastName;

    return `${firstName} ${lastName}`;
  }, [isToggled, user?.firstName, user?.lastName]);

  const menuItems1 = useMemo<SidebarMenuItemI[]>(
    () => [
      {
        label: t("sidebar.myDebts"),
        href: "/",
        icon: mdiUpload,
      },
    ],
    [t]
  );

  const menuItems2 = useMemo<SidebarMenuItemI[]>(
    () => [
      {
        label: t("sidebar.settings"),
        href: "/settings",
        icon: mdiCardAccountDetailsOutline,
      },
    ],
    [t]
  );

  const menuItems3 = useMemo<SidebarMenuItemI[]>(
    () => [
      {
        label: t("sidebar.privacy"),
        href: t("config.privacyAndCookiePolicyUrl", {
          lng: user && user.countryCode,
        }),
        target: "_blank",
        icon: mdiNotebookEditOutline,
        id: "sidebarPrivacy",
        as: OuterLink,
      },
      {
        label: t("sidebar.terms"),
        href: t("config.termsUrl", {
          lng: user && user.countryCode,
        }),
        target: "_blank",
        icon: mdiNotebookEditOutline,
        id: "sidebarTerms",
        as: OuterLink,
      },
      {
        label: t("sidebar.faq"),
        href: t("config.faqUrl"),
        target: "_blank",
        icon: mdiFrequentlyAskedQuestions,
        id: "sidebarFaq",
        as: OuterLink,
      },
    ],
    [t, user]
  );

  const menuItems4 = useMemo<SidebarMenuItemI[]>(
    () => [
      {
        label: t("sidebar.logOut"),
        href: "/",
        icon: mdiLogout,
        as: "button",
        onClick: () => setIsLogoutConfirmModalOpen(true),
      },
    ],
    [t]
  );

  const handleLogoutConfirmModalOk = useCallback(async () => {
    await dispatch(authSliceActions.logOut());

    history.push("/");
  }, [dispatch, history]);

  useEffect(() => {
    onToggledChange(isToggled);
  }, [isToggled, onToggledChange]);

  return (
    <aside className={clsx(`${isToggled ? "w-20" : "w-56"}`)} ref={ref}>
      <div
        className={clsx(
          "flex-shrink-0 bg-secondary transition-all z-20",
          "px-4 pb-3 shadow fixed top-0 left-0 h-full",
          `${isToggled ? "w-20" : "w-56"}`
        )}
      >
        <div className="overflow-hidden">
          <div className="py-3 border-b border-sidebar">
            <Link to="/">
              <img
                src={isToggled ? LogoMinified : Logo}
                alt="Axfina"
                className="h-6"
              />
            </Link>
          </div>
          <SidebarMenu
            label={t("sidebar.debts")}
            items={menuItems1}
            isToggled={isToggled}
          />
          <div className="text-xs text-label mt-4">{t("sidebar.profile")}</div>
          <div className={clsx("text-white", { "text-center": isToggled })}>
            {userName}
          </div>
          <SidebarMenu items={menuItems2} isToggled={isToggled} />
          <SidebarMenu
            label={t("sidebar.aboutUs")}
            items={menuItems3}
            isToggled={isToggled}
          />
          <SidebarMenu items={menuItems4} isToggled={isToggled} />
          {!isToggled && (
            <div className="py-4 border-b border-sidebar text-center text-label">
              {t("sidebar.version")} {appVersionDtoToString(appVersion)}
            </div>
          )}
        </div>

        <button
          className={clsx(
            "bg-secondary absolute right-0 top-0 transform translate-x-1/2 w-9 h-9",
            "sidebar-toggler mt-1 rounded-full shadow-sidebarToggler text-white"
          )}
          onClick={() => setIsToggled((value) => !value)}
        >
          <MaterialIcon
            path={mdiArrowCollapseLeft}
            className={clsx("h-4 mx-auto", {
              "rotate-180 transform": isToggled,
            })}
          />
        </button>
      </div>
      <Modal
        isOpen={isLogoutConfirmModalOpen}
        title={t("sidebar.confirmLogoutModal.title")}
        okText={t("common.yes")}
        cancelText={t("common.no")}
        type="alert"
        alertType={"warning"}
        showCancelButton
        size="medium"
        onOk={handleLogoutConfirmModalOk}
        onCancel={() => setIsLogoutConfirmModalOpen(false)}
      />
    </aside>
  );
};

export default Sidebar;
