import React, { useContext, useEffect, useMemo } from "react";
import { DebtPaymentOptionsProps, PaymentSchedule } from "./DebtPaymentOptions";
import InputNumberFormGroup from "components/forms/InputNumberFormGroup";
import RadioGroup from "components/forms/RadioGroup";
import Button from "components/ui/Button/Button";
import { H6 } from "components/ui/Headings/Headings";
import { Form } from "formik";
import Input from "components/forms/Input";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getEnumValuesOptions } from "utils/common";
import { PaymentContext } from "contexts/PaymentContext";
import useLanguageCodeCountryCodeLogics from "hooks/useLanguageCodeCountryCodeLogics";
import { CountryCode } from "api/generated/finastic";

const CardPayment: React.FC<DebtPaymentOptionsProps> = ({
  debtDetails,
  formikProps,
  redirecting,
}) => {
  const { t, i18n } = useTranslation();

  const {
    currencyCode,
    hasInstalmentPlan,
    isPaymentInProgress,
    nextPaymentAmount,
    countryCode,
  } = debtDetails;

  const { isFinasticUser, isPaymentLinkPay } = useContext(PaymentContext);

  const { paymentLinkPrivacyTextMap } = useLanguageCodeCountryCodeLogics();

  const paymentLinkPrivacyText =
    paymentLinkPrivacyTextMap[countryCode || CountryCode.Invalid];

  const paymentScheduleOptions = getEnumValuesOptions<PaymentSchedule>(
    PaymentSchedule,
    "PaymentSchedule",
    [PaymentSchedule.Partial, PaymentSchedule.Full]
  );

  const isPaymentScheduleFull = useMemo(
    () => formikProps.values.paymentSchedule === PaymentSchedule.Full,
    [formikProps.values.paymentSchedule]
  );

  const isPaymentSchedulePartial = useMemo(
    () => formikProps.values.paymentSchedule === PaymentSchedule.Partial,
    [formikProps.values.paymentSchedule]
  );

  useEffect(() => {
    if (isPaymentScheduleFull || isPaymentSchedulePartial) {
      formikProps.setFieldValue("amount", debtDetails.outstandingBalance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debtDetails.outstandingBalance,
    isPaymentScheduleFull,
    isPaymentSchedulePartial,
  ]);

  useEffect(() => {
    if (!hasInstalmentPlan) {
      formikProps.setFieldValue("paymentSchedule", PaymentSchedule.Full);
    } else {
      formikProps.setFieldValue("amount", debtDetails.nextPaymentAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInstalmentPlan]);

  useEffect(() => {
    formikProps.setFieldValue("languageCode", i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Form noValidate>
      <Input name="debtId" hidden />
      <Input name="currencyCode" hidden />
      <Input name="languageCode" hidden />

      <div className="md:px-8 p-4 md:pb-4 pb-0 pt-0">
        <div className="divide-y divide-main">
          {!hasInstalmentPlan && (
            <div className="space-y-4 pb-4">
              <H6>{t("debt.paymentScheduleChoose")}</H6>
              <RadioGroup
                name="paymentSchedule"
                options={paymentScheduleOptions}
                required
              />
            </div>
          )}

          <div className="space-y-4">
            <H6>{t("debt.repaymentAmount")}</H6>
            <div className="space-x-4 flex items-start">
              <div className="w-48">
                <InputNumberFormGroup
                  textAlignRight
                  name="amount"
                  required
                  disabled={isPaymentScheduleFull || hasInstalmentPlan}
                  max={Math.max(
                    debtDetails.outstandingBalance || 0,
                    debtDetails.nextPaymentAmount || 0
                  )}
                  min={debtDetails.outstandingBalance ? 1 : 0}
                />
              </div>
              <div className="mb-6 mt-3 relative -top-px">{currencyCode}</div>
            </div>
            {!isFinasticUser && isPaymentLinkPay && (
              <span
                className="text-xs inline-block"
                dangerouslySetInnerHTML={{
                  __html: paymentLinkPrivacyText,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="py-4 md:px-8 px-4 border-t border-main">
        <div className="flex justify-between -my-2 sm:flex-row flex-col-reverse items-start">
          <div className="my-2 min-w-cardButton">
            <Button
              variant="outline"
              as={Link}
              to="/"
              block
              disabled={redirecting}
            >
              {t("debt.repayment.cancelButtonText")}
            </Button>
          </div>
          {!isPaymentInProgress && !!nextPaymentAmount && (
            <div className="my-2 min-w-cardButton">
              <Button
                disabled={!formikProps.isValid}
                isLoading={formikProps.isSubmitting || redirecting}
                block
                type="submit"
              >
                {t("debt.repayment.payButtonText")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Form>
  );
};

export default CardPayment;
