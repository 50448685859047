/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreatePaymentPlanCommand,
    CreatePaymentPlanCommandFromJSON,
    CreatePaymentPlanCommandToJSON,
    PaymentPlanOptionsDto,
    PaymentPlanOptionsDtoFromJSON,
    PaymentPlanOptionsDtoToJSON,
} from '../models';

export interface ApiFrontendV1PaymentPlanOptionsGetRequest {
    debtId: string;
}

export interface ApiFrontendV1PaymentPlanPostRequest {
    createPaymentPlanCommand?: CreatePaymentPlanCommand;
}

/**
 * 
 */
export class PaymentPlanApi extends runtime.BaseAPI {

    /**
     */
    async apiFrontendV1PaymentPlanOptionsGetRaw(requestParameters: ApiFrontendV1PaymentPlanOptionsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PaymentPlanOptionsDto>> {
        if (requestParameters.debtId === null || requestParameters.debtId === undefined) {
            throw new runtime.RequiredError('debtId','Required parameter requestParameters.debtId was null or undefined when calling apiFrontendV1PaymentPlanOptionsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.debtId !== undefined) {
            queryParameters['debtId'] = requestParameters.debtId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/PaymentPlan/Options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentPlanOptionsDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1PaymentPlanOptionsGet(requestParameters: ApiFrontendV1PaymentPlanOptionsGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaymentPlanOptionsDto> {
        const response = await this.apiFrontendV1PaymentPlanOptionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1PaymentPlanPostRaw(requestParameters: ApiFrontendV1PaymentPlanPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/PaymentPlan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaymentPlanCommandToJSON(requestParameters.createPaymentPlanCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1PaymentPlanPost(requestParameters: ApiFrontendV1PaymentPlanPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1PaymentPlanPostRaw(requestParameters, initOverrides);
    }

}
