import clsx from "clsx";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface FormGroupErrorProps {
  children: React.ReactNode;
  fieldName?: string;
}

const FormGroupError: React.FC<FormGroupErrorProps> = ({
  children,
  fieldName,
}) => {
  const { i18n } = useTranslation();
  const { validateField } = useFormikContext();

  useEffect(() => {
    if (fieldName) {
      // to desplay error message in corrent language after language change
      validateField(fieldName);
    }
  }, [fieldName, i18n.language, validateField]);

  return <div className={clsx("mb-1 text-xs text-error mt-1")}>{children}</div>;
};

export default FormGroupError;
