import { PaymentPlanOptionDto } from "api/generated/finastic";
import DisplayNullableData from "components/DisplayNullableData";
import DisplayNumberFormat from "components/ui/DisplayNumberFormat/DisplayNumberFormat";
import React from "react";
import { useTranslation } from "react-i18next";

interface InstalmentNumberDataProps {
  instalmentCount: number;
  currencyCode?: string | null;
  paymentPlanOptions?: PaymentPlanOptionDto[] | null;
}

const InstalmentNumberData: React.FC<InstalmentNumberDataProps> = ({
  instalmentCount,
  currencyCode,
  paymentPlanOptions,
}) => {
  const { t } = useTranslation();

  return (
    <div className="divide-y divide-main space-y-2">
      <div className="flex justify-between flex-wrap">
        <span>{t("debt.noOfInstalments")}:</span>
        <DisplayNullableData value={instalmentCount}>
          <span>
            <DisplayNumberFormat value={instalmentCount} />
            <span className="inline-block ml-2 text-debtDetailSecondary invisible">
              {currencyCode}
            </span>
          </span>
        </DisplayNullableData>
      </div>
      <div className="flex justify-between pt-2 flex-wrap">
        <span>{t("debt.instalmentAmount")}:</span>
        <DisplayNullableData
          value={
            paymentPlanOptions?.find(
              (option: any) => option.instalmentCount === instalmentCount
            )?.monthlyAmount
          }
        >
          <span>
            <DisplayNumberFormat
              value={
                paymentPlanOptions?.find(
                  (option: any) => option.instalmentCount === instalmentCount
                )?.monthlyAmount
              }
            />
            <span className="inline-block ml-2 text-debtDetailSecondary">
              {currencyCode}
            </span>
          </span>
        </DisplayNullableData>
      </div>
      <div className="flex justify-between pt-2 flex-wrap">
        <span>{t("debt.lastInstalmentAmount")}:</span>
        <DisplayNullableData
          value={
            paymentPlanOptions?.find(
              (option: any) => option.instalmentCount === instalmentCount
            )?.lastAmount
          }
        >
          <span>
            <DisplayNumberFormat
              value={
                paymentPlanOptions?.find(
                  (option: any) => option.instalmentCount === instalmentCount
                )?.lastAmount
              }
            />
            <span className="inline-block ml-2 text-debtDetailSecondary">
              {currencyCode}
            </span>
          </span>
        </DisplayNullableData>
      </div>
    </div>
  );
};

export default InstalmentNumberData;
