/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryCode,
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';

/**
 * 
 * @export
 * @interface CheckCustomerCommand
 */
export interface CheckCustomerCommand {
    /**
     * 
     * @type {CountryCode}
     * @memberof CheckCustomerCommand
     */
    countryCode?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof CheckCustomerCommand
     */
    personIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCustomerCommand
     */
    accountIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCustomerCommand
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCustomerCommand
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCustomerCommand
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCustomerCommand
     */
    phoneNumber: string;
}

export function CheckCustomerCommandFromJSON(json: any): CheckCustomerCommand {
    return CheckCustomerCommandFromJSONTyped(json, false);
}

export function CheckCustomerCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckCustomerCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': !exists(json, 'countryCode') ? undefined : CountryCodeFromJSON(json['countryCode']),
        'personIdentifier': json['personIdentifier'],
        'accountIdentifier': json['accountIdentifier'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'emailAddress': json['emailAddress'],
        'phoneNumber': json['phoneNumber'],
    };
}

export function CheckCustomerCommandToJSON(value?: CheckCustomerCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': CountryCodeToJSON(value.countryCode),
        'personIdentifier': value.personIdentifier,
        'accountIdentifier': value.accountIdentifier,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
    };
}

