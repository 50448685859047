/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CommunicationChannel = {
    Invalid: 'Invalid',
    Email: 'Email',
    Sms: 'Sms',
    Push: 'Push',
    WhatsApp: 'WhatsApp'
} as const;
export type CommunicationChannel = typeof CommunicationChannel[keyof typeof CommunicationChannel];


export function CommunicationChannelFromJSON(json: any): CommunicationChannel {
    return CommunicationChannelFromJSONTyped(json, false);
}

export function CommunicationChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationChannel {
    return json as CommunicationChannel;
}

export function CommunicationChannelToJSON(value?: CommunicationChannel | null): any {
    return value as any;
}

