import { useAppSelector } from "app/store";
import { Form, Formik, FormikHelpers } from "formik";
import useFormValidations from "hooks/useFormValidations";
import { FeatureState } from "models/FeatureState";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { smsResetPasswordActions } from "./smsResetPassword.slice";
import * as Yup from "yup";
import Card from "components/ui/Card/Card";
import CardFooter from "features/forgotPassword/components/CardFooter";
import { H3 } from "components/ui/Headings/Headings";
import Alert from "components/ui/Alert/Alert";
import InputFormGroup from "components/forms/InputFormGroup";
import Modal from "components/ui/Modal/Modal";
import ForgotPasswordTimedOut from "features/forgotPassword/components/ForgotPasswordTimedOut";
import Content from "components/layout/Content";
import useEventLogger from "hooks/useEventLogger";

export interface SMSResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

const SMSResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { formValues: previousStepFormValues } = useAppSelector(
    (state) => state.forgotPassword.smsPasscode
  );

  const { featureState } = useAppSelector(
    (state) => state.forgotPassword.smsResetPassword
  );

  const feedbackModalIsOpen = useMemo(
    () => featureState === FeatureState.Success,
    [featureState]
  );

  const { passwordValidation, confirmPasswordValidation } =
    useFormValidations();

  const { trackAction } = useEventLogger("ForgotPasswordSubmitNewPassword");

  const handleSubmit: (
    values: SMSResetPasswordFormValues,
    formikHelpers: FormikHelpers<SMSResetPasswordFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    dispatch(smsResetPasswordActions.setFormValues(values));

    trackAction({});

    await dispatch(smsResetPasswordActions.resetPassword(values));
  };

  useEffect(() => {
    dispatch(smsResetPasswordActions.reset());
  }, [dispatch]);

  return previousStepFormValues ? (
    <>
      <Content>
        <Formik<SMSResetPasswordFormValues>
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            password: passwordValidation,
            confirmPassword: confirmPasswordValidation,
          })}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formikProps) => (
            <Card
              footer={
                <CardFooter<SMSResetPasswordFormValues>
                  formikProps={formikProps}
                  cancelButtonText={t(
                    "forgotPassword.smsPasscode.resetPassword.cancelButtonText"
                  )}
                  nextButtonText={t(
                    "forgotPassword.smsPasscode.resetPassword.nextButtonText"
                  )}
                />
              }
              isDense
            >
              <H3>{t("forgotPassword.smsPasscode.resetPassword.title")}</H3>

              <Alert type="success">
                {t(
                  "forgotPassword.smsPasscode.resetPassword.passcodeSucessfullyValidated"
                )}
              </Alert>

              <p className="my-4">
                {t("forgotPassword.smsPasscode.resetPassword.description")}
              </p>

              <Form noValidate>
                <InputFormGroup
                  name="password"
                  type="password"
                  label={t("forgotPassword.smsPasscode.resetPassword.password")}
                  placeholder={t(
                    "forgotPassword.smsPasscode.resetPassword.passwordPlaceholder"
                  )}
                  required
                />
                <InputFormGroup
                  name="confirmPassword"
                  type="password"
                  label={t(
                    "forgotPassword.smsPasscode.resetPassword.confirmPassword"
                  )}
                  placeholder={t(
                    "forgotPassword.smsPasscode.resetPassword.confirmPasswordPlaceholder"
                  )}
                  required
                />
              </Form>
            </Card>
          )}
        </Formik>
      </Content>
      <Modal
        isOpen={feedbackModalIsOpen}
        title={t("forgotPassword.smsPasscode.resetPassword.successModal.title")}
        description={t(
          "forgotPassword.smsPasscode.resetPassword.successModal.description"
        )}
        okText={t(
          "forgotPassword.smsPasscode.resetPassword.successModal.logInButtonText"
        )}
        type="alert"
        alertType="success"
        onOk={() => history.push("/login")}
      />
    </>
  ) : (
    <ForgotPasswordTimedOut />
  );
};

export default SMSResetPassword;
