import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLocalStorageValue, LocalStorageKeys } from "utils/storageHandlers";
import translationEn from "./locales/en.json";
import translationRo from "./locales/ro.json";
import translationPl from "./locales/pl.json";
import { LanguageCode } from "api/generated/finastic";

type Resource = {
  [key in LanguageCode]: any;
};

const resources: Resource = {
  [LanguageCode.Invalid]: {
    translation: translationEn,
  },
  [LanguageCode.En]: {
    translation: translationEn,
  },
  [LanguageCode.Ro]: {
    translation: translationRo,
  },
  [LanguageCode.Pl]: {
    translation: translationPl,
  },
};

const fallbackLng = LanguageCode.En;

const urlParams = new URLSearchParams(window.location.search);
const langFromUrl = urlParams.get("lang")?.toUpperCase();
const langFromLocalStorage = getLocalStorageValue(LocalStorageKeys.Locale);
const langFromBrowser = navigator.language.split("-")[0].toUpperCase(); // to make 'EN' from 'en-GB'

const languageToSet = langFromUrl || langFromLocalStorage || langFromBrowser;

const defaultLanguage = Object.keys(LanguageCode)
  .map((key) => key.toUpperCase())
  .includes(languageToSet)
  ? languageToSet
  : fallbackLng;

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  fallbackLng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
