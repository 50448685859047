import { DebtDetailsDto } from "api/generated/finastic";
import Alert from "components/ui/Alert/Alert";
import { H6 } from "components/ui/Headings/Headings";
import useDateFormatter, { DateFormat } from "hooks/useDateFormatter";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { mdiClockTimeThreeOutline } from "@mdi/js";
import DisplayNullableData from "components/DisplayNullableData";
import DisplayNumberFormat from "components/ui/DisplayNumberFormat/DisplayNumberFormat";
import Button from "components/ui/Button/Button";
import { useDispatch } from "react-redux";
import { debtPaymentPlanActions } from "features/debts/DebtPaymentPlanCreationWizard/DebtPaymentPlan.slice";
import { useAppSelector } from "app/store";

const DebtSchedule: React.FC<DebtDetailsDto> = ({
  hasInstalmentPlan,
  remainingInstalments,
  currencyCode,
  isPaymentInProgress,
  debtId,
  canCreatePaymentPlan,
}) => {
  const { t } = useTranslation();
  const { formatter } = useDateFormatter();
  const dispatch = useDispatch();
  const { showWizard } = useAppSelector((state) => state.debts.debtPaymentPlan);

  // Load payment plan options before user clicks Create payment plan button
  useEffect(() => {
    if (debtId && !showWizard && !hasInstalmentPlan && canCreatePaymentPlan) {
      dispatch(debtPaymentPlanActions.getPaymentPlanOptions(debtId));
    }
  }, [debtId, dispatch, hasInstalmentPlan, showWizard, canCreatePaymentPlan]);

  return (
    <div>
      {hasInstalmentPlan ? (
        <div className="md:px-12 px-4">
          <H6>{t("debt.paymentScheduleHasAlready")}</H6>
          <div className="divide-y-2 divide-main mt-2">
            {remainingInstalments?.map((instalment) => (
              <div
                className="py-2 last:pb-0"
                key={instalment.dueDate?.toISOString()}
              >
                <div className="flex justify-between">
                  {instalment.dueDate && (
                    <div>{formatter(instalment.dueDate, DateFormat.Date)}</div>
                  )}
                  <DisplayNullableData value={instalment.amount}>
                    <div>
                      <DisplayNumberFormat value={instalment.amount} />
                      <span className="inline-block ml-2 text-debtDetailSecondary">
                        {currencyCode}
                      </span>
                    </div>
                  </DisplayNullableData>
                </div>
                {isPaymentInProgress && (
                  <div className="mt-2">
                    <Alert
                      type="info"
                      size="small"
                      icon={mdiClockTimeThreeOutline}
                    >
                      {t("debt.processingPayment")}
                    </Alert>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="md:px-12 px-4">
          <H6>{t("debt.paymentScheduleNoActiveYet")}</H6>
          <div className="md:w-52 mx-auto mt-10 text-paymentSchedule italic text-center">
            {t("debt.noActiveInstalmentPlan")}
          </div>

          {canCreatePaymentPlan && (
            <div className="text-center mt-4">
              <Button
                variant="outline"
                onClick={() =>
                  dispatch(debtPaymentPlanActions.setShowWizard(true))
                }
              >
                {t("debt.createPaymentPlan")}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DebtSchedule;
