/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentPlanOptionDto
 */
export interface PaymentPlanOptionDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionDto
     */
    instalmentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionDto
     */
    monthlyAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionDto
     */
    lastAmount?: number;
}

export function PaymentPlanOptionDtoFromJSON(json: any): PaymentPlanOptionDto {
    return PaymentPlanOptionDtoFromJSONTyped(json, false);
}

export function PaymentPlanOptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentPlanOptionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instalmentCount': !exists(json, 'instalmentCount') ? undefined : json['instalmentCount'],
        'monthlyAmount': !exists(json, 'monthlyAmount') ? undefined : json['monthlyAmount'],
        'lastAmount': !exists(json, 'lastAmount') ? undefined : json['lastAmount'],
    };
}

export function PaymentPlanOptionDtoToJSON(value?: PaymentPlanOptionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instalmentCount': value.instalmentCount,
        'monthlyAmount': value.monthlyAmount,
        'lastAmount': value.lastAmount,
    };
}

