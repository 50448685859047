import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { PhomeNumberFormGroupValue } from "components/forms/PhoneNumberFormGroup";
import { FeatureState } from "models/FeatureState";
import { mapPhoneNumberFormGroupValueToString } from "utils/mappers";
import { ForgotPasswordFormValues } from "./ForgotPasswordHome";

export interface ForgotPasswordHomeState {
  formValues?: ForgotPasswordFormValues;
  featureState: FeatureState;
}

const initialState: ForgotPasswordHomeState = {
  formValues: undefined,
  featureState: FeatureState.Initial,
};

const forgotPasswordHome = createSlice({
  name: "forgotPasswordHome",
  initialState,
  reducers: {
    reset: () => initialState,
    setFormValues(state, { payload }: PayloadAction<ForgotPasswordFormValues>) {
      state.formValues = payload;
    },
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
  },
});

const { setFormValues, reset, setState } = forgotPasswordHome.actions;

export const forgotPasswordHomeReducer = forgotPasswordHome.reducer;

const initiateEmailForgotPassword =
  (emailAddress: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    return api.finastic.authentication
      .apiFrontendV1AuthenticationInitiateResetPasswordWithTokenPut({
        initiateResetPasswordWithTokenCommand: {
          emailAddress,
        },
      })
      .then(() => {
        dispatch(setState(FeatureState.Success));
      });
  };

const initiateSMSForgotPassword =
  (phoneNumber: PhomeNumberFormGroupValue): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    return api.finastic.authentication
      .apiFrontendV1AuthenticationInitiateResetPasswordWithOtpPut({
        initiateResetPasswordWithOtpCommand: {
          phoneNumber: mapPhoneNumberFormGroupValueToString(phoneNumber),
        },
      })
      .then(() => {
        dispatch(setState(FeatureState.Success));
      });
  };

export const forgotPasswordHomeActions = {
  setFormValues,
  reset,
  initiateEmailForgotPassword,
  initiateSMSForgotPassword,
};
