import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";

export interface SettingsHomeState {
  featureState: FeatureState;
}

const initialState: SettingsHomeState = {
  featureState: FeatureState.Initial,
};

const settingsHome = createSlice({
  name: "settingsHome",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
  },
});

const { reset, setState } = settingsHome.actions;

export const settingsHomeReducer = settingsHome.reducer;

const deleteUser = (): AppThunk<Promise<any>> => async (dispatch, getState) => {
  dispatch(setState(FeatureState.Loading));

  return api.finastic.user
    .apiFrontendV1UserProfileDelete()
    .then(() => {
      dispatch(setState(FeatureState.Success));
    })
    .catch((error) => {
      dispatch(setState(FeatureState.Error));

      throw error;
    });
};

export const settingsHomeActions = {
  reset,
  deleteUser,
};
