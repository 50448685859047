import React, { InputHTMLAttributes, useMemo, useState } from "react";
import { FieldInputProps } from "formik";
import clsx from "clsx";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import { mdiEye, mdiEyeOff } from "@mdi/js";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: "text" | "email" | "password" | "tel" | "number" | "url";
  hasError?: boolean;
  field?: FieldInputProps<any>;
  forceError?: boolean;
  hasPrependText?: boolean;
}

const Input: React.FC<InputProps> = ({
  type = "text",
  required,
  hasError,
  field,
  forceError,
  hasPrependText,
  ...rest
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const computedType = useMemo(
    () => (type === "password" && isPasswordVisible === true ? "text" : type),
    [isPasswordVisible, type]
  );

  return (
    <div className="relative">
      <input
        {...field}
        {...rest}
        required={required}
        type={computedType}
        // input custom class to share style between Input and InputNumber
        className={clsx(
          "input",
          `${hasError || forceError ? "border-error" : "border-formControl"}`,
          { "pr-10": type === "password" },
          { "pl-5": hasPrependText }
        )}
      />
      {type === "password" && (
        <div
          className={clsx(
            "absolute right-0 top-1/2 transform -translate-y-1/2 py-2 px-3",
            "cursor-pointer"
          )}
          onClick={() => setIsPasswordVisible((old) => !old)}
        >
          {isPasswordVisible ? (
            <MaterialIcon path={mdiEyeOff} className="text-primary h-4" />
          ) : (
            <MaterialIcon path={mdiEye} className="text-primary h-4" />
          )}
        </div>
      )}
    </div>
  );
};

export default Input;
