import { CountryCode, DebtDetailsDto } from "api/generated/finastic";
import Alert from "components/ui/Alert/Alert";
import Button from "components/ui/Button/Button";
import Skeleton from "components/ui/Skeleton/Skeleton";
import DebtCardBg from "features/debts/components/DebtCardBg";
import DisplayBigSum from "features/debts/components/DisplayBigSum";
import DisplayData from "features/debts/DebtsList/components/DisplayData";
import useDateFormatter, { DateFormat } from "hooks/useDateFormatter";
import React, { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { translateBooleanValue } from "utils/common";
import { Tab } from "@headlessui/react";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import { mdiUpload } from "@mdi/js";
import clsx from "clsx";
import DebtContract from "./DebtContract";
import DebtBalance from "./DebtBalance";
import DebtSchedule from "./DebtSchedule";
import DisplayNullableData from "components/DisplayNullableData";
import { useHistory, useLocation } from "react-router";
import DisplayNumberFormat from "components/ui/DisplayNumberFormat/DisplayNumberFormat";
import { useAppSelector } from "app/store";
import DebtPaymentPlanCreationWizard from "features/debts/DebtPaymentPlanCreationWizard/DebtPaymentPlanCreationWizard";
import useEventLogger from "hooks/useEventLogger";
import useLanguageCodeCountryCodeLogics from "hooks/useLanguageCodeCountryCodeLogics";

const tabContentComponents = [DebtBalance, DebtSchedule, DebtContract];

export enum TabKey {
  "Balance" = "Balance",
  "Schedule" = "Schedule",
  "Contract" = "Contract",
}

interface TabI {
  key: TabKey;
  label: string;
}

interface DebtDetailsCardProps extends DebtDetailsDto {
  isLoading?: boolean;
}

const DebtDetailsCard: React.FC<DebtDetailsCardProps> = ({
  isLoading,
  ...debtDetails
}) => {
  const {
    creditorName,
    hasInstalmentPlan,
    outstandingBalance,
    currencyCode,
    remainingInstalments,
    nextPaymentAmount,
    isDueToBePaid,
    isPaymentInProgress,
    debtId,
    countryCode,
  } = debtDetails;

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { showWizard } = useAppSelector((state) => state.debts.debtPaymentPlan);

  const { trackAction: trackTabChange } = useEventLogger(
    "DebtDetailsTabChanged",
    {
      tab: TabKey.Balance,
    }
  );

  const nextInstalment = useMemo(
    () => remainingInstalments?.[0],
    [remainingInstalments]
  );

  const nextPaymentDiffersFromNextInstalment = useMemo(
    () => nextInstalment?.amount !== nextPaymentAmount,
    [nextInstalment?.amount, nextPaymentAmount]
  );

  const { formatter } = useDateFormatter();

  const tabs = useMemo<TabI[]>(
    () => [
      {
        key: TabKey.Balance,
        label: t("debt.balance"),
      },
      {
        key: TabKey.Schedule,
        label: t("debt.schedule"),
      },
      {
        key: TabKey.Contract,
        label: t("debt.contract"),
      },
    ],
    [t]
  );

  const handleTabGroupChange = (index: number) => {
    history.push(`#${tabs[index].key}`);

    trackTabChange({ tab: tabs[index].key });
  };

  const defaultTabIndex = useMemo(() => {
    const tabKeyFromUrl = location.hash.replace("#", "");

    return tabs.findIndex(({ key }) => key === tabKeyFromUrl) ?? 0;
  }, [location.hash, tabs]);

  const { countryCodeAccountIdentifierLabelMap } =
    useLanguageCodeCountryCodeLogics();

  return (
    <div className="rounded-xl shadow">
      <div className="bg-debtCard rounded-t-xl relative md:pr-8 md:pt-8 md:pb-4 md:pl-12 p-4 overflow-hidden">
        <DebtCardBg />
        <div className="relative">
          {isLoading ? (
            <div className="p-4">
              <Skeleton />
            </div>
          ) : (
            <div className="space-y-4">
              <div className="grid gap-4 md:grid-cols-3 grid-cols-1">
                <div className="col-span-2">
                  <DisplayData label={t("debt.creditor")}>
                    <DisplayNullableData value={creditorName} isOnDarkBg>
                      {creditorName}
                    </DisplayNullableData>
                  </DisplayData>
                </div>
                <DisplayData
                  label={
                    countryCodeAccountIdentifierLabelMap[
                      countryCode || CountryCode.Invalid
                    ]
                  }
                >
                  <DisplayNullableData value={debtId} isOnDarkBg />
                </DisplayData>
              </div>

              <div className="grid gap-4 md:grid-cols-3 grid-cols-1">
                {hasInstalmentPlan && (
                  <DisplayData label={t("debt.outstandingBalance")}>
                    <DisplayNullableData value={outstandingBalance} isOnDarkBg>
                      <DisplayNumberFormat value={outstandingBalance} />
                      <span className="inline-block ml-2">{currencyCode}</span>
                    </DisplayNullableData>
                  </DisplayData>
                )}
                {hasInstalmentPlan && (
                  <DisplayData label={t("debt.nextPaymentDate")}>
                    <DisplayNullableData
                      value={nextInstalment?.dueDate}
                      isOnDarkBg
                    >
                      {nextInstalment?.dueDate && (
                        <span>
                          {formatter(nextInstalment.dueDate, DateFormat.Date)}
                        </span>
                      )}
                    </DisplayNullableData>
                  </DisplayData>
                )}
                <DisplayData label={t("debt.instalmentPlan")}>
                  <DisplayNullableData value={hasInstalmentPlan} isOnDarkBg>
                    {translateBooleanValue(hasInstalmentPlan)}
                  </DisplayNullableData>
                </DisplayData>
                {nextPaymentDiffersFromNextInstalment && hasInstalmentPlan && (
                  <DisplayData label={t("debt.nextInstalment")}>
                    <DisplayNullableData
                      value={nextInstalment?.amount}
                      isOnDarkBg
                    >
                      <DisplayNumberFormat value={nextInstalment?.amount} />
                      <span className="inline-block ml-2">{currencyCode}</span>
                    </DisplayNullableData>
                  </DisplayData>
                )}
              </div>
              <div className="border border-debtCard p-4 rounded-xl">
                <div className="relative">
                  {!hasInstalmentPlan ? (
                    <DisplayData label={t("debt.outstandingBalance")}>
                      <DisplayNullableData
                        value={outstandingBalance}
                        isOnDarkBg
                      >
                        {typeof outstandingBalance === "number" && (
                          <DisplayBigSum
                            value={outstandingBalance}
                            currency={currencyCode}
                          />
                        )}
                      </DisplayNullableData>
                    </DisplayData>
                  ) : nextPaymentDiffersFromNextInstalment ? (
                    <DisplayData label={t("debt.nextPayment")}>
                      <DisplayNullableData value={nextPaymentAmount} isOnDarkBg>
                        {typeof nextPaymentAmount === "number" && (
                          <DisplayBigSum
                            value={nextPaymentAmount}
                            currency={currencyCode}
                          />
                        )}
                      </DisplayNullableData>
                    </DisplayData>
                  ) : (
                    <DisplayData label={t("debt.nextInstalment")}>
                      <DisplayNullableData
                        value={nextInstalment?.amount}
                        isOnDarkBg
                      >
                        {typeof nextInstalment?.amount === "number" && (
                          <DisplayBigSum
                            value={nextInstalment.amount}
                            currency={currencyCode}
                          />
                        )}
                      </DisplayNullableData>
                    </DisplayData>
                  )}
                  <MaterialIcon
                    path={mdiUpload}
                    className="h-5 text-white absolute right-full bottom-1 mr-7 hidden md:block"
                  />
                </div>
                {!isPaymentInProgress && !!nextPaymentAmount && !showWizard && (
                  <div className="md:min-w-cardButton mb-4 md:inline-block mt-4">
                    <Button
                      as={Link}
                      to={`/debts/${debtId}/repayment`}
                      variant="primary"
                      block
                    >
                      {t("debt.payButtonText")}
                    </Button>
                  </div>
                )}
                {isPaymentInProgress && (
                  <Alert type="infoAlt" size="small">
                    {t("debt.processingPayment")}
                  </Alert>
                )}
                {!isPaymentInProgress && isDueToBePaid && (
                  <Alert type="errorAlt" size="small">
                    {t("debt.paymentIsDue")}
                  </Alert>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {!isLoading && !showWizard && (
        <div className="relative pt-4">
          <Tab.Group
            onChange={handleTabGroupChange}
            defaultIndex={defaultTabIndex}
          >
            <Tab.List className="border-b border-main pt-4 sm:px-4 flex">
              {tabs.map((tab) => (
                <Tab as={Fragment} key={tab.key}>
                  {({ selected }) => (
                    <button
                      className={clsx(
                        "py-2 px-3 font-exo2 font-light border border-main rounded-t-lg border-b-0 relative",
                        "sm:w-auto outline-none",
                        { "font-semibold": selected }
                      )}
                    >
                      {tab.label}
                      {selected && (
                        <div className="absolute left-0 top-full w-full h-px bg-white" />
                      )}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="outline-none">
              {tabContentComponents.map((Component, index) => (
                <Tab.Panel className="pb-8 pt-4 outline-none" key={index}>
                  <Component {...debtDetails} />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      )}
      {!isLoading && showWizard && <DebtPaymentPlanCreationWizard />}
    </div>
  );
};

export default DebtDetailsCard;
