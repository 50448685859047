/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryCode,
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';
import {
    UserPreferencesDto,
    UserPreferencesDtoFromJSON,
    UserPreferencesDtoFromJSONTyped,
    UserPreferencesDtoToJSON,
} from './UserPreferencesDto';

/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {CountryCode}
     * @memberof UserProfileDto
     */
    countryCode?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    identificationNumber?: string | null;
    /**
     * 
     * @type {UserPreferencesDto}
     * @memberof UserProfileDto
     */
    preferences?: UserPreferencesDto;
}

export function UserProfileDtoFromJSON(json: any): UserProfileDto {
    return UserProfileDtoFromJSONTyped(json, false);
}

export function UserProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'countryCode': !exists(json, 'countryCode') ? undefined : CountryCodeFromJSON(json['countryCode']),
        'identificationNumber': !exists(json, 'identificationNumber') ? undefined : json['identificationNumber'],
        'preferences': !exists(json, 'preferences') ? undefined : UserPreferencesDtoFromJSON(json['preferences']),
    };
}

export function UserProfileDtoToJSON(value?: UserProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
        'countryCode': CountryCodeToJSON(value.countryCode),
        'identificationNumber': value.identificationNumber,
        'preferences': UserPreferencesDtoToJSON(value.preferences),
    };
}

