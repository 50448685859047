import React from "react";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import ro from "date-fns/locale/ro";
import pl from "date-fns/locale/pl";
import clsx from "clsx";

registerLocale("en", enUS);
registerLocale("ro", ro);
registerLocale("pl", pl);

export interface DatepickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
> extends Omit<ReactDatePickerProps, "onChange"> {
  onChange?(
    date: WithRange extends false | undefined
      ? Date | null
      : [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined
  ): void;
  hideNavigation?: boolean;
  hideDayNames?: boolean;
}

function Datepicker<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
>({
  onChange = (
    date: WithRange extends false | undefined
      ? Date | null
      : [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
  calendarClassName,
  hideNavigation,
  hideDayNames,
  ...rest
}: DatepickerProps<CustomModifierNames, WithRange>): JSX.Element {
  const calendarClassNames = clsx(calendarClassName, {
    "react-datepicker--hide-navigation": hideNavigation,
    "react-datepicker--hide-day-names": hideDayNames,
  });

  return (
    <ReactDatePicker<CustomModifierNames, WithRange>
      {...rest}
      calendarClassName={calendarClassNames}
      onChange={onChange}
    />
  );
}

export default Datepicker;
