/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaymentDto,
    PaymentDtoFromJSON,
    PaymentDtoToJSON,
    StartPaymentCommand,
    StartPaymentCommandFromJSON,
    StartPaymentCommandToJSON,
    StartPaymentResultDto,
    StartPaymentResultDtoFromJSON,
    StartPaymentResultDtoToJSON,
} from '../models';

export interface ApiFrontendV1PaymentIdGetRequest {
    id: string;
}

export interface ApiFrontendV1PaymentPostRequest {
    startPaymentCommand?: StartPaymentCommand;
}

/**
 * 
 */
export class PaymentApi extends runtime.BaseAPI {

    /**
     */
    async apiFrontendV1PaymentIdGetRaw(requestParameters: ApiFrontendV1PaymentIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PaymentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFrontendV1PaymentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Payment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1PaymentIdGet(requestParameters: ApiFrontendV1PaymentIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaymentDto> {
        const response = await this.apiFrontendV1PaymentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1PaymentPostRaw(requestParameters: ApiFrontendV1PaymentPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StartPaymentResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartPaymentCommandToJSON(requestParameters.startPaymentCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StartPaymentResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1PaymentPost(requestParameters: ApiFrontendV1PaymentPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StartPaymentResultDto> {
        const response = await this.apiFrontendV1PaymentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
