import clsx from "clsx";
import React from "react";

interface MainProps {
  children: React.ReactNode;
  sidebarIsToggled?: boolean;
}

const Main: React.FC<MainProps> = ({ children, sidebarIsToggled }) => {
  return (
    <main
      className={clsx(
        "flex-1 pb-5 pt-8 mt-12 md:min-w-0 main flex",
        `${sidebarIsToggled ? "xxl:-ml-20" : "xxl:-ml-56"}`
      )}
    >
      {children}
    </main>
  );
};

export default Main;
