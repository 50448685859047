import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";
import { ResetPasswordFormValues } from "./ResetPassword";

export interface ResetPasswordState {
  formValues?: ResetPasswordFormValues;
  featureState: FeatureState;
}

const initialState: ResetPasswordState = {
  formValues: undefined,
  featureState: FeatureState.Initial,
};

const resetPassword = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
    setFormValues(state, { payload }: PayloadAction<ResetPasswordFormValues>) {
      state.formValues = payload;
    },
  },
});

const { setFormValues, reset, setState } = resetPassword.actions;

export const resetPasswordReducer = resetPassword.reducer;

export interface SetPasswordParams {
  password: string;
  token?: string;
}

const setPassword =
  (values: SetPasswordParams): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    if (typeof values.token !== "string") {
      return;
    }

    dispatch(setState(FeatureState.Loading));

    return api.finastic.authentication
      .apiFrontendV1AuthenticationSetPasswordWithTokenPost({
        setPasswordWithTokenCommand: {
          password: values.password,
          token: values.token,
        },
      })
      .then(() => dispatch(setState(FeatureState.Success)));
  };

export const resetPasswordActions = {
  setFormValues,
  reset,
  setPassword,
};
