import React from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import useAuth from "features/auth/useAuth";
import { PrivateLayout } from "components/layout/PrivateLayout";
import DocumentTitle from "components/DocumentTitle";

interface PrivateRouteProps {
  component: React.ElementType;
  layout?: React.ElementType;
  roles?: any[];
  path?: string;
  title?: string;
  exact?: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  layout: Layout = PrivateLayout,
  roles,
  title,
  ...rest
}) => {
  const { userIsLoggedIn, hasUserRole } = useAuth();

  const renderLoggedInContent = (props: RouteComponentProps) =>
    hasUserRole(roles) ? (
      <Layout>
        <DocumentTitle title={title} />
        <Component {...props} />
      </Layout>
    ) : (
      <Redirect to={{ pathname: "/" }} />
    );

  const renderNotLoggedInContent = (props: RouteComponentProps) => (
    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        userIsLoggedIn
          ? renderLoggedInContent(props)
          : renderNotLoggedInContent(props)
      }
    />
  );
};
