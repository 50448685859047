import Button from "components/ui/Button/Button";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { mdiAlertOutline } from "@mdi/js";
import { H1, H5 } from "components/ui/Headings/Headings";

const Error404: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="h-full flex justify-center items-center flex-col">
      <MaterialIcon path={mdiAlertOutline} className="h-12 text-error my-8" />
      {t("pageNotFound.title") !== "" && (
        <div className="font-bold mb-4">
          <H1 noMargin>{t("pageNotFound.title")}</H1>
        </div>
      )}
      <H5>{t("pageNotFound.description")}</H5>
      <p className="mb-8">{t("pageNotFound.errorCode")}</p>

      <Button to="/" as={Link}>
        {t("pageNotFound.backToHomeButton")}
      </Button>
    </div>
  );
};

export default Error404;
