import { PaymentPlanOptionDto } from "api/generated/finastic";
import Input from "components/forms/Input";
import { useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { Step1FormData } from "./Step1";

interface InstalmentCountHiddenFieldProps {
  name: string;
  paymentPlanOptions?: PaymentPlanOptionDto[] | null;
}

const InstalmentCountHiddenField: React.FC<InstalmentCountHiddenFieldProps> = ({
  name,
  paymentPlanOptions,
}) => {
  const {
    values: { optionIndex },
    setFieldValue,
  } = useFormikContext<Step1FormData>();
  const [field] = useField(name);

  useEffect(() => {
    if (paymentPlanOptions) {
      setFieldValue(name, paymentPlanOptions[optionIndex[0]].instalmentCount);
    }
  }, [name, optionIndex, paymentPlanOptions, setFieldValue]);

  return <Input {...field} name={name} hidden />;
};

export default InstalmentCountHiddenField;
