import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { emailConfirmationActions } from "./emailConfirmation.slice";
import { useAppSelector } from "app/store";
import { FeatureState } from "models/FeatureState";
import useAuth from "features/auth/useAuth";
import { authSliceActions } from "features/auth/authSlice";
import LoadingCard from "./components/LoadingCard";
import EmailConfirmationExpired from "./EmailConfirmationExpired";
import ResetPassword from "./ResetPassword";
import useQuery from "hooks/useQuery";

const EmailConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const { userIsLoggedIn } = useAuth();

  const query = useQuery();

  const token = useMemo(
    () => decodeURIComponent(query.get("token") || ""),
    [query]
  );

  const { featureState } = useAppSelector(
    (state) => state.forgotPassword.emailConfirmation
  );

  const confirmEmail = useCallback(async () => {
    try {
      await dispatch(emailConfirmationActions.confirmEmail(token));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userIsLoggedIn) {
      dispatch(authSliceActions.logOut());
    } else {
      confirmEmail();
    }
  }, [confirmEmail, dispatch, token, userIsLoggedIn]);

  if (
    featureState === FeatureState.Loading ||
    featureState === FeatureState.Initial
  ) {
    return <LoadingCard />;
  }

  return featureState === FeatureState.Error ? (
    <EmailConfirmationExpired />
  ) : (
    <ResetPassword token={token} />
  );
};

export default EmailConfirmation;
