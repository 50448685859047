/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationChannel,
    CommunicationChannelFromJSON,
    CommunicationChannelFromJSONTyped,
    CommunicationChannelToJSON,
} from './CommunicationChannel';
import {
    LanguageCode,
    LanguageCodeFromJSON,
    LanguageCodeFromJSONTyped,
    LanguageCodeToJSON,
} from './LanguageCode';

/**
 * 
 * @export
 * @interface UserPreferencesDto
 */
export interface UserPreferencesDto {
    /**
     * 
     * @type {LanguageCode}
     * @memberof UserPreferencesDto
     */
    languageCode?: LanguageCode;
    /**
     * 
     * @type {CommunicationChannel}
     * @memberof UserPreferencesDto
     */
    communicationChannel?: CommunicationChannel;
    /**
     * 
     * @type {boolean}
     * @memberof UserPreferencesDto
     */
    isEmailMarketingAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPreferencesDto
     */
    isPushMarketingAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPreferencesDto
     */
    isSmsMarketingAllowed?: boolean;
}

export function UserPreferencesDtoFromJSON(json: any): UserPreferencesDto {
    return UserPreferencesDtoFromJSONTyped(json, false);
}

export function UserPreferencesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferencesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'languageCode': !exists(json, 'languageCode') ? undefined : LanguageCodeFromJSON(json['languageCode']),
        'communicationChannel': !exists(json, 'communicationChannel') ? undefined : CommunicationChannelFromJSON(json['communicationChannel']),
        'isEmailMarketingAllowed': !exists(json, 'isEmailMarketingAllowed') ? undefined : json['isEmailMarketingAllowed'],
        'isPushMarketingAllowed': !exists(json, 'isPushMarketingAllowed') ? undefined : json['isPushMarketingAllowed'],
        'isSmsMarketingAllowed': !exists(json, 'isSmsMarketingAllowed') ? undefined : json['isSmsMarketingAllowed'],
    };
}

export function UserPreferencesDtoToJSON(value?: UserPreferencesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'languageCode': LanguageCodeToJSON(value.languageCode),
        'communicationChannel': CommunicationChannelToJSON(value.communicationChannel),
        'isEmailMarketingAllowed': value.isEmailMarketingAllowed,
        'isPushMarketingAllowed': value.isPushMarketingAllowed,
        'isSmsMarketingAllowed': value.isSmsMarketingAllowed,
    };
}

