import Content from "components/layout/Content";
import Card from "components/ui/Card/Card";
import Skeleton from "components/ui/Skeleton/Skeleton";
import React from "react";

const LoadingCard: React.FC = () => {
  return (
    <Content>
      <Card
        footer={
          <div className="animate-pulse flex justify-end">
            <p className="h-4 min-w-cardButton bg-disabled rounded" />
          </div>
        }
        isDense
      >
        <Skeleton />
      </Card>
    </Content>
  );
};

export default LoadingCard;
