import Error404 from "features/error/Error404";
import React from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
import { ScrollToTop } from "./ScrollToTop";
import useRoutes from "./useRoutes";

export const RouterView: React.FC = () => {
  const { t } = useTranslation();
  const routes = useRoutes();

  return (
    <ScrollToTop>
      <Switch>
        {routes}

        <PublicRoute component={Error404} title={t("pageTitle.pageNotFound")} />
      </Switch>
    </ScrollToTop>
  );
};
