import InputFormGroup from "components/forms/InputFormGroup";
import PhoneNumberFormGroup, {
  PhomeNumberFormGroupValue,
} from "components/forms/PhoneNumberFormGroup";
import RadioGroup from "components/forms/RadioGroup";
import Content from "components/layout/Content";
import Card from "components/ui/Card/Card";
import { H3 } from "components/ui/Headings/Headings";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import useEventLogger from "hooks/useEventLogger";
import useFormValidations from "hooks/useFormValidations";
import useQuery from "hooks/useQuery";
import { GroupOption } from "models/GroupOption";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import CardFooter from "../components/CardFooter";
import { forgotPasswordHomeActions } from "./forgotPasswordHome.slice";

export type ResetType = "sms" | "email";

export interface ForgotPasswordFormValues {
  resetType: ResetType;
  phoneNumber: PhomeNumberFormGroupValue;
  emailAddress: string;
}

const ForgotPasswordHome: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { phoneNumberValidation } = useFormValidations();
  const query = useQuery();
  const formRef = useRef<FormikProps<ForgotPasswordFormValues>>(null);

  const { trackAction } = useEventLogger("GetForgotPassword");

  const handleSubmit: (
    values: ForgotPasswordFormValues,
    formikHelpers: FormikHelpers<ForgotPasswordFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    dispatch(forgotPasswordHomeActions.setFormValues(values));

    trackAction({});

    if (values.resetType === "sms") {
      await dispatch(
        forgotPasswordHomeActions.initiateSMSForgotPassword(values.phoneNumber)
      );

      history.push(`${history.location.pathname}/sms-passcode`);

      return;
    }

    if (values.resetType === "email") {
      await dispatch(
        forgotPasswordHomeActions.initiateEmailForgotPassword(
          values.emailAddress
        )
      );

      history.push(`${history.location.pathname}/email-validation`);
    }
  };

  useEffect(() => {
    dispatch(forgotPasswordHomeActions.reset());
  }, [dispatch]);

  const resetTypeOptions = useMemo<GroupOption<ResetType>[]>(
    () => [
      {
        label: t("forgotPassword.home.resetType.sms"),
        value: "sms",
      },
      {
        label: t("forgotPassword.home.resetType.email"),
        value: "email",
      },
    ],
    [t]
  );

  const initialResetType = useMemo(() => {
    const valueFromQuery = query.get("resetType") as ResetType;

    return valueFromQuery === "sms" || valueFromQuery === "email"
      ? valueFromQuery
      : "sms";
  }, [query]);

  useEffect(() => {
    formRef.current?.setFieldValue("phoneNumber", {
      prefix: t("config.phoneNumberPrefix"),
      number: undefined,
    });
  }, [t]);

  return (
    <Content>
      <Formik<ForgotPasswordFormValues>
        initialValues={{
          resetType: initialResetType,
          phoneNumber: {
            // We can only set prefilled value based on current language, because country code is not available yet
            prefix:
              t("config.phoneNumberPrefix") !== ""
                ? +t("config.phoneNumberPrefix")
                : undefined,
            number: undefined,
          },
          emailAddress: "",
        }}
        validationSchema={Yup.object({
          phoneNumber: Yup.mixed().when("resetType", {
            // only validate if resetType is sms
            is: "sms",
            then: phoneNumberValidation,
            otherwise: Yup.mixed(),
          }),
          emailAddress: Yup.string().when("resetType", {
            // only validate if resetType is email
            is: "email",
            then: Yup.string()
              .email(t("validationErrors.email"))
              .required(t("validationErrors.required")),
            otherwise: Yup.string(),
          }),
        })}
        onSubmit={handleSubmit}
        validateOnMount
        innerRef={formRef}
      >
        {(formikProps) => (
          <Card
            footer={
              <CardFooter<ForgotPasswordFormValues>
                formikProps={formikProps}
                cancelButtonText={t("forgotPassword.home.cancelButtonText")}
                nextButtonText={
                  formikProps.values.resetType === "email"
                    ? t("forgotPassword.home.sendEmailButtonText")
                    : t("forgotPassword.home.sendSMSButtonText")
                }
              />
            }
            isDense
          >
            <H3>{t("forgotPassword.home.title")}</H3>

            <p className="mb-4">{t("forgotPassword.home.description1")}</p>

            <Form noValidate>
              <RadioGroup
                name="resetType"
                options={resetTypeOptions}
                required
              />

              <p className="mb-4">
                {formikProps.values.resetType === "sms"
                  ? t("forgotPassword.home.descriptionSMS")
                  : t("forgotPassword.home.descriptionEmail")}
              </p>

              {formikProps.values.resetType === "sms" && (
                <PhoneNumberFormGroup
                  name="phoneNumber"
                  label={t("forgotPassword.home.phoneNumber")}
                  placeholder={t("forgotPassword.home.phoneNumberPlaceholder")}
                  required
                />
              )}

              {formikProps.values.resetType === "email" && (
                <InputFormGroup
                  name="emailAddress"
                  type="email"
                  label={t("forgotPassword.home.emailAddress")}
                  placeholder={t("forgotPassword.home.emailAddressPlaceholder")}
                  required
                />
              )}
            </Form>
          </Card>
        )}
      </Formik>
    </Content>
  );
};

export default ForgotPasswordHome;
