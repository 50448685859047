import { useCallback, useState } from "react";
import { useHistory } from "react-router";

export interface UseCancelModal {
  handleCardCancelClick: () => void;
  isCancelModalOpen: boolean;
  handleModalCancelClick: () => void;
  handleModalOkClick: () => void;
}

const useCancelModal = (): UseCancelModal => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const history = useHistory();

  const handleCardCancelClick = useCallback(() => {
    setIsCancelModalOpen(true);
  }, []);

  const handleModalCancelClick = useCallback(() => {
    setIsCancelModalOpen(false);

    history.push("/login");
  }, [history]);

  const handleModalOkClick = useCallback(() => {
    setIsCancelModalOpen(false);
  }, []);

  return {
    handleCardCancelClick,
    isCancelModalOpen,
    handleModalCancelClick,
    handleModalOkClick,
  };
};

export default useCancelModal;
