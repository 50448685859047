import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";
import { mapPhoneNumberFormGroupValueToString } from "utils/mappers";
import { ForgotPasswordSMSPasscodeFormValues } from "./SMSPasscode";

export interface SmsPasscodeState {
  formValues?: ForgotPasswordSMSPasscodeFormValues;
  featureState: FeatureState;
  token?: string | null;
}

const initialState: SmsPasscodeState = {
  formValues: undefined,
  featureState: FeatureState.Initial,
  token: undefined,
};

const smsPasscode = createSlice({
  name: "smsPasscode",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
    setFormValues(
      state,
      { payload }: PayloadAction<ForgotPasswordSMSPasscodeFormValues>
    ) {
      state.formValues = payload;
    },
    checkPasscodeSuccess(
      state,
      { payload }: PayloadAction<string | null | undefined>
    ) {
      state.token = payload;
      state.featureState = FeatureState.Success;
    },
  },
});

const { setFormValues, reset, setState, checkPasscodeSuccess } =
  smsPasscode.actions;

export const smsPasscodeReducer = smsPasscode.reducer;

const checkPasscode =
  (otp: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch(setState(FeatureState.Loading));

    const state = getState();

    const dataFromPreviousStep = state.forgotPassword.home.formValues;

    if (!dataFromPreviousStep) {
      return;
    }

    const phoneNumber = mapPhoneNumberFormGroupValueToString(
      dataFromPreviousStep.phoneNumber
    );

    return api.finastic.authentication
      .apiFrontendV1AuthenticationCheckResetPasswordOtpPut({
        checkResetPasswordOtpCommand: {
          otp,
          phoneNumber,
        },
      })
      .then(({ token }) => {
        dispatch(checkPasscodeSuccess(token));
      })
      .catch((error) => {
        dispatch(setState(FeatureState.Error));

        throw error;
      });
  };

const resendSMS = (): AppThunk<Promise<any>> => async (dispatch, getState) => {
  dispatch(setState(FeatureState.Loading));

  const state = getState();
  const dataFromPreviousStep = state.forgotPassword.home.formValues;

  if (!dataFromPreviousStep) {
    return;
  }

  const phoneNumber = mapPhoneNumberFormGroupValueToString(
    dataFromPreviousStep.phoneNumber
  );

  return api.finastic.authentication.apiFrontendV1AuthenticationInitiateResetPasswordWithOtpPut(
    {
      initiateResetPasswordWithOtpCommand: {
        phoneNumber,
      },
    }
  );
};

export const smsPasscodeActions = {
  setFormValues,
  reset,
  checkPasscode,
  resendSMS,
};
