import { LinkPaymentResolutionDto } from "api/generated/finastic";
import { useAppSelector } from "app/store";
import Content from "components/layout/Content";
import { BackendCode } from "models/BackendCode";
import { FeatureState } from "models/FeatureState";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import InvalidPaymentIdModal from "./components/InvalidPaymentIdModal";
import LoadingDebtCardSkeleton from "./components/LoadingDebtCardSkeleton";
import { paymentLinkActions } from "./paymentLink.slice";

interface ResolvePaymentLinkParams {
  backendCode: BackendCode;
  paymentUuid: string;
}

const ResolvePaymentLink: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { paymentUuid, backendCode } = useParams<ResolvePaymentLinkParams>();
  const { resolvePaymentState } = useAppSelector((state) => state.paymentLink);
  const { criticalErrorHappened, currentGlobalErrorKey } = useAppSelector(
    (state) => state.error
  );

  const resolvePaymentLink = useCallback(async () => {
    try {
      const { debtId } = (await dispatch(
        paymentLinkActions.resolvePaymentLink(paymentUuid, backendCode)
      )) as LinkPaymentResolutionDto;

      if (typeof debtId === "string") {
        history.push(`/link-payment/pay/${backendCode}/${debtId}`);
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, history, paymentUuid, backendCode]);

  useEffect(() => {
    resolvePaymentLink();
  }, [resolvePaymentLink]);

  useEffect(() => {
    dispatch(paymentLinkActions.setBackendCode(backendCode));
  }, [backendCode, dispatch]);

  return (
    <Content>
      <LoadingDebtCardSkeleton />

      {resolvePaymentState === FeatureState.Error &&
        !criticalErrorHappened &&
        !currentGlobalErrorKey && <InvalidPaymentIdModal />}
    </Content>
  );
};

export default ResolvePaymentLink;
