import React, { useEffect, useMemo } from "react";
import Card from "components/ui/Card/Card";
import { H3 } from "components/ui/Headings/Headings";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FeatureState } from "models/FeatureState";
import { useAppSelector } from "app/store";
import { resetPasswordActions } from "./resetPassword.slice";
import useFormValidations from "hooks/useFormValidations";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import CardFooter from "features/forgotPassword/components/CardFooter";
import InputFormGroup from "components/forms/InputFormGroup";
import Modal from "components/ui/Modal/Modal";
import Content from "components/layout/Content";
import useEventLogger from "hooks/useEventLogger";

interface ResetPasswordProps {
  token?: string;
}

export interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ token }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { featureState } = useAppSelector(
    (state) => state.forgotPassword.resetPassword
  );

  const feedbackModalIsOpen = useMemo(
    () => featureState === FeatureState.Success,
    [featureState]
  );

  const { passwordValidation, confirmPasswordValidation } =
    useFormValidations();

  const { trackAction } = useEventLogger("ResetPassword");

  const handleSubmit: (
    values: ResetPasswordFormValues,
    formikHelpers: FormikHelpers<ResetPasswordFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    trackAction({});

    await dispatch(
      resetPasswordActions.setPassword({ token, password: values.password })
    );
  };

  useEffect(() => {
    dispatch(resetPasswordActions.reset());
  }, [dispatch]);

  return (
    <>
      <Content>
        <Formik<ResetPasswordFormValues>
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            password: passwordValidation,
            confirmPassword: confirmPasswordValidation,
          })}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formikProps) => (
            <Card
              footer={
                <CardFooter<ResetPasswordFormValues>
                  formikProps={formikProps}
                  cancelButtonText={t(
                    "forgotPassword.emailValidation.resetPassword.cancelButtonText"
                  )}
                  nextButtonText={t(
                    "forgotPassword.emailValidation.resetPassword.nextButtonText"
                  )}
                />
              }
              isDense
            >
              <H3>{t("forgotPassword.emailValidation.resetPassword.title")}</H3>

              <p className="mb-4">
                {t("forgotPassword.emailValidation.resetPassword.description")}
              </p>

              <Form noValidate>
                <InputFormGroup
                  name="password"
                  type="password"
                  label={t(
                    "forgotPassword.emailValidation.resetPassword.password"
                  )}
                  placeholder={t(
                    "forgotPassword.emailValidation.resetPassword.passwordPlaceholder"
                  )}
                  required
                />
                <InputFormGroup
                  name="confirmPassword"
                  type="password"
                  label={t(
                    "forgotPassword.emailValidation.resetPassword.confirmPassword"
                  )}
                  placeholder={t(
                    "forgotPassword.emailValidation.resetPassword.confirmPasswordPlaceholder"
                  )}
                  required
                />
              </Form>
            </Card>
          )}
        </Formik>
      </Content>
      <Modal
        isOpen={feedbackModalIsOpen}
        title={t(
          "forgotPassword.emailValidation.resetPassword.successModal.title"
        )}
        description={t(
          "forgotPassword.emailValidation.resetPassword.successModal.description"
        )}
        okText={t(
          "forgotPassword.emailValidation.resetPassword.successModal.logInButtonText"
        )}
        type="alert"
        alertType="success"
        onOk={() => history.push("/login")}
      />
    </>
  );
};

export default ResetPassword;
