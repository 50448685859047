import { CommunicationChannel } from "api/generated/finastic";
import RadioGroup from "components/forms/RadioGroup";
import { H3 } from "components/ui/Headings/Headings";
import Modal from "components/ui/Modal/Modal";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { authSliceActions } from "features/auth/authSlice";
import useCommunicationChannelsRadios from "hooks/useCommunicationChannelsRadios";
import { useAppSelector } from "app/store";
import { FeatureState } from "models/FeatureState";
import ContentLoadingSpinner from "components/ui/LoadingSpinner/ContentLoadingSpinner";
import useEventLogger from "hooks/useEventLogger";

export interface PreferredCommModalFormValues {
  comminucationChannel?: CommunicationChannel;
}

const PreferredCommModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { optionsWithIcons, optionsState } = useCommunicationChannelsRadios();
  const { user } = useAppSelector((state) => state.auth);

  const { trackAction: trackChannelSelect } = useEventLogger(
    "SetPreferredCommunicationChannel"
  );

  const handleSubmit: (
    values: PreferredCommModalFormValues,
    formikHelpers: FormikHelpers<PreferredCommModalFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    if (values.comminucationChannel) {
      trackChannelSelect({ channel: values.comminucationChannel });

      await dispatch(
        authSliceActions.setPreferredCommunicationChannel(
          values.comminucationChannel
        )
      );
    }
  };

  return (
    <Formik<PreferredCommModalFormValues>
      initialValues={{
        comminucationChannel: undefined,
      }}
      validationSchema={Yup.object({
        comminucationChannel: Yup.string()
          .required(t("validationErrors.required"))
          .nullable(),
      })}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {(formikProps) => (
        <Modal
          isOpen={
            user?.preferences?.communicationChannel ===
              CommunicationChannel.Invalid ||
            !user?.preferences?.communicationChannel
          }
          okText={t("preferredCommunicationModal.saveButtonText")}
          size="medium"
          onOk={() => formikProps.submitForm()}
          okButtonProps={{
            disabled: !formikProps.isValid,
            isLoading: formikProps.isSubmitting,
          }}
        >
          <div className="w-80 mx-auto">
            <H3>{t("preferredCommunicationModal.title")}</H3>
            <p className="mb-8">
              {t("preferredCommunicationModal.description")}
            </p>

            {optionsState === FeatureState.Success && (
              <Form noValidate>
                <RadioGroup
                  name="comminucationChannel"
                  options={optionsWithIcons}
                  required
                />
              </Form>
            )}

            {optionsState === FeatureState.Loading && <ContentLoadingSpinner />}
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default PreferredCommModal;
