/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartPaymentCommand
 */
export interface StartPaymentCommand {
    /**
     * 
     * @type {string}
     * @memberof StartPaymentCommand
     */
    debtId: string;
    /**
     * 
     * @type {number}
     * @memberof StartPaymentCommand
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof StartPaymentCommand
     */
    currencyCode: string;
}

export function StartPaymentCommandFromJSON(json: any): StartPaymentCommand {
    return StartPaymentCommandFromJSONTyped(json, false);
}

export function StartPaymentCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartPaymentCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'debtId': json['debtId'],
        'amount': json['amount'],
        'currencyCode': json['currencyCode'],
    };
}

export function StartPaymentCommandToJSON(value?: StartPaymentCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'debtId': value.debtId,
        'amount': value.amount,
        'currencyCode': value.currencyCode,
    };
}

