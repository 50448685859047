import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { LanguageCode } from "api/generated/finastic";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";
import { mapPhoneNumberFormGroupValueToString } from "utils/mappers";
import { SetPasswordFormValues } from "./SetPassword";

export interface SetPasswordState {
  formValues?: SetPasswordFormValues;
  featureState: FeatureState;
}

const initialState: SetPasswordState = {
  formValues: undefined,
  featureState: FeatureState.Initial,
};

const setPassword = createSlice({
  name: "setPassword",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
    setFormValues(state, { payload }: PayloadAction<SetPasswordFormValues>) {
      state.formValues = payload;
    },
  },
});

const { setFormValues, reset, setState } = setPassword.actions;

export const setPasswordReducer = setPassword.reducer;

const register =
  (values: SetPasswordFormValues): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch(setState(FeatureState.Loading));

    const state = getState();

    const dataFromFirstStep = state.registration.profileData.formValues;
    const dataFromSecondStep = state.registration.oneTimePasscode;

    if (
      !dataFromFirstStep ||
      !dataFromSecondStep ||
      typeof dataFromSecondStep.token !== "string"
    ) {
      return;
    }

    return api.finastic.registration
      .apiFrontendV1RegistrationRegisterCustomerPost({
        registerCustomerCommand: {
          countryCode: dataFromFirstStep.countryCode,
          personIdentifier: dataFromFirstStep.personIdentifier + "",
          accountIdentifier: dataFromFirstStep.accountIdentifier,
          firstName: dataFromFirstStep.firstName,
          lastName: dataFromFirstStep.lastName,
          emailAddress: dataFromFirstStep.emailAddress,
          phoneNumber: mapPhoneNumberFormGroupValueToString(
            dataFromFirstStep.phoneNumber
          ),
          password: values.password,
          languageCode: values.languageCode as LanguageCode,
          token: dataFromSecondStep.token,
          isPrivacyAndCookiePolicyAccepted:
            dataFromFirstStep.isPrivacyAndCookiePolicyAccepted,
          isTacAndPublicNoticeAccepted:
            dataFromFirstStep.isTacAndPublicNoticeAccepted,
          isEmailMarketingAllowed: dataFromFirstStep.isEmailMarketingAllowed,
          isPushMarketingAllowed: dataFromFirstStep.isPushMarketingAllowed,
          isSmsMarketingAllowed: dataFromFirstStep.isSmsMarketingAllowed,
        },
      })
      .then(() => {
        dispatch(setState(FeatureState.Success));
      });
  };

export const setPasswordActions = {
  setFormValues,
  reset,
  register,
};
