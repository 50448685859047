import clsx from "clsx";
import React from "react";

interface HeadingsProps {
  children: React.ReactNode;
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

interface HeadingProps {
  children: React.ReactNode;
  noMargin?: boolean;
}

const Headings: React.FC<HeadingsProps> = ({ children, level }) => {
  switch (level) {
    case 1:
      return <H1>{children}</H1>;
    case 2:
      return <H2>{children}</H2>;
    case 3:
      return <H3>{children}</H3>;
    case 4:
      return <H4>{children}</H4>;
    case 5:
      return <H5>{children}</H5>;
    case 6:
      return <H5>{children}</H5>;
    default:
      return null;
  }
};

export default Headings;

export const H1: React.FC<HeadingProps> = ({ children, noMargin }) => {
  return (
    <h1 className={clsx("text-5xl font-exo2", { "mb-12": !noMargin })}>
      {children}
    </h1>
  );
};

export const H2: React.FC<HeadingProps> = ({ children, noMargin }) => {
  return (
    <h2 className={clsx("text-4xl font-exo2", { "mb-9": !noMargin })}>
      {children}
    </h2>
  );
};

export const H3: React.FC<HeadingProps> = ({ children, noMargin }) => {
  return (
    <h3 className={clsx("text-3xl font-exo2", { "mb-8": !noMargin })}>
      {children}
    </h3>
  );
};

export const H4: React.FC<HeadingProps> = ({ children, noMargin }) => {
  return (
    <h4 className={clsx("text-2xl font-exo2", { "mb-6": !noMargin })}>
      {children}
    </h4>
  );
};

export const H5: React.FC<HeadingProps> = ({ children, noMargin }) => {
  return (
    <h5 className={clsx("text-xl font-exo2", { "mb-4": !noMargin })}>
      {children}
    </h5>
  );
};

export const H6: React.FC<HeadingProps> = ({ children }) => {
  return <h6 className="text-base font-exo2 font-semibold">{children}</h6>;
};
