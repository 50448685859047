import { useAppSelector } from "app/store";
import Container from "./Container";
import Footer from "./Footer/Footer";
import Header from "./Header";
import Main from "./Main";

interface PaymentLinkLayoutProps {
  children: React.ReactNode;
}

export const PaymentLinkLayout: React.FC<PaymentLinkLayoutProps> = ({
  children,
}) => {
  const { debt, backendCode } = useAppSelector((state) => state.paymentLink);

  return (
    <div className="min-h-screen flex flex-col">
      <Header
        backendCode={backendCode}
        userIsFinasticUser={debt?.isFinasticUser}
      />
      <Main sidebarIsToggled>
        <Container>{children}</Container>
      </Main>
      <Footer
        backendCode={backendCode}
        userIsFinasticUser={debt?.isFinasticUser}
      />
    </div>
  );
};
