import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { RequestErrorBody } from "api/middleware/errorHandlingMiddleware";
import { AppThunk } from "app/store";
import { CustomError } from "models/Error";
import { mapPhoneNumberFormGroupValueToString } from "utils/mappers";
import { ProfileDataFormValues } from "./ProfileData";

export enum CheckCustomerStatus {
  Success = "Success",
  AlreadyRegistered = "AlreadyRegistered",
  NoMatch = "NoMatch",
}
export interface ProfileDataState {
  formValues?: ProfileDataFormValues;
  checkCustomerStatus?: CheckCustomerStatus;
}

const initialState: ProfileDataState = {
  formValues: undefined,
  checkCustomerStatus: undefined,
};

const profileData = createSlice({
  name: "profileData",
  initialState,
  reducers: {
    reset: () => initialState,
    setFormValues(state, { payload }: PayloadAction<ProfileDataFormValues>) {
      state.formValues = payload;
    },
    setCheckCustomerStatus(
      state,
      { payload }: PayloadAction<CheckCustomerStatus>
    ) {
      state.checkCustomerStatus = payload;
    },
  },
});

const { setFormValues, reset, setCheckCustomerStatus } = profileData.actions;

export const profileDataReducer = profileData.reducer;

const checkCustomer =
  (values: ProfileDataFormValues): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    if (typeof values.personIdentifier !== "string") {
      return;
    }

    try {
      await api.finastic.registration.apiFrontendV1RegistrationCheckCustomerPut(
        {
          checkCustomerCommand: {
            countryCode: values.countryCode,
            personIdentifier: values.personIdentifier,
            accountIdentifier: values.accountIdentifier,
            firstName: values.firstName,
            lastName: values.lastName,
            emailAddress: values.emailAddress,
            phoneNumber: mapPhoneNumberFormGroupValueToString(
              values.phoneNumber
            ),
          },
        }
      );

      dispatch(setCheckCustomerStatus(CheckCustomerStatus.Success));
    } catch (error: any) {
      const responseText = await error.response?.text();

      if (responseText === "") {
        throw error;
      }

      const errorJson: RequestErrorBody = JSON.parse(responseText);

      if (
        errorJson.errors &&
        Object.keys(errorJson.errors).includes(CustomError.UserAlreadyExists)
      ) {
        dispatch(setCheckCustomerStatus(CheckCustomerStatus.AlreadyRegistered));
      } else if (
        errorJson.errors &&
        Object.keys(errorJson.errors).includes(CustomError.NoMatchingCustomer)
      ) {
        dispatch(setCheckCustomerStatus(CheckCustomerStatus.NoMatch));
      }

      throw error;
    }
  };

export const profileDataActions = {
  setFormValues,
  reset,
  checkCustomer,
};
