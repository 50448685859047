import DisplayNumberFormat from "components/ui/DisplayNumberFormat/DisplayNumberFormat";
import React from "react";

interface DisplayBigSumProps {
  value: number;
  currency?: string | null;
}

const DisplayBigSum: React.FC<DisplayBigSumProps> = ({ value, currency }) => (
  <>
    <span className="font-exo2 font-bold text-xl">
      <DisplayNumberFormat value={value} />
    </span>
    {currency && (
      <span className="font-exo2 text-xl inline-block ml-2">{currency}</span>
    )}
  </>
);

export default DisplayBigSum;
