import Checkbox from "components/forms/Checkbox";
import InputFormGroup from "components/forms/InputFormGroup";
import InputNumberFormGroup from "components/forms/InputNumberFormGroup";
import PhoneNumberFormGroup, {
  PhomeNumberFormGroupValue,
} from "components/forms/PhoneNumberFormGroup";
import SelectFormGroup from "components/forms/SelectFormGroup";
import Card from "components/ui/Card/Card";
import { H3, H6 } from "components/ui/Headings/Headings";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import {
  registrationParentPath,
  registrationPaths,
} from "../RegistrationRouteSwitch";
import CardFooter from "../components/CardFooter";
import { CheckCustomerStatus, profileDataActions } from "./profileData.slice";
import useFormValidations from "hooks/useFormValidations";
import Modal from "components/ui/Modal/Modal";
import { useAppSelector } from "app/store";
import { getEnumValuesOptions } from "utils/common";
import { CountryCode, LanguageCode } from "api/generated/finastic";
import useEventLogger from "hooks/useEventLogger";
import useLanguageCodeCountryCodeLogics from "hooks/useLanguageCodeCountryCodeLogics";

export interface ProfileDataFormValues {
  countryCode?: CountryCode;
  firstName: string;
  lastName: string;
  personIdentifier: string | null;
  accountIdentifier: string;
  emailAddress: string;
  phoneNumber: PhomeNumberFormGroupValue;
  isTacAndPublicNoticeAccepted: boolean;
  isPrivacyAndCookiePolicyAccepted: boolean;
  isPushMarketingAllowed: boolean;
  isSmsMarketingAllowed: boolean;
  isEmailMarketingAllowed: boolean;
}

const ProfileData: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { phoneNumberValidation } = useFormValidations();
  const formRef = useRef<FormikProps<ProfileDataFormValues>>(null);
  const {
    countryCodeAccountIdentifierLabelMap,
    countryCodeAccountIdentifierPlaceholderMap,
    countryCodePersonIdentifierLengthMap,
    languageCodeCountryCodeMap,
    personIdentifierLabelMap,
    personIdentifierPlaceholderMap,
    countryCodePhonePrefixMap,
    countryCodeRegistrationEmailMarketingLabelMap,
    countryCodePrivacyPolicyLabelMap,
    countryCodeRegistrationPushMarketingLabelMap,
    countryCodeRegistrationSMSMarketingLabelMap,
    countryCodeTACLabelMap,
  } = useLanguageCodeCountryCodeLogics();
  const [selectedCountryCode, setSelectedCountryCode] = useState<CountryCode>(
    CountryCode.Invalid
  );

  const { checkCustomerStatus } = useAppSelector(
    (state) => state.registration.profileData
  );

  const [feedbackModalIsOpen, setFeedbackModalIsOpen] = useState(false);

  const { trackAction } = useEventLogger("RegistrationSubmitProfileData");

  const handleSubmit: (
    values: ProfileDataFormValues,
    formikHelpers: FormikHelpers<ProfileDataFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    dispatch(profileDataActions.setFormValues(values));

    trackAction({});

    try {
      await dispatch(profileDataActions.checkCustomer(values));

      history.push(`/${registrationParentPath}${registrationPaths[1]}`);
    } catch (error) {
      setFeedbackModalIsOpen(true);
    } finally {
      setSubmitting(false);
    }
  };

  const countryOptions = getEnumValuesOptions<CountryCode>(
    CountryCode,
    "CountryCode"
  );

  const personIdentifierLengthRequiredByCountry = useMemo(
    () =>
      countryCodePersonIdentifierLengthMap[
        selectedCountryCode || CountryCode.Invalid
      ],
    [countryCodePersonIdentifierLengthMap, selectedCountryCode]
  );

  const handleCountryCodeChange = useCallback(
    (value) => setSelectedCountryCode(value),
    []
  );

  useEffect(() => {
    if (!formRef.current?.values.countryCode) {
      formRef.current?.setFieldValue(
        "countryCode",
        languageCodeCountryCodeMap[i18n.language as LanguageCode]
      );

      setSelectedCountryCode(
        languageCodeCountryCodeMap[i18n.language as LanguageCode] ||
          CountryCode.Invalid
      );
    }
  }, [i18n.language, languageCodeCountryCodeMap]);

  useEffect(() => {
    const currentPhoneNumberData = formRef.current?.values.phoneNumber;

    formRef.current?.setFieldValue("phoneNumber", {
      prefix:
        countryCodePhonePrefixMap[selectedCountryCode] !== ""
          ? +countryCodePhonePrefixMap[selectedCountryCode]
          : undefined,
      number: currentPhoneNumberData?.number,
    });
  }, [countryCodePhonePrefixMap, selectedCountryCode]);

  useEffect(() => {
    dispatch(profileDataActions.reset());
  }, [dispatch]);

  return (
    <>
      <div className="max-w-4xl my-8 mx-auto">
        <Formik<ProfileDataFormValues>
          initialValues={{
            countryCode: undefined,
            firstName: "",
            lastName: "",
            personIdentifier: null,
            accountIdentifier: "",
            emailAddress: "",
            phoneNumber: {
              prefix: undefined,
              number: undefined,
            },
            isTacAndPublicNoticeAccepted: false,
            isPrivacyAndCookiePolicyAccepted: false,
            isPushMarketingAllowed: false,
            isSmsMarketingAllowed: false,
            isEmailMarketingAllowed: false,
          }}
          validationSchema={Yup.object({
            countryCode: Yup.string().required(t("validationErrors.required")),
            firstName: Yup.string()
              .required(t("validationErrors.required"))
              .matches(/^([^0-9]*)$/, t("validationErrors.numbersNotAllowed")),
            lastName: Yup.string()
              .required(t("validationErrors.required"))
              .matches(/^([^0-9]*)$/, t("validationErrors.numbersNotAllowed")),
            personIdentifier: Yup.string()
              .nullable()
              .required(t("validationErrors.required"))
              .test(
                "personIdentifier.length",
                function (value: string | null | undefined, { createError }) {
                  return !!value &&
                    value.length === personIdentifierLengthRequiredByCountry
                    ? true
                    : createError({
                        message: t("validationErrors.length", {
                          length: personIdentifierLengthRequiredByCountry,
                        }),
                        path: "personIdentifier",
                      });
                }
              ),
            accountIdentifier: Yup.string()
              .required(t("validationErrors.required"))
              .max(50),
            emailAddress: Yup.string()
              .email(t("validationErrors.email"))
              .required(t("validationErrors.required")),
            phoneNumber: phoneNumberValidation,
            isTacAndPublicNoticeAccepted: Yup.bool().oneOf(
              [true],
              t("validationErrors.requiredCheckbox")
            ),
            isPrivacyAndCookiePolicyAccepted: Yup.bool().oneOf(
              [true],
              t("validationErrors.requiredCheckbox")
            ),
          })}
          onSubmit={handleSubmit}
          validateOnMount
          innerRef={formRef}
        >
          {(formikProps) => (
            <Card
              footer={
                <CardFooter<ProfileDataFormValues>
                  formikProps={formikProps}
                  cancelButtonText={t(
                    "registration.profileData.cancelButtonText"
                  )}
                  nextButtonText={t("registration.profileData.nextButtonText")}
                />
              }
            >
              <H6>{t("registration.step1")}</H6>
              <H3>{t("registration.profileData.title")}</H3>

              <Form noValidate>
                <div className="divide-y divide-main">
                  <div>
                    <H6>{t("registration.profileData.region")}</H6>

                    <div className="grid gap-16 md:grid-cols-2 grid-cols-1 max-w-full mt-4">
                      <div>
                        <SelectFormGroup<CountryCode>
                          options={countryOptions}
                          name="countryCode"
                          label={t("registration.profileData.country")}
                          onChange={handleCountryCodeChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {!!formikProps.values.countryCode && (
                    <>
                      <div className="pt-4">
                        <H6>{t("registration.profileData.clientData")}</H6>

                        <div className="grid gap-x-16 md:grid-cols-2 grid-cols-1 max-w-full mt-4">
                          <div>
                            <InputFormGroup
                              name="firstName"
                              label={t("registration.profileData.firstName")}
                              placeholder={t(
                                "registration.profileData.firstNamePlaceholder"
                              )}
                              required
                              maxLength={100}
                            />
                          </div>
                          <div>
                            <InputFormGroup
                              name="lastName"
                              label={t("registration.profileData.lastName")}
                              placeholder={t(
                                "registration.profileData.lastNamePlaceholder"
                              )}
                              required
                              maxLength={100}
                            />
                          </div>
                          <div>
                            <InputNumberFormGroup
                              name="personIdentifier"
                              label={
                                personIdentifierLabelMap[
                                  formRef.current?.values.countryCode ||
                                    CountryCode.Invalid
                                ]
                              }
                              placeholder={
                                personIdentifierPlaceholderMap[
                                  formRef.current?.values.countryCode ||
                                    CountryCode.Invalid
                                ]
                              }
                              required
                              thousandSeparator={false}
                              format={[
                                // make an array with length equal to the size that the country needs
                                ...Array(
                                  personIdentifierLengthRequiredByCountry
                                ),
                              ]
                                .map(() => "#")
                                .join("")}
                              valueAsString
                            />
                          </div>
                          <div>
                            <InputFormGroup
                              name="accountIdentifier"
                              label={
                                countryCodeAccountIdentifierLabelMap[
                                  formRef.current?.values.countryCode ||
                                    CountryCode.Invalid
                                ]
                              }
                              placeholder={
                                countryCodeAccountIdentifierPlaceholderMap[
                                  formRef.current?.values.countryCode ||
                                    CountryCode.Invalid
                                ]
                              }
                              required
                              maxLength={50}
                              help={t(
                                "registration.profileData.accountIdentifierHelp"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pt-4">
                        <H6>{t("registration.profileData.accountData")}</H6>

                        <div className="grid gap-x-16 md:grid-cols-2 grid-cols-1 max-w-full mt-4">
                          <div>
                            <InputFormGroup
                              name="emailAddress"
                              type="email"
                              label={t("registration.profileData.emailAddress")}
                              placeholder={t(
                                "registration.profileData.emailAddressPlaceholder"
                              )}
                              required
                            />
                          </div>
                          <div>
                            <PhoneNumberFormGroup
                              name="phoneNumber"
                              label={t("registration.profileData.phoneNumber")}
                              placeholder={t(
                                "registration.profileData.phoneNumberPlaceholder"
                              )}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pt-4">
                        <H6>{t("registration.profileData.terms")}</H6>

                        <Checkbox name="isTacAndPublicNoticeAccepted" required>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                countryCodeTACLabelMap[
                                  formRef.current?.values.countryCode ||
                                    CountryCode.Invalid
                                ],
                            }}
                          />
                        </Checkbox>
                        <hr className="border-main max-w-xs" />
                        <Checkbox
                          name="isPrivacyAndCookiePolicyAccepted"
                          required
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                countryCodePrivacyPolicyLabelMap[
                                  formRef.current?.values.countryCode ||
                                    CountryCode.Invalid
                                ],
                            }}
                          />
                        </Checkbox>
                        <hr className="border-main max-w-xs" />
                        <Checkbox name="isPushMarketingAllowed">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                countryCodeRegistrationPushMarketingLabelMap[
                                  formRef.current?.values.countryCode ||
                                    CountryCode.Invalid
                                ],
                            }}
                          />
                        </Checkbox>
                        <hr className="border-main max-w-xs" />
                        <Checkbox name="isSmsMarketingAllowed">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                countryCodeRegistrationSMSMarketingLabelMap[
                                  formRef.current?.values.countryCode ||
                                    CountryCode.Invalid
                                ],
                            }}
                          />
                        </Checkbox>
                        <hr className="border-main max-w-xs" />
                        <Checkbox name="isEmailMarketingAllowed">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                countryCodeRegistrationEmailMarketingLabelMap[
                                  formRef.current?.values.countryCode ||
                                    CountryCode.Invalid
                                ],
                            }}
                          />
                        </Checkbox>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            </Card>
          )}
        </Formik>
      </div>
      {checkCustomerStatus === CheckCustomerStatus.AlreadyRegistered && (
        <Modal
          isOpen={feedbackModalIsOpen}
          title={t("registration.alreadyRegisteredModal.title")}
          okText={t(
            "registration.alreadyRegisteredModal.backToLoginButtonText"
          )}
          type="alert"
          alertType="warning"
          onOk={() => history.push("/login")}
        />
      )}
      {checkCustomerStatus === CheckCustomerStatus.NoMatch && (
        <Modal
          isOpen={feedbackModalIsOpen}
          title={t("registration.noMatchModal.title")}
          description={t("registration.noMatchModal.description")}
          okText={t("registration.noMatchModal.backToRegistrationButtonText")}
          type="alert"
          alertType="error"
          onOk={() => setFeedbackModalIsOpen(false)}
        />
      )}
    </>
  );
};

export default ProfileData;
