import React, { ButtonHTMLAttributes } from "react";
import clsx from "clsx";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?:
    | "primary"
    | "outline"
    | "ghost"
    | "darkCard"
    | "outlineError"
    | "error";
  size?: "default" | "medium";
  block?: boolean;
  as?: React.ElementType;
  to?: string;
  isLoading?: boolean;
  iconButton?: boolean;
}

// .button-comp to distinguish between Button a tag and normal a tag styling
const baseClasses =
  "button-comp text-center font-exo2 font-semibold shadow uppercase border border-primary hover:shadow-none focus:outline-none";
const disabledBaseClasses = "disabled:shadow-none disabled:pointer-events-none";
const primaryClasses =
  "bg-primary text-white disabled:bg-disabled focus:bg-primaryDark focus:border-primaryDark focus:shadow disabled:border-disabled";
const outlineClasses =
  "text-primary bg-white disabled:text-disabled focus:text-white focus:bg-primaryDark focus:border-primaryDark focus:shadow disabled:border-disabled";
const ghostClasses =
  "border-white shadow-none normal-case text-primary font-calibri hover:text-primaryDark disabled:text-disabled";
const darkCardClasses =
  "bg-darkCardButtonBg text-primary hover:bg-primary hover:text-white focus:text-white focus:bg-primaryDark focus:shadow focus:border-primaryDark disabled:border-disabled";
const mediumClasses = "py-0.5";
const outlineErrorClasses =
  "text-error border-error bg-white disabled:text-disabled focus:text-white focus:bg-error focus:border-error focus:shadow disabled:border-disabled";
const errorClasses =
  "bg-error border-error text-white disabled:bg-disabled focus:bg-error focus:border-error focus:shadow disabled:border-disabled";

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = "primary",
      size = "default",
      block,
      as: Component = "button",
      isLoading = false,
      disabled,
      iconButton,
      ...rest
    },
    ref
  ) => {
    return (
      <Component
        className={clsx(
          baseClasses,
          disabledBaseClasses,
          { [primaryClasses]: variant === "primary" },
          { [outlineClasses]: variant === "outline" },
          { [darkCardClasses]: variant === "darkCard" },
          { [ghostClasses]: variant === "ghost" },
          { [outlineErrorClasses]: variant === "outlineError" },
          { [errorClasses]: variant === "error" },
          { [mediumClasses]: size === "medium" },
          { "w-full": block },
          `${
            iconButton
              ? "rounded-full h-8 w-8 inline-flex items-center justify-center"
              : "rounded-3xl px-6 py-2 inline-block"
          }`
        )}
        {...rest}
        disabled={isLoading || disabled}
        ref={ref}
      >
        {isLoading && (
          <LoadingSpinner className="inline-block mr-3 relative -top-px" />
        )}

        {children}
      </Component>
    );
  }
);

export default Button;
