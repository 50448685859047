import React, { useCallback, useEffect, useState } from "react";
import * as RadixSlider from "@radix-ui/react-slider";
import * as Tooltip from "@radix-ui/react-tooltip";
import SliderArrow from "assets/images/slider-arrow.svg";

export type SliderProps = RadixSlider.SliderProps &
  React.RefAttributes<HTMLSpanElement> & {
    displayTooltipContent?: (value: number[] | undefined) => number | undefined;
    displayMinContent?: (value: number) => number;
    displayMaxContent?: (value: number) => number;
  };

const Slider: React.FC<SliderProps> = ({
  min = 0,
  max = 100,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onValueChange = (value: number[]) => {},
  value,
  displayTooltipContent = (value: number[] | undefined) => value?.[0],
  displayMinContent = (value: number) => value,
  displayMaxContent = (value: number) => value,
  ...sliderProps
}) => {
  const [innerValue, setInnerValue] = useState<number[] | undefined>(
    value ?? [min]
  );

  const handleChange = useCallback(
    (newValue: number[]) => {
      setInnerValue(newValue);

      onValueChange(newValue);
    },
    [onValueChange]
  );

  const decreaseSliderValue = useCallback(() => {
    handleChange(
      typeof innerValue?.[0] !== "number" || innerValue[0] === min
        ? [min]
        : [innerValue[0] - 1]
    );
  }, [min, handleChange, innerValue]);

  const increaseSliderValue = useCallback(() => {
    handleChange(
      typeof innerValue?.[0] !== "number" || innerValue[0] === max
        ? [max]
        : [innerValue[0] + 1]
    );
  }, [max, handleChange, innerValue]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setInnerValue(value);
    }
  }, [value]);

  return (
    <div className="flex items-center">
      <button onClick={decreaseSliderValue} type="button">
        <img
          src={SliderArrow}
          alt="-"
          className="transform rotate-180 select-none"
        />
      </button>
      <RadixSlider.Root
        {...sliderProps}
        className="relative flex items-center select-none h-6 mx-4 flex-1"
        onValueChange={handleChange}
        min={min}
        max={max}
        value={innerValue}
      >
        <div className="absolute bottom-full left-0.5 leading-0 mb-1">
          {displayMinContent(min)}
        </div>
        <div className="absolute bottom-full right-0.5 leading-0 mb-1">
          {displayMaxContent(max)}
        </div>
        <RadixSlider.Track className="bg-sliderBg relative flex-grow h-1 rounded">
          <RadixSlider.Range className="absolute bg-primary h-full rounded" />
        </RadixSlider.Track>
        <Tooltip.Provider delayDuration={0} skipDelayDuration={0}>
          <Tooltip.Root open>
            <Tooltip.Trigger asChild>
              <RadixSlider.Thumb className="block bg-primary h-3 w-3 rounded-full outline-none cursor-pointer" />
            </Tooltip.Trigger>
            <Tooltip.Content
              side="top"
              className="bg-black text-white leading-none rounded-md ml-px"
              style={{ padding: "4px 5px 2px" }}
              sideOffset={4}
            >
              {displayTooltipContent(innerValue)}
              <Tooltip.Arrow className="ml-px" width={8} height={7} />
            </Tooltip.Content>
          </Tooltip.Root>
        </Tooltip.Provider>
      </RadixSlider.Root>
      <button onClick={increaseSliderValue} type="button">
        <img src={SliderArrow} alt="+" className="select-none" />
      </button>
    </div>
  );
};

export default Slider;
