import { Fragment, useMemo } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { DebtDetailsDto } from "api/generated/finastic";
import PayPointPayment from "./PayPointPayment";
import CardPayment from "./CardPayment";
import { FormikProps } from "formik";
import PayULogo from "assets/images/payu-logo.svg";
import PayPointLogo from "assets/images/paypoint-logo.svg";
// import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
// import { mdiBarcode, mdiCreditCardOutline } from "@mdi/js";

export enum PaymentSchedule {
  Partial = "Partial",
  Full = "Full",
}

export interface DebtPaymentFormValues {
  paymentSchedule?: PaymentSchedule;
  amount: number | null;
  currencyCode?: string | null;
  debtId?: string | null;
  languageCode: string;
}

export interface DebtPaymentOptionsProps {
  formikProps: FormikProps<DebtPaymentFormValues>;
  debtDetails: DebtDetailsDto;
  redirecting?: boolean;
}

const DebtPaymentOptions: React.FC<DebtPaymentOptionsProps> = (props) => {
  const { debtDetails } = props;

  const barcodeId = useMemo(
    () => debtDetails?.payPointBarcode,
    [debtDetails?.payPointBarcode]
  );

  const tabs = useMemo(
    () => [
      {
        key: "PayU",
        // label: "PayU",
        // icon: (
        //   <MaterialIcon path={mdiCreditCardOutline} style={{ width: "20px" }} />
        // ),
        logo: <img src={PayULogo} alt="PayULogo" className="h-6 px-3" />,
        component: () => <CardPayment {...props} />,
      },
      ...(barcodeId !== undefined && barcodeId !== null
        ? [
            {
              key: "PayPoint",
              // label: "PayPoint",
              // icon: (
              //   <MaterialIcon path={mdiBarcode} style={{ width: "20px" }} />
              // ),
              logo: (
                <img
                  src={PayPointLogo}
                  alt="PayPointLogo"
                  className="h-7 px-3"
                />
              ),
              component: () => <PayPointPayment barcodeId={barcodeId} />,
            },
          ]
        : []),
    ],
    [barcodeId, props]
  );

  return (
    <Tab.Group>
      <Tab.List className="border-b border-main pt-4 sm:px-4 flex">
        {tabs.map((tab) => (
          <Tab as={Fragment} key={tab.key}>
            {({ selected }) => (
              <button
                className={clsx(
                  "py-2 px-3 font-exo2 font-light border border-main rounded-t-lg border-b-0 relative",
                  "sm:w-auto outline-none",
                  { "font-semibold": selected }
                )}
              >
                {/* <div className="flex justify-between gap-2">
                  {tab.icon || null}
                  {tab.label || null}
                </div> */}
                {tab.logo || null}
                {selected && (
                  <div className="absolute left-0 top-full w-full h-px bg-white" />
                )}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="outline-none">
        {tabs.map(({ component }, index) => (
          <Tab.Panel className="pt-4 outline-none" key={index}>
            {component}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default DebtPaymentOptions;
