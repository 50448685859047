import Content from "components/layout/Content";
import Breadcrumbs, {
  BreadcrumbsItem,
} from "components/ui/Breadcrumbs/Breadcrumbs";
import Card from "components/ui/Card/Card";
import CardFooter from "./components/CardFooter";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { privacyConsentActions } from "./privacyConsent.slice";
import { H3, H6 } from "components/ui/Headings/Headings";
import { FeatureState } from "models/FeatureState";
import { useAppSelector } from "app/store";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import Toggle from "components/forms/Toggle";
import {
  mdiCellphoneMessage,
  mdiMessageReplyText,
  mdiEmailOutline,
} from "@mdi/js";
import useEventLogger from "hooks/useEventLogger";
import useLanguageCodeCountryCodeLogics from "hooks/useLanguageCodeCountryCodeLogics";
import { CountryCode } from "api/generated/finastic";

export interface PrivacyConsentFormValues {
  isPushMarketingAllowed: boolean;
  isSmsMarketingAllowed: boolean;
  isEmailMarketingAllowed: boolean;
}

const PrivacyConsent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { featureState } = useAppSelector(
    (state) => state.settings.privacyConsent
  );

  const { user } = useAppSelector((state) => state.auth);

  const {
    countryCodeSettingsEmailMarketingDescriptionMap,
    countryCodeSettingsEmailMarketingTitleMap,
    countryCodeSettingsPushMarketingDescriptionMap,
    countryCodeSettingsPushMarketingTitleMap,
    countryCodeSettingsSMSMarketingDescriptionMap,
    countryCodeSettingsSMSMarketingTitleMap,
  } = useLanguageCodeCountryCodeLogics();

  const breadcrumbsItems: BreadcrumbsItem[] = [
    {
      label: t("settings.home.title"),
      link: "/settings",
    },
  ];

  const { trackAction } = useEventLogger("SetPrivacySettings");

  const handleSubmit: (
    values: PrivacyConsentFormValues,
    formikHelpers: FormikHelpers<PrivacyConsentFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    trackAction({});

    await dispatch(privacyConsentActions.setPrivacyConsent(values));
  };

  useEffect(() => {
    return () => {
      dispatch(privacyConsentActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (featureState === FeatureState.Success) {
      history.push("/settings");
    }
  }, [featureState, history]);

  return (
    <div>
      <Content>
        <Breadcrumbs items={breadcrumbsItems} />
        <Formik<PrivacyConsentFormValues>
          initialValues={{
            isPushMarketingAllowed:
              user?.preferences?.isPushMarketingAllowed || false,
            isSmsMarketingAllowed:
              user?.preferences?.isSmsMarketingAllowed || false,
            isEmailMarketingAllowed:
              user?.preferences?.isEmailMarketingAllowed || false,
          }}
          validationSchema={Yup.object({
            isPushMarketingAllowed: Yup.bool().oneOf([true, false]),
            isSmsMarketingAllowed: Yup.bool().oneOf([true, false]),
            isEmailMarketingAllowed: Yup.bool().oneOf([true, false]),
          })}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formikProps) => (
            <Card
              footer={
                <CardFooter<PrivacyConsentFormValues>
                  formikProps={formikProps}
                  cancelButtonText={t(
                    "settings.privacyConsentPage.cancelButtonText"
                  )}
                  nextButtonText={t(
                    "settings.privacyConsentPage.saveButtonText"
                  )}
                  handleCardCancelClick={() => history.push("/settings")}
                />
              }
            >
              <div className="md:w-80 mx-auto">
                <H3>{t("settings.privacyConsentPage.title")}</H3>

                <Form noValidate>
                  <div className="flex items-start">
                    <MaterialIcon
                      path={mdiCellphoneMessage}
                      className="h-5 flex-shrink-0 mt-2px"
                    />
                    <div className="ml-5 mr-4">
                      <H6>
                        {
                          countryCodeSettingsPushMarketingTitleMap[
                            user?.countryCode || CountryCode.Invalid
                          ]
                        }
                      </H6>

                      <p className="mb-4 mt-3">
                        {
                          countryCodeSettingsPushMarketingDescriptionMap[
                            user?.countryCode || CountryCode.Invalid
                          ]
                        }
                      </p>
                    </div>
                    <Toggle required name="isPushMarketingAllowed" />
                  </div>
                  <div className="flex items-start">
                    <MaterialIcon
                      path={mdiMessageReplyText}
                      className="h-5 flex-shrink-0 mt-2px"
                    />
                    <div className="ml-5 mr-4">
                      <H6>
                        {
                          countryCodeSettingsSMSMarketingTitleMap[
                            user?.countryCode || CountryCode.Invalid
                          ]
                        }
                      </H6>

                      <p className="mb-4 mt-3">
                        {
                          countryCodeSettingsSMSMarketingDescriptionMap[
                            user?.countryCode || CountryCode.Invalid
                          ]
                        }
                      </p>
                    </div>
                    <Toggle required name="isSmsMarketingAllowed" />
                  </div>
                  <div className="flex items-start">
                    <MaterialIcon
                      path={mdiEmailOutline}
                      className="h-5 flex-shrink-0 mt-2px"
                    />
                    <div className="ml-5 mr-4">
                      <H6>
                        {
                          countryCodeSettingsEmailMarketingTitleMap[
                            user?.countryCode || CountryCode.Invalid
                          ]
                        }
                      </H6>

                      <p className="mb-4 mt-3">
                        {
                          countryCodeSettingsEmailMarketingDescriptionMap[
                            user?.countryCode || CountryCode.Invalid
                          ]
                        }
                      </p>
                    </div>
                    <Toggle required name="isEmailMarketingAllowed" />
                  </div>
                </Form>
              </div>
            </Card>
          )}
        </Formik>
      </Content>
    </div>
  );
};

export default PrivacyConsent;
