import {
  CountryCode,
  DebtDetailsDto,
  StartPaymentResultDto,
} from "api/generated/finastic";
import { H3 } from "components/ui/Headings/Headings";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import Skeleton from "components/ui/Skeleton/Skeleton";
import useDateFormatter, { DateFormat } from "hooks/useDateFormatter";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { translateBooleanValue } from "utils/common";
import { mdiArrowUpBold } from "@mdi/js";
import Content from "components/layout/Content";
import DebtCardBg from "features/debts/components/DebtCardBg";
import DisplayData from "features/debts/DebtsList/components/DisplayData";
import DisplayBigSum from "features/debts/components/DisplayBigSum";
import DebtPaymentOptions, {
  DebtPaymentFormValues,
} from "./DebtPaymentOptions";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Alert from "components/ui/Alert/Alert";
import DisplayNumberFormat from "components/ui/DisplayNumberFormat/DisplayNumberFormat";
import useLanguageCodeCountryCodeLogics from "hooks/useLanguageCodeCountryCodeLogics";

interface DebtPaymentCardProps extends DebtDetailsDto {
  isLoading?: boolean;
  handleSubmit: (
    values: DebtPaymentFormValues,
    formikHelpers: FormikHelpers<DebtPaymentFormValues>
  ) => void | Promise<any>;
  initiatePaymentResult?: StartPaymentResultDto;
}

const DebtPaymentCard: React.FC<DebtPaymentCardProps> = ({
  isLoading,
  handleSubmit,
  initiatePaymentResult,
  ...debtDetailsProps
}) => {
  const {
    creditorName,
    hasInstalmentPlan,
    outstandingBalance,
    currencyCode,
    nextPaymentAmount,
    nextInstalment,
    debtId,
    isPaymentInProgress,
    countryCode,
  } = debtDetailsProps;
  const { t, i18n } = useTranslation();
  const { formatter } = useDateFormatter();
  const [redirecting, setRedirecting] = useState(false);

  const nextPaymentDiffersFromNextInstalment = useMemo(
    () => nextInstalment?.amount !== nextPaymentAmount,
    [nextInstalment?.amount, nextPaymentAmount]
  );

  const { countryCodeAccountIdentifierLabelMap } =
    useLanguageCodeCountryCodeLogics();

  useEffect(() => {
    if (initiatePaymentResult?.redirectUrl) {
      setRedirecting(true);

      window.location.href = initiatePaymentResult.redirectUrl;
    }
  }, [initiatePaymentResult?.redirectUrl]);

  return (
    <Content>
      <div className="rounded-xl shadow">
        <div className="bg-debtCard rounded-t-xl relative md:pr-8 md:pt-8 md:pb-4 md:pl-12 p-4 overflow-hidden">
          <DebtCardBg />
          <div className="relative">
            {isLoading ? (
              <div className="p-4">
                <Skeleton />
              </div>
            ) : (
              <>
                <div className="divide-y divide-main">
                  <div className="text-white">
                    <H3>{t("debt.repayment.title")}</H3>
                  </div>
                  <div className="space-y-4 py-4">
                    <div className="grid gap-4 md:grid-cols-3 grid-cols-1">
                      <div className="col-span-2">
                        <DisplayData label={t("debt.creditor")}>
                          {creditorName}
                        </DisplayData>
                      </div>
                      <DisplayData
                        label={
                          countryCodeAccountIdentifierLabelMap[
                            countryCode || CountryCode.Invalid
                          ]
                        }
                      >
                        {debtId}
                      </DisplayData>
                    </div>

                    <div className="grid gap-4 md:grid-cols-3 grid-cols-1">
                      {hasInstalmentPlan && (
                        <DisplayData
                          label={t("debt.paymentOutstandingBalance")}
                        >
                          <DisplayNumberFormat value={outstandingBalance} />
                          <span className="inline-block ml-2">
                            {currencyCode}
                          </span>
                        </DisplayData>
                      )}
                      {hasInstalmentPlan && nextInstalment?.dueDate && (
                        <DisplayData label={t("debt.nextPaymentDate")}>
                          <span>
                            {formatter(nextInstalment.dueDate, DateFormat.Date)}
                          </span>
                        </DisplayData>
                      )}
                      <DisplayData label={t("debt.instalmentPlan")}>
                        {translateBooleanValue(hasInstalmentPlan)}
                      </DisplayData>
                      {nextPaymentDiffersFromNextInstalment &&
                        hasInstalmentPlan && (
                          <DisplayData label={t("debt.nextInstalment")}>
                            <DisplayNumberFormat
                              value={nextInstalment?.amount}
                            />
                            <span className="inline-block ml-2">
                              {currencyCode}
                            </span>
                          </DisplayData>
                        )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center flex-wrap relative pt-4">
                    {!hasInstalmentPlan ? (
                      <div className="mb-3 md:mb-0">
                        <DisplayData
                          label={t("debt.paymentOutstandingBalance")}
                        >
                          {outstandingBalance && (
                            <DisplayBigSum
                              value={outstandingBalance}
                              currency={currencyCode}
                            />
                          )}
                        </DisplayData>
                      </div>
                    ) : nextPaymentDiffersFromNextInstalment ? (
                      <div className="mb-3 md:mb-0">
                        <DisplayData label={t("debt.nextPayment")}>
                          {typeof nextPaymentAmount === "number" && (
                            <DisplayBigSum
                              value={nextPaymentAmount}
                              currency={currencyCode}
                            />
                          )}
                        </DisplayData>
                      </div>
                    ) : (
                      <div className="mb-3 md:mb-0">
                        <DisplayData label={t("debt.nextInstalment")}>
                          {typeof nextInstalment?.amount === "number" && (
                            <DisplayBigSum
                              value={nextInstalment.amount}
                              currency={currencyCode}
                            />
                          )}
                        </DisplayData>
                      </div>
                    )}
                    <MaterialIcon
                      path={mdiArrowUpBold}
                      className="h-5 text-white absolute right-full bottom-1 mb-px mr-3 hidden md:block"
                    />
                  </div>
                </div>
                {isPaymentInProgress && (
                  <div className="mt-4">
                    <Alert type="infoAlt" size="small">
                      {t("debt.processingPayment")}
                    </Alert>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {!isLoading && (
          <Formik<DebtPaymentFormValues>
            initialValues={{
              paymentSchedule: undefined,
              amount: null,
              currencyCode: currencyCode,
              debtId: debtId,
              languageCode: i18n.language,
            }}
            validationSchema={Yup.object({
              amount: Yup.number()
                .nullable()
                .required(t("validationErrors.required")),
            })}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <DebtPaymentOptions
                debtDetails={debtDetailsProps}
                formikProps={formikProps}
                redirecting={redirecting}
              />
            )}
          </Formik>
        )}
      </div>
    </Content>
  );
};

export default DebtPaymentCard;
