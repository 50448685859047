import React from "react";

const Skeleton: React.FC = () => {
  return (
    <div className="animate-pulse">
      <p className="h-4 mb-4 bg-disabled rounded" />
      <p className="h-4 mb-2 bg-disabled rounded" />
      <p className="h-4 mb-2 bg-disabled rounded" />
      <p className="h-4 mb-2 w-1/2 bg-disabled rounded" />
    </div>
  );
};

export default Skeleton;
