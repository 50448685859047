import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MaintenanceState {
  scheduledMaintenanceEndTime?: string;
}

const initialState: MaintenanceState = {
  scheduledMaintenanceEndTime: undefined,
};

const maintenance = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    reset: () => initialState,
    setScheduledMaintenanceEndTime(
      state,
      { payload }: PayloadAction<string | undefined>
    ) {
      state.scheduledMaintenanceEndTime = payload;
    },
  },
});

const { reset, setScheduledMaintenanceEndTime } = maintenance.actions;

export const maintenanceReducer = maintenance.reducer;

export const maintenanceActions = {
  reset,
  setScheduledMaintenanceEndTime,
};
