import React, { useCallback } from "react";
import { useField } from "formik";
import Datepicker, { DatepickerProps } from "./Datepicker";

function DatepickerFormik<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
>({
  name = "",
  onChange = (
    date: WithRange extends false | undefined
      ? Date | null
      : [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
  ...rest
}: DatepickerProps<CustomModifierNames, WithRange>): JSX.Element {
  const [field, , helpers] = useField<Date | [Date | null, Date | null] | null>(
    name
  );
  const { setValue, setTouched } = helpers;
  const { value, onBlur } = field;

  const handleChange = useCallback(
    (
      date: WithRange extends false | undefined
        ? Date | null
        : [Date | null, Date | null],
      event?: React.SyntheticEvent<any, Event>
    ) => {
      setValue(date);

      // need setTimeout to make touched after value was set (in the next render)
      setTimeout(() => {
        setTouched(true);
      }, 0);

      onChange(date, event);
    },
    [onChange, setTouched, setValue]
  );

  const handleBlur = useCallback(() => {
    onBlur(name);
  }, [name, onBlur]);

  return (
    <Datepicker
      {...rest}
      name={name}
      onBlur={handleBlur}
      selected={value === null || value instanceof Date ? value : undefined}
      onChange={handleChange}
    />
  );
}

export default DatepickerFormik;
