/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LanguageCode = {
    Invalid: 'Invalid',
    En: 'EN',
    Ro: 'RO',
    Pl: 'PL'
} as const;
export type LanguageCode = typeof LanguageCode[keyof typeof LanguageCode];


export function LanguageCodeFromJSON(json: any): LanguageCode {
    return LanguageCodeFromJSONTyped(json, false);
}

export function LanguageCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageCode {
    return json as LanguageCode;
}

export function LanguageCodeToJSON(value?: LanguageCode | null): any {
    return value as any;
}

