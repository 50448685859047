import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { CommunicationChannel } from "api/generated/finastic";
import { AppThunk } from "app/store";
import { authSliceActions } from "features/auth/authSlice";
import { FeatureState } from "models/FeatureState";
import { PreferredCommunicationFormValues } from "./PreferredCommunication";

export interface PreferredCommunicationState {
  savingState: FeatureState;
  fetchingState: FeatureState;
  availableCommunicationChannels: CommunicationChannel[];
}

const initialState: PreferredCommunicationState = {
  savingState: FeatureState.Initial,
  fetchingState: FeatureState.Initial,
  availableCommunicationChannels: [],
};

const preferredCommunication = createSlice({
  name: "preferredCommunication",
  initialState,
  reducers: {
    reset: () => initialState,
    setSavingState(state, { payload }: PayloadAction<FeatureState>) {
      state.savingState = payload;
    },
    setFetchingState(state, { payload }: PayloadAction<FeatureState>) {
      state.fetchingState = payload;
    },
    setAvailableCommunicationChannels(
      state,
      { payload }: PayloadAction<CommunicationChannel[]>
    ) {
      state.availableCommunicationChannels = payload;
    },
  },
});

const {
  reset,
  setSavingState,
  setFetchingState,
  setAvailableCommunicationChannels,
} = preferredCommunication.actions;

export const preferredCommunicationReducer = preferredCommunication.reducer;

// Need to get actual enum list, because not every country has all CommunicationChannel values
const getAvailableCommunicationChannels =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    const state = getState();

    // 2 components uses this getAvailableCommunicationChannels action, they both display at the same time, but we need only one call.
    if (
      state.settings.preferredCommunication.fetchingState ===
      FeatureState.Loading
    ) {
      return;
    }

    dispatch(setFetchingState(FeatureState.Loading));

    return api.finastic.userPreference
      .apiFrontendV1UserPreferenceCommunicationChannelGet()
      .then((data) => {
        dispatch(setAvailableCommunicationChannels(data));
        dispatch(setFetchingState(FeatureState.Success));
      })
      .catch(() => dispatch(setFetchingState(FeatureState.Error)));
  };

const setPreferredCommunicationChannel =
  ({
    comminucationChannel,
  }: PreferredCommunicationFormValues): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch(setSavingState(FeatureState.Loading));

    return api.finastic.userPreference
      .apiFrontendV1UserPreferenceCommunicationChannelPut({
        updateCommunicationChannelCommand: {
          comminucationChannel,
        },
      })
      .then(() => {
        dispatch(setSavingState(FeatureState.Success));

        if (comminucationChannel) {
          const state = getState();

          dispatch(
            authSliceActions.setUser({
              ...state.auth.user,
              preferences: {
                ...state.auth.user?.preferences,
                communicationChannel: comminucationChannel,
              },
            })
          );
        }
      })
      .catch(() => dispatch(setSavingState(FeatureState.Error)));
  };

export const preferredCommunicationActions = {
  reset,
  setPreferredCommunicationChannel,
  getAvailableCommunicationChannels,
};
