import { LanguageCode } from "api/generated/finastic";
import { authSliceActions } from "features/auth/authSlice";
import useAuth from "features/auth/useAuth";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LocalStorageKeys, setLocalStorageValue } from "utils/storageHandlers";
import "dayjs/locale/ro";
import "dayjs/locale/en";
import "dayjs/locale/pl";
import dayjs from "dayjs";
import { setDefaultLocale } from "react-datepicker";

type LanguageConfigMap = {
  [key in LanguageCode]: string;
};

const languageCodeLanguageConfigMap: LanguageConfigMap = {
  [LanguageCode.Invalid]: "en",
  [LanguageCode.En]: "en",
  [LanguageCode.Ro]: "ro",
  [LanguageCode.Pl]: "pl",
};

interface UseLanguageSwitcher {
  changeLanguage: (lang: LanguageCode) => void;
}

const useLanguageSwitcher = (): UseLanguageSwitcher => {
  const { i18n } = useTranslation();
  const { userIsLoggedIn } = useAuth();
  const dispatch = useDispatch();

  const setDependencyLanguages = useCallback(
    (lang: LanguageCode) => {
      i18n.changeLanguage(lang);

      dayjs.locale(languageCodeLanguageConfigMap[lang]);

      setDefaultLocale(languageCodeLanguageConfigMap[lang]);
    },
    [i18n]
  );

  const changeLanguage = useCallback(
    (lang: LanguageCode): void => {
      setDependencyLanguages(lang);

      setLocalStorageValue(LocalStorageKeys.Locale, lang);

      if (userIsLoggedIn) {
        dispatch(authSliceActions.changeLanguage(lang));
      }
    },
    [dispatch, setDependencyLanguages, userIsLoggedIn]
  );

  return {
    changeLanguage,
  };
};

export default useLanguageSwitcher;
