import clsx from "clsx";
import React from "react";
import MaterialIcon from "../MaterialIcon/MaterialIcon";
import { mdiCheckBold, mdiAlert, mdiClockTimeThreeOutline } from "@mdi/js";

interface AlertProps {
  children: React.ReactNode;
  type?: "success" | "error" | "info" | "warning" | "errorAlt" | "infoAlt";
  size?: "default" | "small";
  icon?: string;
}

const icons = {
  success: mdiCheckBold,
  error: mdiAlert,
  info: mdiAlert,
  warning: mdiAlert,
  errorAlt: mdiAlert,
  infoAlt: mdiClockTimeThreeOutline,
};

const Alert: React.FC<AlertProps> = ({
  children,
  type = "info",
  size = "default",
  icon,
}) => {
  return (
    <div
      className={clsx("border rounded-xl", {
        "p-4": size === "default",
        "py-1 px-2": size === "small",
        "bg-white": type === "errorAlt" || type === "infoAlt",
        "border-success": type === "success",
        "border-error": type === "error",
        "border-info": type === "info",
        "border-white": type === "infoAlt" || type === "errorAlt",
        "border-warning": type === "warning",
        "text-error": type === "errorAlt",
        "text-info": type === "infoAlt",
        "text-md": type === "errorAlt",
      })}
    >
      <div
        className={clsx(
          "flex",
          `${size === "small" ? "items-center" : "items-baseline"}`
        )}
      >
        <div className="mr-4">
          <MaterialIcon
            className={clsx(
              "relative",
              {
                "text-success": type === "success",
                "text-error": type === "error",
                "text-info": type === "info",
                "text-warning": type === "warning",
              },
              `${size === "small" ? "h-5 top-0" : "h-4 top-2px"}`
            )}
            path={icon || icons[type]}
          />
        </div>
        <div
          className={clsx({
            "font-bold": type === "infoAlt" || type === "errorAlt",
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Alert;
