import { H6 } from "components/ui/Headings/Headings";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  mdiSortVariant,
  mdiSortCalendarAscending,
  mdiSortCalendarDescending,
  mdiSortNumericAscending,
  mdiSortNumericDescending,
} from "@mdi/js";
import { Menu } from "@headlessui/react";
import { getEnumValues, translateEnumValue } from "utils/common";
import clsx from "clsx";

export enum DebtsOrderBy {
  PaymentDateAsc = "PaymentDateAsc",
  PaymentDateDesc = "PaymentDateDesc",
  OutstandingBalanceAsc = "OutstandingBalanceAsc",
  OutstandingBalanceDesc = "OutstandingBalanceDesc",
}

const debtsOrderByIcons = {
  [DebtsOrderBy.PaymentDateAsc]: mdiSortCalendarAscending,
  [DebtsOrderBy.PaymentDateDesc]: mdiSortCalendarDescending,
  [DebtsOrderBy.OutstandingBalanceAsc]: mdiSortNumericAscending,
  [DebtsOrderBy.OutstandingBalanceDesc]: mdiSortNumericDescending,
};

interface MyDebtsOrderByProps {
  selectedValue: DebtsOrderBy;
  handleValueChange: (value: DebtsOrderBy) => void;
}

const MyDebtsOrderBy: React.FC<MyDebtsOrderByProps> = ({
  selectedValue,
  handleValueChange,
}) => {
  const { t } = useTranslation();

  return (
    <Menu>
      {({ open }) => (
        <div
          className={clsx(
            "relative h-8 w-8 ml-8 flex items-center justify-center rounded-full mb-8",
            {
              "bg-primaryDark": open,
            }
          )}
        >
          <Menu.Button
            className={`${open ? "text-white" : "text-primary"} outline-none`}
          >
            <MaterialIcon className="h-5" path={mdiSortVariant} />
          </Menu.Button>

          <Menu.Items
            className={clsx(
              "absolute mt-2 top-full -right-2 shadow bg-white",
              "border border-main rounded-xl dropdown-menu z-10 outline-none"
            )}
          >
            <div className="px-4 pt-4">
              <H6>{t("debts.orderBy")}</H6>
            </div>
            <div className="divide-y divide-main">
              {getEnumValues(DebtsOrderBy).map((value) => (
                <Menu.Item key={value} onClick={() => handleValueChange(value)}>
                  {({ active }) => (
                    <div className="p-4 flex cursor-pointer whitespace-nowrap items-center">
                      <MaterialIcon
                        className={clsx(
                          "h-5 w-5 mr-4",
                          `${
                            selectedValue === value
                              ? "text-primary"
                              : "text-dropdownItemIconNotActive"
                          }`
                        )}
                        path={debtsOrderByIcons[value]}
                      />
                      {translateEnumValue(value, "DebtsOrderBy")}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </div>
      )}
    </Menu>
  );
};

export default MyDebtsOrderBy;
