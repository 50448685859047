import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import { useField } from "formik";
import React, { Fragment, useMemo } from "react";
import FormGroup from "./FormGroup";
import FormGroupError from "./FormGroupError";
import FormGroupLabel from "./FormGroupLabel";
import { mdiChevronDown } from "@mdi/js";
import { GroupOption } from "models/GroupOption";

interface SelectFormGroupProps<T> {
  options: GroupOption<T>[];
  label?: string;
  required?: boolean;
  name?: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
  placeholder?: string;
}

function SelectFormGroup<T = string>({
  options,
  label,
  required,
  name,
  onChange,
  disabled,
  placeholder,
}: SelectFormGroupProps<T>): JSX.Element {
  const [field, meta, helpers] = useField(name || "");

  const { value } = field;
  const { setValue, setTouched } = helpers;

  const hasError = useMemo(
    () => !!meta.touched && !!meta.error,
    [meta.error, meta.touched]
  );

  const handleOnChange = (data: any) => {
    setValue(data);
    onChange?.(data);

    // need setTimeout to make touched after value was set (in the next render)
    setTimeout(() => {
      setTouched(true);
    }, 0);
  };

  const selectedValue = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );

  return (
    <FormGroup>
      {label && (
        <FormGroupLabel required={required} hasError={hasError}>
          {label}
        </FormGroupLabel>
      )}
      <Listbox
        value={selectedValue}
        onChange={handleOnChange}
        as="div"
        className="relative"
        disabled={disabled}
      >
        <Listbox.Button
          className={clsx(
            "relative shadow-formControl rounded-3xl placeholder-main w-full leading-none",
            "pt-11px pb-9px px-4 h-input disabled:border-formControlDisabled disabled:shadow-none border",
            `${hasError ? "border-error" : "border-formControl"}`,
            `${disabled ? "cursor-default" : "cursor-pointer"}`
          )}
        >
          <span className="block truncate text-left">
            {selectedValue?.label}
          </span>
          {!selectedValue && placeholder && (
            <span className="text-left block truncate">{placeholder}</span>
          )}
          {!disabled && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <MaterialIcon path={mdiChevronDown} className="h-4">
                expand_more
              </MaterialIcon>
            </span>
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={clsx(
              "absolute w-full py-1 mt-1 overflow-auto text-base bg-white",
              "rounded-md shadow max-h-60 border-formControl"
            )}
          >
            {options.map((option) => (
              <Listbox.Option
                key={option.value + ""}
                value={option.value}
                as={Fragment}
              >
                {({ active, selected }) => (
                  <li
                    className={clsx(
                      "cursor-pointer select-none relative py-2 px-4",
                      {
                        "bg-primary bg-opacity-10":
                          selectedValue?.value === option.value,
                        "bg-darkerCard":
                          active && selectedValue?.value !== option.value,
                      }
                    )}
                  >
                    {option.label}
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
      {hasError ? (
        <FormGroupError fieldName={name}>{meta.error}</FormGroupError>
      ) : null}
    </FormGroup>
  );
}

export default SelectFormGroup;
