import React from "react";

interface StepNavigationProps {
  firstButton?: React.ReactNode;
  secondButton?: React.ReactNode;
}

const StepNavigation: React.FC<StepNavigationProps> = ({
  firstButton,
  secondButton,
}) => {
  return (
    <div className="py-4 md:px-8 px-4 border-t border-main">
      <div className="flex justify-between -my-2 sm:flex-row flex-col items-start">
        <div className="my-2 min-w-cardButton">{firstButton}</div>
        <div className="my-2 min-w-cardButton">{secondButton}</div>
      </div>
    </div>
  );
};

export default StepNavigation;
