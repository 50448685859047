import { SubRouteLayout } from "components/layout/SubRouteLayout";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { PublicRoute } from "router/PublicRoute";
import { RouteItem, RouteItemTypes } from "router/useRoutes";
import EmailConfirmation from "./EmailConfirmation/EmailConfirmation";
import OneTimePasscode from "./OneTimePasscode/OneTimePasscode";
import ProfileData from "./ProfileData/ProfileData";
import SetPassword from "./SetPassword/SetPassword";

export const registrationParentPath = "registration";

export const registrationPaths = [
  "/profile-data",
  "/one-time-passcode",
  "/set-password",
  "/email-confirmation",
];

const Registration: React.FC = () => {
  const { path: currentPath } = useRouteMatch();
  const { t } = useTranslation();

  const registrationRoutes = useMemo<RouteItem[]>(
    () => [
      {
        path: registrationPaths[0],
        title: t("pageTitle.registration.profileData"),
        component: ProfileData,
        type: RouteItemTypes.Public,
        restricted: true,
      },
      {
        path: registrationPaths[1],
        title: t("pageTitle.registration.oneTimePasscode"),
        component: OneTimePasscode,
        type: RouteItemTypes.Public,
        restricted: true,
      },
      {
        path: registrationPaths[2],
        title: t("pageTitle.registration.setPassword"),
        component: SetPassword,
        type: RouteItemTypes.Public,
        restricted: true,
      },
      {
        path: registrationPaths[3],
        title: t("pageTitle.registration.emailConfirmation"),
        component: EmailConfirmation,
        type: RouteItemTypes.Public,
        restricted: false,
      },
    ],
    [t]
  );

  return (
    <Switch>
      {registrationRoutes.map(({ path, ...rest }) => (
        <PublicRoute
          {...rest}
          key={path}
          path={`${currentPath}${path}`}
          layout={SubRouteLayout}
        />
      ))}
      {/* Redirect user from /registration to first sub route  */}
      <Redirect to={`${currentPath}${registrationRoutes[0].path}`} />
    </Switch>
  );
};

export default Registration;
