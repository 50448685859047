/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstalmentDto
 */
export interface InstalmentDto {
    /**
     * 
     * @type {Date}
     * @memberof InstalmentDto
     */
    dueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof InstalmentDto
     */
    amount?: number;
}

export function InstalmentDtoFromJSON(json: any): InstalmentDto {
    return InstalmentDtoFromJSONTyped(json, false);
}

export function InstalmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstalmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dueDate': !exists(json, 'dueDate') ? undefined : (new Date(json['dueDate'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function InstalmentDtoToJSON(value?: InstalmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dueDate': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'amount': value.amount,
    };
}

