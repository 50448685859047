import { useField } from "formik";
import { GroupOption } from "models/GroupOption";
import React, { InputHTMLAttributes, useMemo } from "react";
import FormGroup from "./FormGroup";
import FormGroupError from "./FormGroupError";
import FormGroupLabel from "./FormGroupLabel";
import Radio from "./Radio";

interface RadioGroupProps<T> extends InputHTMLAttributes<HTMLInputElement> {
  options: GroupOption<T>[];
  label?: string;
}

function RadioGroup<T = string>({
  label,
  required,
  options,
  name,
  ...rest
}: RadioGroupProps<T>): JSX.Element {
  const [field, meta, helpers] = useField(name || "");

  const { setValue, setTouched } = helpers;

  const hasError = useMemo(
    () => !!meta.touched && !!meta.error,
    [meta.error, meta.touched]
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    // need setTimeout to make touched after value was set (in the next render)
    setTimeout(() => {
      setTouched(true);
    }, 0);
  };

  return (
    <FormGroup>
      {label && (
        <FormGroupLabel required={required} hasError={hasError}>
          {label}
        </FormGroupLabel>
      )}
      {options.map((option) => (
        <Radio
          {...field}
          {...rest}
          value={option.value + ""}
          key={option.value + ""}
          onChange={handleOnChange}
        >
          {option.label}
        </Radio>
      ))}
      {hasError ? (
        <FormGroupError fieldName={name}>{meta.error}</FormGroupError>
      ) : null}
    </FormGroup>
  );
}

export default RadioGroup;
