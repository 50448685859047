import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { AIReactCustomEvent } from "@microsoft/applicationinsights-react-js/types/useTrackEvent";

interface UseEventLogger {
  trackAction: AIReactCustomEvent<any>;
}

export type EventName =
  | "DebtDetailsTabChanged"
  | "SetPreferredCommunicationChannel"
  | "LoggedIn"
  | "OuterLinkClicked"
  | "ResetPassword"
  | "GetForgotPassword"
  | "ForgotPasswordSubmitOTP"
  | "ForgotPasswordSubmitNewPassword"
  | "RegistrationSubmitOTP"
  | "RegistrationSubmitProfileData"
  | "RegistrationSetPassword"
  | "SettingsSubmitChangePassword"
  | "SetPrivacySettings"
  | "AccountDeletionOpened"
  | "AccountDeletionConfirmed"
  | "AccountDeletionSuccessful"
  | "AccountDeletionFailed"
  | "InitiatePayment";

const useEventLogger = (
  eventName: EventName,
  eventData: { [key: string]: any } = {},
  skipFirstRun = true
): UseEventLogger => {
  const appInsightsReactPlugin = useAppInsightsContext();

  const trackAction = useTrackEvent(
    appInsightsReactPlugin,
    eventName,
    eventData,
    skipFirstRun
  );

  return {
    trackAction,
  };
};

export default useEventLogger;
