import { mdiDelete } from "@mdi/js";
import { useAppDispatch, useAppSelector } from "app/store";
import Button from "components/ui/Button/Button";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import Modal from "components/ui/Modal/Modal";
import { FeatureState } from "models/FeatureState";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { settingsHomeActions } from "../settingsHome.slice";
import { authSliceActions } from "features/auth/authSlice";
import useEventLogger from "hooks/useEventLogger";

const DeleteAccountButton: React.FC = () => {
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { trackAction: trackAccountDeletionOpened } = useEventLogger(
    "AccountDeletionOpened"
  );
  const { trackAction: trackAccountDeletionConfirmed } = useEventLogger(
    "AccountDeletionConfirmed"
  );
  const { trackAction: trackAccountDeletionSuccessful } = useEventLogger(
    "AccountDeletionSuccessful"
  );
  const { trackAction: trackAccountDeletionFailed } = useEventLogger(
    "AccountDeletionFailed"
  );

  const { featureState } = useAppSelector(
    (state) => state.settings.settingsHome
  );
  const dispatch = useAppDispatch();

  const handleDeleteButtonClick = useCallback(() => {
    trackAccountDeletionOpened({});

    setIsConfirmModalOpen(true);
  }, [trackAccountDeletionOpened]);

  const handleConfirmModalOk = useCallback(async () => {
    trackAccountDeletionConfirmed({});

    try {
      await dispatch(settingsHomeActions.deleteUser());

      trackAccountDeletionSuccessful({});

      setIsSuccessModalOpen(true);
    } catch (error) {
      setIsErrorModalOpen(true);

      trackAccountDeletionFailed({});
    } finally {
      setIsConfirmModalOpen(false);
    }
  }, [
    dispatch,
    trackAccountDeletionConfirmed,
    trackAccountDeletionSuccessful,
    trackAccountDeletionFailed,
  ]);

  const handleConfirmModalCancel = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, []);

  const handleSuccessModalOk = useCallback(() => {
    dispatch(authSliceActions.logOut());
  }, [dispatch]);

  const handleErrorModalOk = useCallback(() => {
    setIsErrorModalOpen(false);
  }, []);

  return (
    <>
      <Button
        variant="outlineError"
        iconButton
        onClick={() => handleDeleteButtonClick()}
      >
        <MaterialIcon path={mdiDelete} className="h-4" />
      </Button>

      <Modal
        isOpen={isConfirmModalOpen}
        okText={t("settings.confirmModal.okButtonText")}
        cancelText={t("settings.confirmModal.cancelButtonText")}
        okButtonProps={{
          variant: "error",
          isLoading: featureState === FeatureState.Loading,
        }}
        cancelButtonProps={{
          disabled: featureState === FeatureState.Loading,
        }}
        title={t("settings.confirmModal.title")}
        description={t("settings.confirmModal.description")}
        type="alert"
        alertType="error"
        size="large"
        showCancelButton
        onOk={() => handleConfirmModalOk()}
        onCancel={() => handleConfirmModalCancel()}
      />

      <Modal
        isOpen={isSuccessModalOpen}
        okText={t("common.ok")}
        title={t("settings.successModal.title")}
        description={t("settings.successModal.description")}
        type="alert"
        alertType="success"
        onOk={() => handleSuccessModalOk()}
      />

      <Modal
        isOpen={isErrorModalOpen}
        okText={t("settings.errorModal.close")}
        title={t("settings.errorModal.title")}
        description={t("settings.errorModal.description")}
        type="alert"
        alertType="error"
        onOk={() => handleErrorModalOk()}
      />
    </>
  );
};

export default DeleteAccountButton;
