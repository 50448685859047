import { CommunicationChannel } from "api/generated/finastic";
import Content from "components/layout/Content";
import Breadcrumbs, {
  BreadcrumbsItem,
} from "components/ui/Breadcrumbs/Breadcrumbs";
import Card from "components/ui/Card/Card";
import CardFooter from "./components/CardFooter";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { preferredCommunicationActions } from "./preferredCommunication.slice";
import { H3 } from "components/ui/Headings/Headings";
import RadioGroup from "components/forms/RadioGroup";
import useCommunicationChannelsRadios from "hooks/useCommunicationChannelsRadios";
import Modal from "components/ui/Modal/Modal";
import { FeatureState } from "models/FeatureState";
import { useAppSelector } from "app/store";
import ContentLoadingSpinner from "components/ui/LoadingSpinner/ContentLoadingSpinner";
import useEventLogger from "hooks/useEventLogger";

export interface PreferredCommunicationFormValues {
  comminucationChannel?: CommunicationChannel;
}
const PreferredCommunication: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { optionsWithIcons, optionsState } = useCommunicationChannelsRadios();

  const { savingState } = useAppSelector(
    (state) => state.settings.preferredCommunication
  );

  const { user } = useAppSelector((state) => state.auth);

  const { trackAction: trackChannelSelect } = useEventLogger(
    "SetPreferredCommunicationChannel",
    { channel: "" }
  );

  const breadcrumbsItems: BreadcrumbsItem[] = [
    {
      label: t("settings.home.title"),
      link: "/settings",
    },
  ];

  const handleSubmit: (
    values: PreferredCommunicationFormValues,
    formikHelpers: FormikHelpers<PreferredCommunicationFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    if (values.comminucationChannel) {
      trackChannelSelect({ channel: values.comminucationChannel });

      await dispatch(
        preferredCommunicationActions.setPreferredCommunicationChannel(values)
      );
    }
  };

  const feedbackModalIsOpen = useMemo(
    () => savingState === FeatureState.Success,
    [savingState]
  );

  useEffect(() => {
    return () => {
      dispatch(preferredCommunicationActions.reset());
    };
  }, [dispatch]);

  return (
    <div>
      <Content>
        <Breadcrumbs items={breadcrumbsItems} />
        <Formik<PreferredCommunicationFormValues>
          initialValues={{
            comminucationChannel: user?.preferences?.communicationChannel,
          }}
          validationSchema={Yup.object({
            comminucationChannel: Yup.string()
              .required(t("validationErrors.required"))
              .nullable(),
          })}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formikProps) => (
            <Card
              footer={
                <CardFooter<PreferredCommunicationFormValues>
                  formikProps={formikProps}
                  cancelButtonText={t(
                    "settings.preferredCommunicatonChannel.cancelButtonText"
                  )}
                  nextButtonText={t(
                    "settings.preferredCommunicatonChannel.saveButtonText"
                  )}
                  handleCardCancelClick={() => history.push("/settings")}
                />
              }
            >
              <div className="md:w-80 mx-auto">
                <div className="mb-8">
                  <H3>{t("settings.preferredCommunicatonChannel.title")}</H3>
                </div>

                {optionsState === FeatureState.Success && (
                  <Form noValidate>
                    <RadioGroup
                      name="comminucationChannel"
                      options={optionsWithIcons}
                      required
                    />
                  </Form>
                )}

                {optionsState === FeatureState.Loading && (
                  <ContentLoadingSpinner />
                )}
              </div>
            </Card>
          )}
        </Formik>
      </Content>
      <Modal
        isOpen={feedbackModalIsOpen}
        title={t("settings.preferredCommunicatonChannel.successModal.title")}
        okText={t(
          "settings.preferredCommunicatonChannel.successModal.backToProfileButtonText"
        )}
        type="alert"
        alertType="success"
        onOk={() => history.push("/settings")}
      />
    </div>
  );
};

export default PreferredCommunication;
