import React, { useMemo } from "react";
import { useField } from "formik";
import FormGroup from "./FormGroup";
import FormGroupLabel from "./FormGroupLabel";
import FormGroupError from "./FormGroupError";
import InputNumber from "./InputNumber";
import { NumberFormatProps, NumberFormatValues } from "react-number-format";

interface InputNumberFormGroupProps extends NumberFormatProps {
  label?: string;
  textAlignRight?: boolean;
  valueAsString?: boolean;
}

const InputNumberFormGroup: React.FC<InputNumberFormGroupProps> = ({
  name,
  label,
  required,
  hidden,
  valueAsString,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name || "");

  const fieldProps = useMemo(
    () => ({
      ...field,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange: () => {},
    }),
    [field]
  );

  const { setValue, setTouched } = helpers;

  const handleValueChange = (values: NumberFormatValues) => {
    const { max, min } = rest;
    if (
      typeof values.floatValue === "number" &&
      typeof max === "number" &&
      values.floatValue > max
    ) {
      setValue(valueAsString ? max + "" : max);
    } else if (
      typeof values.floatValue === "number" &&
      typeof min === "number" &&
      values.floatValue < min
    ) {
      setValue(valueAsString ? min + "" : min);
    } else {
      setValue(valueAsString ? values.value : values.floatValue);
    }

    // need setTimeout to make touched after value was set (in the next render)
    setTimeout(() => {
      setTouched(true);
    }, 0);
  };

  const hasError = useMemo(
    () => !!meta.touched && !!meta.error,
    [meta.error, meta.touched]
  );

  return (
    <FormGroup>
      {label && (
        <FormGroupLabel required={required} hasError={hasError}>
          {label}
        </FormGroupLabel>
      )}
      <InputNumber
        {...rest}
        field={fieldProps}
        required={required}
        name={name}
        hidden={hidden}
        onValueChange={handleValueChange}
      />
      {hasError ? (
        <FormGroupError fieldName={name}>{meta.error}</FormGroupError>
      ) : null}
    </FormGroup>
  );
};

export default InputNumberFormGroup;
