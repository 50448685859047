/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LinkPaymentDebtDto,
    LinkPaymentDebtDtoFromJSON,
    LinkPaymentDebtDtoToJSON,
    LinkPaymentResolutionDto,
    LinkPaymentResolutionDtoFromJSON,
    LinkPaymentResolutionDtoToJSON,
    LinkPaymentStatusDto,
    LinkPaymentStatusDtoFromJSON,
    LinkPaymentStatusDtoToJSON,
    StartLinkPaymentCommand,
    StartLinkPaymentCommandFromJSON,
    StartLinkPaymentCommandToJSON,
    StartPaymentResultDto,
    StartPaymentResultDtoFromJSON,
    StartPaymentResultDtoToJSON,
} from '../models';

export interface ApiFrontendV1LinkPaymentDebtGetRequest {
    debtId: string;
    backendCode?: string | null;
}

export interface ApiFrontendV1LinkPaymentPaymentIdGetRequest {
    paymentId: string;
}

export interface ApiFrontendV1LinkPaymentPostRequest {
    startLinkPaymentCommand?: StartLinkPaymentCommand;
}

export interface ApiFrontendV1LinkPaymentResolveGetRequest {
    paymentUuid: string;
    backendCode?: string | null;
}

/**
 * 
 */
export class LinkPaymentApi extends runtime.BaseAPI {

    /**
     */
    async apiFrontendV1LinkPaymentDebtGetRaw(requestParameters: ApiFrontendV1LinkPaymentDebtGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<LinkPaymentDebtDto>> {
        if (requestParameters.debtId === null || requestParameters.debtId === undefined) {
            throw new runtime.RequiredError('debtId','Required parameter requestParameters.debtId was null or undefined when calling apiFrontendV1LinkPaymentDebtGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.backendCode !== undefined) {
            queryParameters['backendCode'] = requestParameters.backendCode;
        }

        if (requestParameters.debtId !== undefined) {
            queryParameters['debtId'] = requestParameters.debtId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/LinkPayment/Debt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkPaymentDebtDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1LinkPaymentDebtGet(requestParameters: ApiFrontendV1LinkPaymentDebtGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LinkPaymentDebtDto> {
        const response = await this.apiFrontendV1LinkPaymentDebtGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1LinkPaymentPaymentIdGetRaw(requestParameters: ApiFrontendV1LinkPaymentPaymentIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<LinkPaymentStatusDto>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling apiFrontendV1LinkPaymentPaymentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/LinkPayment/{paymentId}`.replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkPaymentStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1LinkPaymentPaymentIdGet(requestParameters: ApiFrontendV1LinkPaymentPaymentIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LinkPaymentStatusDto> {
        const response = await this.apiFrontendV1LinkPaymentPaymentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1LinkPaymentPostRaw(requestParameters: ApiFrontendV1LinkPaymentPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<StartPaymentResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/LinkPayment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartLinkPaymentCommandToJSON(requestParameters.startLinkPaymentCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StartPaymentResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1LinkPaymentPost(requestParameters: ApiFrontendV1LinkPaymentPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<StartPaymentResultDto> {
        const response = await this.apiFrontendV1LinkPaymentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1LinkPaymentResolveGetRaw(requestParameters: ApiFrontendV1LinkPaymentResolveGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<LinkPaymentResolutionDto>> {
        if (requestParameters.paymentUuid === null || requestParameters.paymentUuid === undefined) {
            throw new runtime.RequiredError('paymentUuid','Required parameter requestParameters.paymentUuid was null or undefined when calling apiFrontendV1LinkPaymentResolveGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.backendCode !== undefined) {
            queryParameters['backendCode'] = requestParameters.backendCode;
        }

        if (requestParameters.paymentUuid !== undefined) {
            queryParameters['paymentUuid'] = requestParameters.paymentUuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/LinkPayment/Resolve`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkPaymentResolutionDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1LinkPaymentResolveGet(requestParameters: ApiFrontendV1LinkPaymentResolveGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<LinkPaymentResolutionDto> {
        const response = await this.apiFrontendV1LinkPaymentResolveGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
