import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { PublicRoute } from "router/PublicRoute";
import { RouteItem, RouteItemTypes } from "router/useRoutes";
import EmailConfirmation from "./EmalConfirmation/EmailConfirmation";
import EmailValidation from "./EmailValidation";
import { SubRouteLayout } from "components/layout/SubRouteLayout";

const EmailValidationRouteSwitch: React.FC = () => {
  const { path: currentPath } = useRouteMatch();
  const { t } = useTranslation();

  const routes = useMemo<RouteItem[]>(
    () => [
      {
        path: "",
        title: t("pageTitle.forgotPassword.emailValidation"),
        component: EmailValidation,
        type: RouteItemTypes.Public,
        restricted: true,
        exact: true,
      },
      {
        path: "/reset-password",
        title: t("pageTitle.forgotPassword.emailValidationResetPassword"),
        component: EmailConfirmation,
        type: RouteItemTypes.Public,
        restricted: false,
      },
    ],
    [t]
  );

  return (
    <Switch>
      {routes.map(({ path, ...rest }) => (
        <PublicRoute
          {...rest}
          key={path}
          path={`${currentPath}${path}`}
          layout={SubRouteLayout}
        />
      ))}
      <Redirect to={`${currentPath}${routes[0].path}`} />
    </Switch>
  );
};

export default EmailValidationRouteSwitch;
