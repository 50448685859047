import OuterLink from "components/OuterLink";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import React, { HTMLAttributeAnchorTarget } from "react";

export interface FooterMenuItem {
  icon?: string;
  href?: string;
  label: string;
  target?: HTMLAttributeAnchorTarget;
  id: string;
}

interface FooterMenuProps {
  items: FooterMenuItem[];
}

const FooterMenu: React.FC<FooterMenuProps> = ({ items }) => {
  return (
    <ul>
      {items.map(({ icon, href, label, target, id }, index) => (
        <li key={index} className="mb-3 last:mb-0 flex items-center">
          {icon && (
            <MaterialIcon
              path={icon}
              className="text-white mr-4 h-4 -top-px relative"
            />
          )}
          <OuterLink
            href={href}
            target={target}
            className="font-normal"
            id={id}
          >
            {label}
          </OuterLink>
        </li>
      ))}
    </ul>
  );
};

export default FooterMenu;
