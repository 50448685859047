import { DebtDetailsDto } from "api/generated/finastic";
import React from "react";
import {
  mdiFileDocumentOutline,
  mdiAccountBoxOutline,
  mdiCalendarArrowRight,
  mdiDownload,
  mdiCalendarRefresh,
  mdiSortCalendarDescending,
  mdiCheckBold,
  mdiBriefcaseVariantOutline,
  mdiCalendar,
  mdiBriefcaseClockOutline,
  mdiBriefcaseEditOutline,
  mdiAnimationOutline,
  mdiClipboardEditOutline,
  mdiClipboardAccountOutline,
} from "@mdi/js";
import { useTranslation } from "react-i18next";
import useDateFormatter, { DateFormat } from "hooks/useDateFormatter";
import { translateBooleanValue } from "utils/common";
import DisplayNumberFormat from "components/ui/DisplayNumberFormat/DisplayNumberFormat";
import DataLine from "components/ui/DataLine/DataLine";

const DebtContract: React.FC<DebtDetailsDto> = ({
  caseId,
  customerId,
  disbursmentDate,
  loanTermMonthCount,
  terminationDate,
  hasInstalmentPlan,
  currencyCode,
  paidAmount,
  originalDebtAmount,
  productDescription,
  countryCode,
  originalCreditorName,
  currentCreditorName,
  caseStatus,
  signature,
  entryKrd,
}) => {
  const { t } = useTranslation();
  const { formatter } = useDateFormatter();

  return (
    <div className="md:px-12 px-4">
      <div className="divide-y divide-main">
        <div className="space-y-4 mb-4">
          {countryCode === "PL" && (
            <DataLine
              iconPath={mdiBriefcaseClockOutline}
              label={t("debt.originalCreditor")}
              value={originalCreditorName}
            />
          )}

          <DataLine
            iconPath={mdiBriefcaseVariantOutline}
            label={t("debt.caseId")}
            value={caseId}
          />

          {countryCode === "RO" && (
            <DataLine
              iconPath={mdiAccountBoxOutline}
              label={t("debt.customerId")}
              value={customerId}
            />
          )}

          {countryCode === "PL" && (
            <DataLine
              iconPath={mdiBriefcaseEditOutline}
              label={t("debt.currentCreditor")}
              value={currentCreditorName}
            />
          )}
        </div>
        <div className="space-y-4 pt-4">
          {countryCode === "PL" && (
            <DataLine
              iconPath={mdiFileDocumentOutline}
              label={t("debt.productDescription")}
              value={productDescription}
            />
          )}

          {countryCode === "RO" && (
            <DataLine
              iconPath={mdiCalendar}
              label={t("debt.disbursementDate")}
              value={disbursmentDate}
            >
              {disbursmentDate && formatter(disbursmentDate, DateFormat.Date)}
            </DataLine>
          )}

          {countryCode === "RO" && (
            <DataLine
              iconPath={mdiCalendarArrowRight}
              label={t("debt.loanTerm")}
              value={loanTermMonthCount}
            >
              {t("debt.loanTermValue", { month: loanTermMonthCount })}
            </DataLine>
          )}

          {countryCode === "RO" && (
            <DataLine
              iconPath={mdiSortCalendarDescending}
              label={t("debt.terminationDate")}
              value={terminationDate}
            >
              {terminationDate && formatter(terminationDate, DateFormat.Date)}
            </DataLine>
          )}

          {countryCode === "RO" && (
            <DataLine
              iconPath={mdiCalendarRefresh}
              label={t("debt.instalment")}
              value={hasInstalmentPlan}
            >
              {translateBooleanValue(hasInstalmentPlan)}
            </DataLine>
          )}

          {countryCode === "PL" && (
            <DataLine
              iconPath={mdiAnimationOutline}
              label={t("debt.caseStatus")}
              value={caseStatus}
            />
          )}

          {countryCode === "PL" && (
            <DataLine
              iconPath={mdiClipboardEditOutline}
              label={t("debt.signature")}
              value={signature}
            />
          )}

          {countryCode === "PL" && (
            <DataLine
              iconPath={mdiClipboardAccountOutline}
              label={t("debt.entryKrd")}
              value={entryKrd}
            >
              {translateBooleanValue(entryKrd)}
            </DataLine>
          )}

          <DataLine
            iconPath={mdiDownload}
            label={t("debt.originalDebt")}
            value={originalDebtAmount}
          >
            <DisplayNumberFormat value={originalDebtAmount} />
            <span className="inline-block ml-2 ">{currencyCode}</span>
          </DataLine>
          <DataLine
            iconPath={mdiCheckBold}
            label={t("debt.totalRepaid")}
            value={paidAmount}
          >
            <DisplayNumberFormat value={paidAmount} />
            <span className="inline-block ml-2 ">{currencyCode}</span>
          </DataLine>
        </div>
      </div>
    </div>
  );
};

export default DebtContract;
