import clsx from "clsx";
import React, { ButtonHTMLAttributes } from "react";

interface PaginationButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  active?: boolean;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({
  children,
  disabled,
  active,
  ...rest
}) => {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={clsx(
        "h-8 w-8 inline-flex justify-center items-center rounded-full border",
        "font-exo2 font-semibold text-sm",
        `${
          disabled
            ? "text-disabled border-disabled pointer-events-none"
            : "text-primary border-primary"
        }`,
        { shadow: !active && !disabled },
        `${
          active
            ? "bg-primary text-white border-primary pointer-events-none"
            : "bg-white"
        }`
      )}
    >
      {children}
    </button>
  );
};

export default PaginationButton;
