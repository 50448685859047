import { useAppSelector } from "app/store";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { appVersionDtoToString, hasAppVersionMismatch } from "./utils";
import { mdiTimerSand } from "@mdi/js";

const initialRemainingTime = 10; // seconds

const AppVersionMismatch: React.FC = () => {
  const [secondsRemainingToRefresh, setSecondsRemainingToRefresh] =
    useState(initialRemainingTime);
  const { appVersion, minimumAppVersionRequiredByApi } = useAppSelector(
    (state) => state.appVersion
  );
  const history = useHistory();
  const { t } = useTranslation();

  const returnUrl =
    new URLSearchParams(window.location.search).get("returnUrl") || "/";

  useEffect(() => {
    if (secondsRemainingToRefresh === 0) {
      window.location.reload();
    }
  }, [secondsRemainingToRefresh]);

  useEffect(() => {
    if (
      minimumAppVersionRequiredByApi &&
      !hasAppVersionMismatch(appVersion, minimumAppVersionRequiredByApi)
    ) {
      history.push(returnUrl);
    }
  }, [appVersion, history, minimumAppVersionRequiredByApi, returnUrl]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (secondsRemainingToRefresh >= 1) {
      interval = setInterval(() => {
        setSecondsRemainingToRefresh((seconds) => seconds - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [secondsRemainingToRefresh]);

  return (
    <div className="h-full flex justify-center items-center flex-col">
      <MaterialIcon path={mdiTimerSand} className="text-warning h-12" />
      <h1 className="text-3xl font-exo2 mb-3 mt-5 text-center">
        {t("appVersionMismatch.title")}
      </h1>
      <div className="text-disabled mb-8">
        {t("appVersionMismatch.currentVersion")}:{" "}
        {appVersionDtoToString(appVersion)} |{" "}
        {t("appVersionMismatch.minimumVersion")}:{" "}
        {minimumAppVersionRequiredByApi &&
          appVersionDtoToString(minimumAppVersionRequiredByApi)}
      </div>

      <h2 className="text-xl font-exo2 mb-2">
        {t("appVersionMismatch.automaticRefresh")}
      </h2>
      <span className="text-4xl">{secondsRemainingToRefresh}</span>
    </div>
  );
};

export default AppVersionMismatch;
