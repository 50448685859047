import { useState } from "react";
import Container from "./Container";
import Footer from "./Footer/Footer";
import Header from "./Header";
import Main from "./Main";
import PreferredCommModal from "./PreferredCommModal";
import Sidebar from "./Sidebar/Sidebar";

interface PrivateLayoutProps {
  children: React.ReactNode;
}

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
  const [sidebarIsToggled, setSidebarIsToggled] = useState(false);

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <Header hideLogo />
        <div className="flex-1 flex overflow-hidden">
          <Sidebar
            onToggledChange={(newValue) => setSidebarIsToggled(newValue)}
          />
          <Main sidebarIsToggled={sidebarIsToggled}>
            <Container>{children}</Container>
          </Main>
        </div>
        <Footer layoutHasSidebar />
      </div>
      <PreferredCommModal />
    </>
  );
};
