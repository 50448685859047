import React from "react";
import { Link } from "react-router-dom";
import { mdiChevronLeft } from "@mdi/js";
import MaterialIcon from "../MaterialIcon/MaterialIcon";

export interface BreadcrumbsItem {
  link: string;
  label: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbsItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <ul className="pl-8 pb-2 space-x-2">
      {items.map((item) => (
        <li key={item.label} className="inline-block">
          <Link to={item.link}>
            <MaterialIcon
              path={mdiChevronLeft}
              className="h-5 inline-block relative -top-px"
            />
            <span className="inline-block ml-2">{item.label}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
