import { LanguageCode } from "api/generated/finastic";
import { appInsights } from "app/appInsights";
import { useAppSelector } from "app/store";
import Modal from "components/ui/Modal/Modal";
import { appVersionActions } from "features/appVersion/appVersionSlice";
import { hasAppVersionMismatch } from "features/appVersion/utils";
import { authSliceActions } from "features/auth/authSlice";
import useAuth from "features/auth/useAuth";
import { errorActions } from "features/error/errorSlice";
import useLanguageSwitcher from "hooks/useLanguageSwitcher";
import { FeatureState } from "models/FeatureState";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RouterView } from "router/RouterView";
import { getLocalStorageValue, LocalStorageKeys } from "utils/storageHandlers";

const App: React.FC = () => {
  const { user, userState, userIsLoggedIn } = useAuth();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    criticalErrorHappened,
    currentGlobalErrorKey,
    currentGlobalErrorHappened,
  } = useAppSelector((state) => state.error);
  const { appVersion, minimumAppVersionRequiredByApi } = useAppSelector(
    (state) => state.appVersion
  );
  const location = useLocation();
  const history = useHistory();
  const { changeLanguage } = useLanguageSwitcher();

  const { t } = useTranslation();

  // On initial load if user is logged in from previous occasion or tab refresh his user data
  useEffect(() => {
    if (userIsLoggedIn && userState === FeatureState.Initial) {
      dispatch(authSliceActions.getUser());
    }
  }, [dispatch, userIsLoggedIn, userState]);

  // If user logged in with other language than it was set in his profile, save it to BE.
  useEffect(() => {
    if (user && user?.preferences?.languageCode !== i18n.language) {
      dispatch(authSliceActions.changeLanguage(i18n.language as LanguageCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user?.preferences?.languageCode]);

  // App version handling
  useEffect(() => {
    dispatch(appVersionActions.getMinimumAppVersion());
  }, [appVersion, dispatch]);

  useEffect(() => {
    if (
      hasAppVersionMismatch(appVersion, minimumAppVersionRequiredByApi) &&
      location.pathname !== "/app-version-mismatch"
    ) {
      history.push(`/app-version-mismatch?returnUrl=${location.pathname}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersion, minimumAppVersionRequiredByApi]);

  useEffect(() => {
    const userId = getLocalStorageValue(LocalStorageKeys.UserId);

    if (userIsLoggedIn && userId) {
      appInsights.setAuthenticatedUserContext(userId, undefined, true);
    } else {
      appInsights.clearAuthenticatedUserContext();
    }
  }, [userIsLoggedIn]);

  // Set initial language for all date libs in the App based on default language set in i18n/index
  useEffect(() => {
    changeLanguage(i18n.language as LanguageCode);
  }, [i18n, changeLanguage]);

  return (
    <>
      <RouterView />
      <Modal
        isOpen={criticalErrorHappened}
        title={t("criticalErrorModal.title")}
        description={t("criticalErrorModal.description")}
        okText={t("criticalErrorModal.okButtonText")}
        type="alert"
        alertType={"error"}
        onOk={() => dispatch(errorActions.setCriticalErrorHappened(false))}
      />
      <Modal
        isOpen={currentGlobalErrorHappened && !!currentGlobalErrorKey}
        title={t(`globalErrors.${currentGlobalErrorKey}.title`)}
        description={t(`globalErrors.${currentGlobalErrorKey}.description`)}
        okText={t("common.close")}
        type="alert"
        alertType={"error"}
        onOk={() => {
          dispatch(errorActions.setCurrentGlobalErrorHappened(false));

          setTimeout(() => {
            // setTimeout to wait until Modal's transition out is finished
            dispatch(errorActions.setCurrentGlobalErrorKey(undefined));
          }, 500);
        }}
      />
    </>
  );
};

export default App;
