import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { DebtSummaryDto } from "api/generated/finastic";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";

export interface DebtsListState {
  featureState: FeatureState;
  debts: DebtSummaryDto[];
}

const initialState: DebtsListState = {
  featureState: FeatureState.Initial,
  debts: [],
};

const debtsList = createSlice({
  name: "debtsList",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
    getDebtsSuccess(state, { payload }: PayloadAction<DebtSummaryDto[]>) {
      state.debts = payload;
      state.featureState = FeatureState.Success;
    },
  },
});

const { reset, setState, getDebtsSuccess } = debtsList.actions;

export const debtsListReducer = debtsList.reducer;

const getDebts = (): AppThunk<Promise<any>> => async (dispatch, getState) => {
  dispatch(setState(FeatureState.Loading));

  return api.finastic.debt
    .apiFrontendV1DebtGet()
    .then((value) => {
      dispatch(getDebtsSuccess(value));
    })
    .catch((error) => {
      dispatch(setState(FeatureState.Error));

      throw error;
    });
};

export const debtsListActions = {
  getDebts,
  reset,
};
