import Button from "components/ui/Button/Button";
import { FormikProps } from "formik";
import CancelModal from "./CancelModal";
import useCancelModal from "./useCancelModal";

type CardFooterProps<T> = {
  formikProps: FormikProps<T>;
  cancelButtonText: string;
  nextButtonText: string;
};

function CardFooter<T>({
  formikProps,
  cancelButtonText,
  nextButtonText,
}: CardFooterProps<T>): JSX.Element {
  const {
    handleCardCancelClick,
    isCancelModalOpen,
    handleModalCancelClick,
    handleModalOkClick,
  } = useCancelModal();

  const handleNextClick = () => {
    formikProps.submitForm();
  };

  return (
    <>
      <div className="flex justify-between flex-wrap -my-2">
        <div className="my-2 min-w-cardButton">
          <Button variant="outline" onClick={handleCardCancelClick} block>
            {cancelButtonText}
          </Button>
        </div>
        <div className="my-2 min-w-cardButton">
          <Button
            disabled={!formikProps.isValid}
            isLoading={formikProps.isSubmitting}
            onClick={handleNextClick}
            block
          >
            {nextButtonText}
          </Button>
        </div>
      </div>
      <CancelModal
        isOpen={isCancelModalOpen}
        onCancel={handleModalCancelClick}
        onOk={handleModalOkClick}
      />
    </>
  );
}

export default CardFooter;
