import {
  Configuration,
  Middleware,
  AuthenticationApi,
  UserPreferenceApi,
  UserApi,
  DebtApi,
  RegistrationApi,
  PaymentApi,
  ConfigurationApi,
  LinkPaymentApi,
} from "api/generated/finastic";
import store from "app/store";
import { errorActions } from "features/error/errorSlice";
import { PaymentPlanApi } from "./generated/finastic/apis/PaymentPlanApi";
import { errorHandlingMiddleware } from "./middleware/errorHandlingMiddleware";
import { requestHeadersMiddleware } from "./middleware/requestHeadersMiddleware";

// Params: time in seconds
export const createTimeoutSignal = (time: number): AbortController => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), time * 1000);

  return controller;
};

const apiMiddleware: Middleware[] = [
  ...requestHeadersMiddleware,
  ...errorHandlingMiddleware,
];

const apiConfig: Configuration = new Configuration({
  basePath: process.env.REACT_APP_MW_API_URL,
  middleware: apiMiddleware,
  headers: {
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
    Expires: "0",
  },
  fetchApi: (input, init) =>
    fetch(input, {
      ...init,
      signal: createTimeoutSignal(30).signal, // Make request timeout after 30 seconds without answer
    }).catch((error) => {
      if (
        error?.message === "The user aborted a request." || // Timeout error
        error?.message === "Failed to fetch" // CORS error
      ) {
        store.dispatch(
          errorActions.setCurrentGlobalErrorKey("FinasticUnavailable")
        );
        store.dispatch(errorActions.setCurrentGlobalErrorHappened(true));
      }

      throw error;
    }),
});

export const api = {
  finastic: {
    authentication: new AuthenticationApi(apiConfig),
    user: new UserApi(apiConfig),
    userPreference: new UserPreferenceApi(apiConfig),
    debt: new DebtApi(apiConfig),
    registration: new RegistrationApi(apiConfig),
    payment: new PaymentApi(apiConfig),
    configuration: new ConfigurationApi(apiConfig),
    linkPayment: new LinkPaymentApi(apiConfig),
    paymentPlan: new PaymentPlanApi(apiConfig),
  },
};
