import { authSliceActions } from "features/auth/authSlice";
import { debtDetailsActions } from "features/debts/DebtDetails/debtDetails.slice";
import { debtPaymentActions } from "features/debts/DebtPayment/debtPayment.slice";
import { debtsListActions } from "features/debts/DebtsList/debtsList.slice";
import { errorActions } from "features/error/errorSlice";
import { emailConfirmationActions as forgotPwEmailConfirmationActions } from "features/forgotPassword/EmailValidation/EmalConfirmation/emailConfirmation.slice";
import { resetPasswordActions } from "features/forgotPassword/EmailValidation/EmalConfirmation/resetPassword.slice";
import { forgotPasswordHomeActions } from "features/forgotPassword/Home/forgotPasswordHome.slice";
import { smsPasscodeActions } from "features/forgotPassword/SMSPasscode/smsPasscode.slice";
import { smsResetPasswordActions } from "features/forgotPassword/SMSPasscode/SMSResetPassword/smsResetPassword.slice";
import { paymentActions } from "features/payment/payment.slice";
import { emailConfirmationActions } from "features/registration/EmailConfirmation/emailConfirmation.slice";
import { oneTimePasscodeActions } from "features/registration/OneTimePasscode/oneTimePasscode.slice";
import { profileDataActions } from "features/registration/ProfileData/profileData.slice";
import { setPasswordActions } from "features/registration/SetPassword/setPassword.slice";
import { changePasswordActions } from "features/settings/ChangePassword/changePassword.slice";
import { settingsHomeActions } from "features/settings/Home/settingsHome.slice";
import { preferredCommunicationActions } from "features/settings/PreferredCommunication/preferredCommunication.slice";
import { privacyConsentActions } from "features/settings/PrivacyConsent/privacyConsent.slice";
import { batch } from "react-redux";
import { AppThunk } from "./store";

export const resetStore = (): AppThunk => async (dispatch) => {
  batch(() => {
    dispatch(authSliceActions.reset());
    dispatch(forgotPwEmailConfirmationActions.reset());
    dispatch(resetPasswordActions.reset());
    dispatch(forgotPasswordHomeActions.reset());
    dispatch(smsPasscodeActions.reset());
    dispatch(smsResetPasswordActions.reset());
    dispatch(profileDataActions.reset());
    dispatch(oneTimePasscodeActions.reset());
    dispatch(setPasswordActions.reset());
    dispatch(emailConfirmationActions.reset());
    dispatch(errorActions.reset());
    dispatch(debtsListActions.reset());
    dispatch(debtDetailsActions.reset());
    dispatch(debtPaymentActions.reset());
    dispatch(changePasswordActions.reset());
    dispatch(preferredCommunicationActions.reset());
    dispatch(privacyConsentActions.reset());
    dispatch(paymentActions.reset());
    dispatch(settingsHomeActions.reset());
  });
};
