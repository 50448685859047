import React, { useCallback } from "react";
import { useField } from "formik";
import Slider, { SliderProps } from "./Slider";

const SliderFormik: React.FC<SliderProps> = ({
  name = "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onValueChange = (value: number[]) => {},
  ...rest
}) => {
  const [field, , helpers] = useField<number[]>(name);
  const { setValue, setTouched } = helpers;
  const { value, onBlur } = field;

  const handleValueChange = useCallback(
    (newValue: number[]) => {
      setValue(newValue);

      // need setTimeout to make touched after value was set (in the next render)
      setTimeout(() => {
        setTouched(true);
      }, 0);

      onValueChange(newValue);
    },
    [onValueChange, setTouched, setValue]
  );

  const handleBlur = useCallback(() => {
    onBlur(name);
  }, [name, onBlur]);

  return (
    <Slider
      {...rest}
      name={name}
      onBlur={handleBlur}
      value={value}
      onValueChange={handleValueChange}
    />
  );
};

export default SliderFormik;
