import { CommunicationChannel } from "api/generated/finastic";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import { useEffect, useMemo } from "react";
import { translateEnumValue } from "utils/common";
import {
  mdiCellphoneMessage,
  mdiCellphoneText,
  mdiEmailOutline,
  mdiWhatsapp,
} from "@mdi/js";
import { useDispatch } from "react-redux";
import { preferredCommunicationActions } from "features/settings/PreferredCommunication/preferredCommunication.slice";
import { useAppSelector } from "app/store";
import { FeatureState } from "models/FeatureState";

const communicationChannelIcons = {
  [CommunicationChannel.Invalid]: "",
  [CommunicationChannel.Push]: mdiCellphoneMessage,
  [CommunicationChannel.Sms]: mdiCellphoneText,
  [CommunicationChannel.Email]: mdiEmailOutline,
  [CommunicationChannel.WhatsApp]: mdiWhatsapp,
};

interface UseCommunicationChannelsRadios {
  optionsWithIcons: {
    label: JSX.Element;
    value: CommunicationChannel;
  }[];
  optionsState: FeatureState;
}

const useCommunicationChannelsRadios = (): UseCommunicationChannelsRadios => {
  const dispatch = useDispatch();
  const { fetchingState, availableCommunicationChannels } = useAppSelector(
    (state) => state.settings.preferredCommunication
  );

  const options = useMemo(
    () =>
      availableCommunicationChannels.map((channel) => ({
        label: translateEnumValue(channel, "CommunicationChannel"),
        value: channel,
      })),
    [availableCommunicationChannels]
  );

  useEffect(() => {
    dispatch(preferredCommunicationActions.getAvailableCommunicationChannels());
  }, [dispatch]);

  const optionsWithIcons = useMemo(
    () =>
      options.map(({ value, label }) => ({
        label: (
          <span className="flex items-start">
            <span className="inline-block mr-4 -top-1 relative">
              <MaterialIcon
                className="h-6 w-6"
                path={communicationChannelIcons[value]}
              />
            </span>
            {label}
          </span>
        ),
        value,
      })),
    [options]
  );

  return { optionsWithIcons, optionsState: fetchingState };
};

export default useCommunicationChannelsRadios;
