import clsx from "clsx";
import React from "react";

interface DisplayDataProps {
  label: string;
  children: React.ReactNode;
  darkText?: boolean;
}

const DisplayData: React.FC<DisplayDataProps> = ({
  label,
  children,
  darkText,
}) => {
  return (
    <div>
      <div
        className={clsx(
          `${darkText ? "text-formGroupLabel" : "text-label"}`,
          "text-xs"
        )}
      >
        {label}
      </div>
      <div className={clsx(`${darkText ? "text-body" : "text-white"}`)}>
        {children}
      </div>
    </div>
  );
};

export default DisplayData;
