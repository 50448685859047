import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { authSliceReducer } from "features/auth/authSlice";
import forgotPasswordReducers from "features/forgotPassword/forgotPassword.slice";
import debtsReducers from "features/debts/debtsReducers";
import registrationReducers from "features/registration/registration.slice";
import settingsReducers from "features/settings/settings.slice";
import { paymentReducer } from "features/payment/payment.slice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { errorReducer } from "features/error/errorSlice";
import { appVersionReducer } from "features/appVersion/appVersionSlice";
import { paymentLinkReducer } from "features/paymentLink/paymentLink.slice";
import { maintenanceReducer } from "features/maintenance/maintenanceSlice";

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    registration: registrationReducers,
    forgotPassword: forgotPasswordReducers,
    debts: debtsReducers,
    settings: settingsReducers,
    payment: paymentReducer,
    error: errorReducer,
    appVersion: appVersionReducer,
    paymentLink: paymentLinkReducer,
    maintenance: maintenanceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
