import { DebtDetailsDto } from "api/generated/finastic";
import React from "react";
import { useTranslation } from "react-i18next";
import DisplayNullableData from "components/DisplayNullableData";
import DisplayNumberFormat from "components/ui/DisplayNumberFormat/DisplayNumberFormat";

const DebtBalance: React.FC<DebtDetailsDto> = ({
  principalAmount,
  currencyCode,
  interestAmount,
  feeAmount,
  paidAmount,
  outstandingBalance,
}) => {
  const { t } = useTranslation();

  return (
    <div className="md:px-8 px-4">
      <div className="divide-y divide-main sm:mx-4">
        <div className="flex justify-between pb-2 flex-wrap">
          <span>{t("debt.principal")}</span>
          <DisplayNullableData value={principalAmount}>
            <span>
              <DisplayNumberFormat value={principalAmount} />
              <span className="inline-block ml-2 text-debtDetailSecondary">
                {currencyCode}
              </span>
            </span>
          </DisplayNullableData>
        </div>
        <div className="py-2 space-y-4">
          <div className="flex justify-between flex-wrap">
            <span>{t("debt.interest")}</span>
            <DisplayNullableData value={interestAmount}>
              <span>
                <DisplayNumberFormat value={interestAmount} />
                <span className="inline-block ml-2 text-debtDetailSecondary">
                  {currencyCode}
                </span>
              </span>
            </DisplayNullableData>
          </div>
          <div className="flex justify-between flex-wrap">
            <span>{t("debt.fees")}</span>
            <DisplayNullableData value={feeAmount}>
              <span>
                <DisplayNumberFormat value={feeAmount} />
                <span className="inline-block ml-2 text-debtDetailSecondary">
                  {currencyCode}
                </span>
              </span>
            </DisplayNullableData>
          </div>
        </div>
        <div className="pt-2 flex justify-between flex-wrap">
          <span>{t("debt.paid")}</span>
          <DisplayNullableData value={paidAmount}>
            <span>
              <DisplayNumberFormat value={paidAmount} />
              <span className="inline-block ml-2 text-debtDetailSecondary">
                {currencyCode}
              </span>
            </span>
          </DisplayNullableData>
        </div>
      </div>
      <div className="mt-4 py-2 px-4 bg-debtLineBg">
        <div className="flex justify-between flex-col sm:flex-row items-center">
          <span>{t("debt.outstandingBalance")}</span>
          <DisplayNullableData value={outstandingBalance}>
            <div className="flex items-center">
              <span className="font-bold text-xl">
                <DisplayNumberFormat value={outstandingBalance} />
              </span>
              <span className="inline-block ml-2 text-debtDetailSecondary">
                {currencyCode}
              </span>
            </div>
          </DisplayNullableData>
        </div>
      </div>
    </div>
  );
};

export default DebtBalance;
