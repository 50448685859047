import { useAppSelector } from "app/store";
import Content from "components/layout/Content";
import Breadcrumbs, {
  BreadcrumbsItem,
} from "components/ui/Breadcrumbs/Breadcrumbs";
import { FeatureState } from "models/FeatureState";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router";
import { debtDetailsActions } from "../DebtDetails/debtDetails.slice";
import DebtPaymentCard from "../../../components/debtPayment/DebtPaymentCard";
import { DebtPaymentFormValues } from "components/debtPayment/DebtPaymentOptions";
import { FormikHelpers } from "formik";
import { debtPaymentActions } from "./debtPayment.slice";
import { StartPaymentResultDto } from "api/generated/finastic";
import useEventLogger from "hooks/useEventLogger";

interface DebtPaymentParams {
  id: string;
}

const DebtPayment: React.FC = () => {
  const { id } = useParams<DebtPaymentParams>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [initiatePaymentResult, setInitiatePaymentResult] =
    useState<StartPaymentResultDto>();

  const breadcrumbsItems: BreadcrumbsItem[] = [
    {
      label: t("debts.title"),
      link: "/",
    },
  ];

  const { featureState, debt } = useAppSelector(
    (state) => state.debts.debtDetails
  );

  const { trackAction: trackInitiatePayment } =
    useEventLogger("InitiatePayment");

  const handleSubmit: (
    values: DebtPaymentFormValues,
    formikHelpers: FormikHelpers<DebtPaymentFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    try {
      trackInitiatePayment(values);

      const startPaymentResultDto = await dispatch(
        debtPaymentActions.initiatePayment(values)
      );

      setInitiatePaymentResult(startPaymentResultDto as StartPaymentResultDto);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(debtDetailsActions.getDebt(id));

    return () => {
      dispatch(debtDetailsActions.reset());
    };
  }, [dispatch, id]);

  if (featureState === FeatureState.Error) {
    <Redirect to="/404" />;
  }

  return (
    <Content>
      <Breadcrumbs items={breadcrumbsItems} />
      <DebtPaymentCard
        {...debt}
        isLoading={featureState === FeatureState.Loading && !debt}
        handleSubmit={handleSubmit}
        initiatePaymentResult={initiatePaymentResult}
      />
    </Content>
  );
};

export default DebtPayment;
