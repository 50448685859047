import { RequestContext } from "api/generated/finastic/runtime";
import { getAuthorizationHeader } from "api/middleware/requestHeadersMiddleware";

interface RequestToRefresh extends RequestContext {
  retry?: boolean;
}

/**
 * For multiple requests on 401 error
 */
let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

export const fetchApiRefreshTokenHandler = ({
  ctx,
  asyncTokenRefresher,
}: {
  ctx: RequestToRefresh;
  asyncTokenRefresher: () => Promise<any>;
}): Promise<any> => {
  if (ctx.retry) {
    return Promise.reject(ctx);
  }

  if (isRefreshing) {
    return new Promise(function (resolve, reject) {
      failedQueue.push({ resolve, reject });
    })
      .then(() => {
        ctx.init.headers = {
          ...ctx.init.headers,
          Authorization: getAuthorizationHeader(),
        };

        return ctx.fetch(ctx.url, ctx.init);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  ctx.retry = true;
  isRefreshing = true;

  return new Promise(function (resolve, reject) {
    asyncTokenRefresher()
      .then(() => {
        processQueue(null);
        ctx.init.headers = {
          ...ctx.init.headers,
          Authorization: getAuthorizationHeader(),
        };
        resolve(ctx.fetch(ctx.url, ctx.init));
      })
      .catch((err) => {
        processQueue(err);
        reject(err);
      })
      .finally(() => {
        isRefreshing = false;
      });
  });
};
