import clsx from "clsx";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import React, { HTMLAttributeAnchorTarget } from "react";
import { Link } from "react-router-dom";

export interface SidebarMenuItemI {
  icon: string;
  href?: string;
  label: string;
  target?: HTMLAttributeAnchorTarget;
  as?: React.ElementType;
  onClick?: () => void;
  id?: string;
}

export interface SidebarMenuItemProps extends SidebarMenuItemI {
  isToggled: boolean;
}

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  as: Component = Link,
  icon,
  href,
  label,
  target,
  isToggled,
  onClick,
  id,
}) => {
  return (
    <Component
      to={href}
      href={href}
      target={target}
      className={clsx(
        "flex items-center text-primary font-bold hover:text-primaryDark w-full",
        {
          "justify-center": isToggled,
        }
      )}
      title={label}
      onClick={onClick}
      id={id}
    >
      <MaterialIcon className="h-5 relative -top-px" path={icon} />
      {!isToggled && <span className="inline-block ml-4">{label}</span>}
    </Component>
  );
};

export default SidebarMenuItem;
