import clsx from "clsx";
import React from "react";

interface FormGroupProps {
  children: React.ReactNode;
  hidden?: boolean;
}

const FormGroup: React.FC<FormGroupProps> = ({ children, hidden }) => {
  return <div className={clsx("mb-6", { hidden: hidden })}>{children}</div>;
};

export default FormGroup;
