import { useAppSelector } from "app/store";
import Content from "components/layout/Content";
import DebtPaymentCard from "components/debtPayment/DebtPaymentCard";
import { FeatureState } from "models/FeatureState";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InvalidPaymentIdModal from "./components/InvalidPaymentIdModal";
import { paymentLinkActions } from "./paymentLink.slice";
import { DebtPaymentFormValues } from "components/debtPayment/DebtPaymentOptions";
import { FormikHelpers } from "formik";
import { StartPaymentResultDto } from "api/generated/finastic";
import LoadingDebtCardSkeleton from "./components/LoadingDebtCardSkeleton";
import useEventLogger from "hooks/useEventLogger";
import { useParams } from "react-router";
import { BackendCode } from "models/BackendCode";
import { PaymentContext } from "contexts/PaymentContext";

interface PayDebtLinkParams {
  backendCode: BackendCode;
  debtId: string;
}

const PayDebt: React.FC = () => {
  const dispatch = useDispatch();
  const { getDebtState, debt } = useAppSelector((state) => state.paymentLink);
  const [initiatePaymentResult, setInitiatePaymentResult] =
    useState<StartPaymentResultDto>();
  const { criticalErrorHappened, currentGlobalErrorKey } = useAppSelector(
    (state) => state.error
  );

  const { backendCode, debtId } = useParams<PayDebtLinkParams>();

  const { isFinasticUser, ...debtPaymentData } = debt || {};

  const { trackAction: trackInitiatePayment } =
    useEventLogger("InitiatePayment");

  const handleSubmit: (
    values: DebtPaymentFormValues,
    formikHelpers: FormikHelpers<DebtPaymentFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    try {
      trackInitiatePayment(values);

      const startPaymentResultDto = await dispatch(
        paymentLinkActions.initiatePayment(values, backendCode)
      );

      setInitiatePaymentResult(startPaymentResultDto as StartPaymentResultDto);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  const getDebt = useCallback(async () => {
    try {
      await dispatch(paymentLinkActions.getDebt(debtId, backendCode));
    } catch (error) {
      console.error(error);
    }
  }, [debtId, dispatch, backendCode]);

  useEffect(() => {
    dispatch(paymentLinkActions.setBackendCode(backendCode));
  }, [backendCode, dispatch]);

  useEffect(() => {
    getDebt();
  }, [getDebt]);

  return (
    <Content>
      {getDebtState === FeatureState.Initial ||
      getDebtState === FeatureState.Loading ? (
        <LoadingDebtCardSkeleton />
      ) : (
        <PaymentContext.Provider
          value={{
            isFinasticUser,
            isPaymentLinkPay: true,
          }}
        >
          <DebtPaymentCard
            {...debtPaymentData}
            handleSubmit={handleSubmit}
            initiatePaymentResult={initiatePaymentResult}
          />
        </PaymentContext.Provider>
      )}

      {getDebtState === FeatureState.Error &&
        !criticalErrorHappened &&
        !currentGlobalErrorKey && <InvalidPaymentIdModal />}
    </Content>
  );
};

export default PayDebt;
