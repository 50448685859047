import Modal from "components/ui/Modal/Modal";
import React from "react";
import { useTranslation } from "react-i18next";

export interface CancelModalProps {
  isOpen: boolean;
  onOk?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CancelModal: React.FC<CancelModalProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      title={t("registration.cancelModal.title")}
      description={t("registration.cancelModal.description")}
      okText={t("registration.cancelModal.continueButtonText")}
      cancelText={t("registration.cancelModal.cancelButtonText")}
      type="alert"
      alertType={"warning"}
      showCancelButton
      size="large"
    />
  );
};

export default CancelModal;
