import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { PaymentStatus } from "api/generated/finastic";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";

export interface PaymentState {
  featureState: FeatureState;
}

const initialState: PaymentState = {
  featureState: FeatureState.Loading,
};

const payment = createSlice({
  name: "payment",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
  },
});

const { reset, setState } = payment.actions;

export const paymentReducer = payment.reducer;

const getPaymentStatus =
  (id: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    return api.finastic.payment
      .apiFrontendV1PaymentIdGet({ id })
      .then((data) => {
        if (data.status === PaymentStatus.Successful) {
          dispatch(setState(FeatureState.Success));
        } else if (
          data.status === PaymentStatus.Invalid ||
          data.status === PaymentStatus.Failed
        ) {
          dispatch(setState(FeatureState.Error));
        } else {
          dispatch(setState(FeatureState.Loading));
        }
      })
      .catch((error) => {
        console.error(error);

        dispatch(setState(FeatureState.Error));
      });
  };

export const paymentActions = {
  reset,
  getPaymentStatus,
};
