import Login from "features/auth/Login";
import RegistrationRouteSwitch, {
  registrationParentPath,
} from "features/registration/RegistrationRouteSwitch";
import React, { useMemo } from "react";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import ForgotPasswordRouteSwitch from "features/forgotPassword/ForgotPasswordRouteSwitch";
import DebtsList from "features/debts/DebtsList/DebtsList";
import DebtDetails from "features/debts/DebtDetails/DebtDetails";
import DebtPayment from "features/debts/DebtPayment/DebtPayment";
import SettingsRouteSwitch from "features/settings/SettingsRouteSwitch";
import { useTranslation } from "react-i18next";
import Payment from "features/payment/Payment";
import AppVersionMismatch from "features/appVersion/AppVersionMismatch";
import ResolvePaymentLink from "features/paymentLink/ResolvePaymentLink";
import { PaymentLinkLayout } from "components/layout/PaymentLinkLayout";
import PaymentLinkRouteSwitch from "features/paymentLink/PaymentLinkRouteSwitch";
import BarcodePage from "features/barcode/BarcodePage";
import Maintenance from "features/maintenance/Maintenance";

export enum RouteItemTypes {
  Public = "Public",
  Private = "Private",
}

export interface RouteItem {
  path: string;
  component: React.ElementType;
  type: RouteItemTypes;
  exact?: boolean;
  /** restricted meaning it can only be accessed, if the user is not logged in */
  restricted?: boolean;
  layout?: React.ElementType;
  roles?: any[];
  title?: string;
  withLayout?: boolean;
}

const useRoutes = (): JSX.Element[] => {
  const { t } = useTranslation();

  const routesArray = useMemo<RouteItem[]>(
    () => [
      {
        path: "",
        title: t("pageTitle.myDebts"),
        component: DebtsList,
        type: RouteItemTypes.Private,
        exact: true,
      },
      {
        path: "settings",
        component: SettingsRouteSwitch,
        type: RouteItemTypes.Private,
      },
      {
        path: "login",
        title: t("pageTitle.login"),
        component: Login,
        type: RouteItemTypes.Public,
        restricted: true,
      },
      {
        path: registrationParentPath,
        component: RegistrationRouteSwitch,
        type: RouteItemTypes.Public,
      },
      {
        path: "forgot-password",
        component: ForgotPasswordRouteSwitch,
        type: RouteItemTypes.Public,
      },
      {
        path: "debts/:id/details",
        title: t("pageTitle.debtDetails"),
        component: DebtDetails,
        type: RouteItemTypes.Private,
      },
      {
        path: "debts/:id/repayment",
        title: t("pageTitle.debtRepayment"),
        component: DebtPayment,
        type: RouteItemTypes.Private,
      },
      {
        path: "payment",
        title: t("pageTitle.payment"),
        component: Payment,
        type: RouteItemTypes.Private,
      },
      {
        path: "app-version-mismatch",
        title: t("pageTitle.appVersionMismatch"),
        component: AppVersionMismatch,
        type: RouteItemTypes.Public,
      },
      {
        path: `pay/:backendCode(${process.env.REACT_APP_SUPPORTED_BACKEND_CODES?.split(
          ","
        ).join("|")})/:paymentUuid`, // :backendCode(c|o|SOME_FUTURE_BACKEND_CODE)
        title: t("pageTitle.debtRepayment"),
        component: ResolvePaymentLink,
        layout: PaymentLinkLayout,
        type: RouteItemTypes.Public,
      },
      {
        path: "link-payment",
        component: PaymentLinkRouteSwitch,
        type: RouteItemTypes.Public,
        layout: PaymentLinkLayout,
      },
      {
        path: "barcode/:barcodeId",
        component: BarcodePage,
        type: RouteItemTypes.Public,
        layout: ({ children }) => <>{children}</>,
      },
      {
        path: "maintenance",
        title: t("pageTitle.maintenance"),
        component: Maintenance,
        type: RouteItemTypes.Public,
      },
    ],
    [t]
  );

  const routes = routesArray.map(({ type, path, component, ...props }) =>
    type === RouteItemTypes.Private ? (
      <PrivateRoute
        {...props}
        component={component}
        path={`/${path}`}
        key={path}
      />
    ) : (
      <PublicRoute
        {...props}
        component={component}
        path={`/${path}`}
        key={path}
      />
    )
  );

  return routes;
};

export default useRoutes;
