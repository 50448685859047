import React, { useEffect, useMemo } from "react";
import Card from "components/ui/Card/Card";
import { H3, H6 } from "components/ui/Headings/Headings";
import { useTranslation } from "react-i18next";
import Alert from "components/ui/Alert/Alert";
import Button from "components/ui/Button/Button";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { emailConfirmationActions } from "./emailConfirmation.slice";
import { useAppSelector } from "app/store";
import { FeatureState } from "models/FeatureState";
import Skeleton from "components/ui/Skeleton/Skeleton";
import { registrationParentPath } from "../RegistrationRouteSwitch";
import useAuth from "features/auth/useAuth";
import { authSliceActions } from "features/auth/authSlice";
import Content from "components/layout/Content";
import useQuery from "hooks/useQuery";

const EmailConfirmation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userIsLoggedIn } = useAuth();

  const query = useQuery();

  const token = useMemo(
    () => decodeURIComponent(query.get("token") || ""),
    [query]
  );

  const { featureState } = useAppSelector(
    (state) => state.registration.emailConfirmation
  );

  useEffect(() => {
    if (userIsLoggedIn) {
      dispatch(authSliceActions.logOut());
    } else if (token) {
      dispatch(emailConfirmationActions.confirmEmail(token));
    }
  }, [dispatch, token, userIsLoggedIn]);

  return (
    <Content>
      <Card
        footer={
          <>
            {featureState === FeatureState.Loading ? (
              <div className="animate-pulse flex justify-end">
                <p className="h-4 min-w-cardButton bg-disabled rounded" />
              </div>
            ) : (
              <div className="flex justify-end">
                <div className="min-w-cardButton">
                  {featureState === FeatureState.Success ? (
                    <Button as={Link} to={"/login"} block>
                      {t("registration.emailConfirmation.loginButtonText")}
                    </Button>
                  ) : (
                    <Button as={Link} to={`/${registrationParentPath}`} block>
                      {t(
                        "registration.emailConfirmation.expired.backToRegistrationButtonText"
                      )}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </>
        }
        isDense
      >
        {featureState === FeatureState.Success && (
          <>
            <H3>{t("registration.emailConfirmation.title")}</H3>
            <Alert type="success">
              {t("registration.emailConfirmation.description")}
            </Alert>
          </>
        )}
        {featureState === FeatureState.Error && (
          <>
            <H6>{t("registration.emailConfirmation.expired.subTitle")}</H6>
            <H3>{t("registration.emailConfirmation.expired.title")}</H3>
            <Alert type="error">
              {t("registration.emailConfirmation.expired.description")}
            </Alert>
          </>
        )}
        {featureState === FeatureState.Loading && <Skeleton />}
      </Card>
    </Content>
  );
};

export default EmailConfirmation;
