import React from "react";
import Card from "components/ui/Card/Card";
import { H3, H6 } from "components/ui/Headings/Headings";
import { useTranslation } from "react-i18next";
import Alert from "components/ui/Alert/Alert";
import Button from "components/ui/Button/Button";
import { Link } from "react-router-dom";
import Content from "components/layout/Content";

const EmailConfirmationExpired: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <Card
        footer={
          <div className="flex justify-between flex-wrap -my-2">
            <div className="my-2 min-w-cardButton">
              <Button as={Link} to="/login" block variant="outline">
                {t(
                  "forgotPassword.emailValidation.expired.backToLoginButtonText"
                )}
              </Button>
            </div>
            <div className="my-2 min-w-cardButton">
              <Button as={Link} to="/forgot-password?resetType=email" block>
                {t(
                  "forgotPassword.emailValidation.expired.backToForgotPassword"
                )}
              </Button>
            </div>
          </div>
        }
        isDense
      >
        <H6>{t("forgotPassword.emailValidation.expired.subTitle")}</H6>
        <H3>{t("forgotPassword.emailValidation.expired.title")}</H3>
        <Alert type="error">
          {t("forgotPassword.emailValidation.expired.description")}
        </Alert>
      </Card>
    </Content>
  );
};

export default EmailConfirmationExpired;
