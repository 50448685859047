import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { VersionDto } from "api/generated/finastic";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";
import packageJson from "../../../package.json";
import { appVersionStringToDto } from "./utils";

export interface AppVersionState {
  featureState: FeatureState;
  appVersion: VersionDto;
  minimumAppVersionRequiredByApi?: VersionDto;
}

const initialState: AppVersionState = {
  featureState: FeatureState.Initial,
  appVersion: appVersionStringToDto(packageJson.version),
  minimumAppVersionRequiredByApi: undefined,
};

const appVersion = createSlice({
  name: "appVersion",
  initialState,
  reducers: {
    reset: () => initialState,
    setMinimumAppVersionRequiredByApi(
      state,
      { payload }: PayloadAction<VersionDto>
    ) {
      state.minimumAppVersionRequiredByApi = payload;
    },
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
  },
});

const { reset, setMinimumAppVersionRequiredByApi, setState } =
  appVersion.actions;

export const appVersionReducer = appVersion.reducer;

const getMinimumAppVersion =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    dispatch(setState(FeatureState.Loading));

    return api.finastic.configuration
      .apiFrontendV1ConfigurationMinimumAppVersionGet()
      .then((value) => {
        dispatch(setMinimumAppVersionRequiredByApi(value));

        dispatch(setState(FeatureState.Success));
      })
      .catch((error) => {
        dispatch(setState(FeatureState.Error));

        throw error;
      });
  };

export const appVersionActions = {
  reset,
  setMinimumAppVersionRequiredByApi,
  getMinimumAppVersion,
};
