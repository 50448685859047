/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentStatus = {
    Invalid: 'Invalid',
    New: 'New',
    Pending: 'Pending',
    Successful: 'Successful',
    Failed: 'Failed'
} as const;
export type PaymentStatus = typeof PaymentStatus[keyof typeof PaymentStatus];


export function PaymentStatusFromJSON(json: any): PaymentStatus {
    return PaymentStatusFromJSONTyped(json, false);
}

export function PaymentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatus {
    return json as PaymentStatus;
}

export function PaymentStatusToJSON(value?: PaymentStatus | null): any {
    return value as any;
}

