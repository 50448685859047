import { CountryCode, LanguageCode } from "api/generated/finastic";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type LanguageCodeCountryCodeMap = {
  [key in LanguageCode]: CountryCode | undefined;
};

const languageCodeCountryCodeMap: LanguageCodeCountryCodeMap = {
  [LanguageCode.Invalid]: undefined,
  [LanguageCode.En]: undefined,
  [LanguageCode.Ro]: CountryCode.Ro,
  [LanguageCode.Pl]: CountryCode.Pl,
};

type CountryCodePersonIdentifierLengthMap = {
  [key in CountryCode]: number;
};

const countryCodePersonIdentifierLengthMap: CountryCodePersonIdentifierLengthMap =
  {
    [CountryCode.Invalid]: 0,
    [CountryCode.Ro]: 13,
    [CountryCode.Pl]: 11,
  };

type CountryCodeTranslation = {
  [key in CountryCode]: string;
};

type CountryCodePhonePrefixMap = {
  [key in CountryCode]: string;
};

interface UseLanguageCodeCountryCodeLogics {
  languageCodeCountryCodeMap: LanguageCodeCountryCodeMap;
  countryCodePersonIdentifierLengthMap: CountryCodePersonIdentifierLengthMap;
  countryCodeAccountIdentifierLabelMap: CountryCodeTranslation;
  countryCodeAccountIdentifierPlaceholderMap: CountryCodeTranslation;
  personIdentifierLabelMap: CountryCodeTranslation;
  personIdentifierPlaceholderMap: CountryCodeTranslation;
  countryCodePhonePrefixMap: CountryCodePhonePrefixMap;
  countryCodeTACLabelMap: CountryCodeTranslation;
  countryCodePrivacyPolicyLabelMap: CountryCodeTranslation;
  countryCodeRegistrationPushMarketingLabelMap: CountryCodeTranslation;
  countryCodeRegistrationSMSMarketingLabelMap: CountryCodeTranslation;
  countryCodeRegistrationEmailMarketingLabelMap: CountryCodeTranslation;
  countryCodeSettingsPushMarketingTitleMap: CountryCodeTranslation;
  countryCodeSettingsPushMarketingDescriptionMap: CountryCodeTranslation;
  countryCodeSettingsSMSMarketingTitleMap: CountryCodeTranslation;
  countryCodeSettingsSMSMarketingDescriptionMap: CountryCodeTranslation;
  countryCodeSettingsEmailMarketingTitleMap: CountryCodeTranslation;
  countryCodeSettingsEmailMarketingDescriptionMap: CountryCodeTranslation;
  countryCodePaymentAgreementUrlMap: CountryCodeTranslation;
  paymentLinkPrivacyTextMap: CountryCodeTranslation;
}

const useLanguageCodeCountryCodeLogics =
  (): UseLanguageCodeCountryCodeLogics => {
    const { t } = useTranslation();

    const countryCodeAccountIdentifierLabelMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t(
            "registration.profileData.accountIdentifier.romania"
          ),
          [CountryCode.Pl]: t(
            "registration.profileData.accountIdentifier.poland"
          ),
        }),
        [t]
      );

    const countryCodeAccountIdentifierPlaceholderMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t(
            "registration.profileData.accountIdentifierPlaceholder.romania"
          ),
          [CountryCode.Pl]: t(
            "registration.profileData.accountIdentifierPlaceholder.poland"
          ),
        }),
        [t]
      );

    const personIdentifierLabelMap: CountryCodeTranslation = useMemo(
      () => ({
        [CountryCode.Invalid]: "",
        [CountryCode.Ro]: t(
          "registration.profileData.personIdentifier.romania"
        ),
        [CountryCode.Pl]: t("registration.profileData.personIdentifier.poland"),
      }),
      [t]
    );

    const personIdentifierPlaceholderMap: CountryCodeTranslation = useMemo(
      () => ({
        [CountryCode.Invalid]: "",
        [CountryCode.Ro]: t(
          "registration.profileData.personIdentifierPlaceholder.romania"
        ),
        [CountryCode.Pl]: t(
          "registration.profileData.personIdentifierPlaceholder.poland"
        ),
      }),
      [t]
    );

    const countryCodePhonePrefixMap: CountryCodePhonePrefixMap = useMemo(
      () => ({
        [CountryCode.Invalid]: "",
        [CountryCode.Ro]: t("config.phoneNumberPrefix", {
          lng: LanguageCode.Ro,
        }),
        [CountryCode.Pl]: t("config.phoneNumberPrefix", {
          lng: LanguageCode.Pl,
        }),
      }),
      [t]
    );

    const countryCodeTACLabelMap: CountryCodeTranslation = useMemo(
      () => ({
        [CountryCode.Invalid]: "",
        [CountryCode.Ro]: t(
          "registration.profileData.termsAndConditions.romania",
          {
            termsLink: t("config.termsUrl", {
              lng: LanguageCode.Ro,
            }),
            publicNoticeLink: t("config.publicNoticeUrl", {
              lng: LanguageCode.Ro,
            }),
          }
        ),
        [CountryCode.Pl]: t(
          "registration.profileData.termsAndConditions.poland",
          {
            termsLink: t("config.termsUrl", {
              lng: LanguageCode.Pl,
            }),
            publicNoticeLink: t("config.publicNoticeUrl", {
              lng: LanguageCode.Pl,
            }),
          }
        ),
      }),
      [t]
    );

    const countryCodePrivacyPolicyLabelMap: CountryCodeTranslation = useMemo(
      () => ({
        [CountryCode.Invalid]: "",
        [CountryCode.Ro]: t("registration.profileData.privacyPolicy.romania", {
          link: t("config.privacyAndCookiePolicyUrl", {
            lng: LanguageCode.Ro,
          }),
        }),
        [CountryCode.Pl]: t("registration.profileData.privacyPolicy.poland", {
          link: t("config.privacyAndCookiePolicyUrl", {
            lng: LanguageCode.Pl,
          }),
        }),
      }),
      [t]
    );

    const countryCodeRegistrationPushMarketingLabelMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t("registration.profileData.pushMarketing.romania"),
          [CountryCode.Pl]: t("registration.profileData.pushMarketing.poland"),
        }),
        [t]
      );

    const countryCodeRegistrationSMSMarketingLabelMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t("registration.profileData.SMSMarketing.romania"),
          [CountryCode.Pl]: t("registration.profileData.SMSMarketing.poland"),
        }),
        [t]
      );

    const countryCodeRegistrationEmailMarketingLabelMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t(
            "registration.profileData.emailMarketing.romania"
          ),
          [CountryCode.Pl]: t("registration.profileData.emailMarketing.poland"),
        }),
        [t]
      );

    const countryCodeSettingsPushMarketingTitleMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t(
            "settings.privacyConsentPage.pushMarketing.romania.title"
          ),
          [CountryCode.Pl]: t(
            "settings.privacyConsentPage.pushMarketing.poland.title"
          ),
        }),
        [t]
      );

    const countryCodeSettingsPushMarketingDescriptionMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t(
            "settings.privacyConsentPage.pushMarketing.romania.description"
          ),
          [CountryCode.Pl]: t(
            "settings.privacyConsentPage.pushMarketing.poland.description"
          ),
        }),
        [t]
      );

    const countryCodeSettingsSMSMarketingTitleMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t(
            "settings.privacyConsentPage.smsMarketing.romania.title"
          ),
          [CountryCode.Pl]: t(
            "settings.privacyConsentPage.smsMarketing.poland.title"
          ),
        }),
        [t]
      );

    const countryCodeSettingsSMSMarketingDescriptionMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t(
            "settings.privacyConsentPage.smsMarketing.romania.description"
          ),
          [CountryCode.Pl]: t(
            "settings.privacyConsentPage.smsMarketing.poland.description"
          ),
        }),
        [t]
      );

    const countryCodeSettingsEmailMarketingTitleMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t(
            "settings.privacyConsentPage.emailMarketing.romania.title"
          ),
          [CountryCode.Pl]: t(
            "settings.privacyConsentPage.emailMarketing.poland.title"
          ),
        }),
        [t]
      );

    const countryCodeSettingsEmailMarketingDescriptionMap: CountryCodeTranslation =
      useMemo(
        () => ({
          [CountryCode.Invalid]: "",
          [CountryCode.Ro]: t(
            "settings.privacyConsentPage.emailMarketing.romania.description"
          ),
          [CountryCode.Pl]: t(
            "settings.privacyConsentPage.emailMarketing.poland.description"
          ),
        }),
        [t]
      );

    const countryCodePaymentAgreementUrlMap: CountryCodeTranslation = useMemo(
      () => ({
        [CountryCode.Invalid]: "",
        [CountryCode.Ro]: t("config.paymentAgreementUrl", {
          lng: LanguageCode.Ro,
        }),
        [CountryCode.Pl]: t("config.paymentAgreementUrl", {
          lng: LanguageCode.Pl,
        }),
      }),
      [t]
    );

    const paymentLinkPrivacyTextMap: CountryCodeTranslation = useMemo(
      () => ({
        [CountryCode.Invalid]: "",
        [CountryCode.Ro]: t("paymentLink.noteForNonFinasticUsers", {
          lng: LanguageCode.Ro,
          paymentLinkPrivacyUrl: t("config.privacyAndCookiePolicyUrl", {
            lng: LanguageCode.Ro,
          }),
        }),
        [CountryCode.Pl]: t("paymentLink.noteForNonFinasticUsers", {
          lng: LanguageCode.Pl,
          paymentLinkPrivacyUrl: t("config.privacyAndCookiePolicyUrl", {
            lng: LanguageCode.Pl,
          }),
        }),
      }),
      [t]
    );

    return {
      languageCodeCountryCodeMap,
      countryCodePersonIdentifierLengthMap,
      countryCodeAccountIdentifierLabelMap,
      countryCodeAccountIdentifierPlaceholderMap,
      personIdentifierLabelMap,
      personIdentifierPlaceholderMap,
      countryCodePhonePrefixMap,
      countryCodeTACLabelMap,
      countryCodePrivacyPolicyLabelMap,
      countryCodeRegistrationPushMarketingLabelMap,
      countryCodeRegistrationSMSMarketingLabelMap,
      countryCodeRegistrationEmailMarketingLabelMap,
      countryCodeSettingsPushMarketingTitleMap,
      countryCodeSettingsPushMarketingDescriptionMap,
      countryCodeSettingsSMSMarketingTitleMap,
      countryCodeSettingsSMSMarketingDescriptionMap,
      countryCodeSettingsEmailMarketingTitleMap,
      countryCodeSettingsEmailMarketingDescriptionMap,
      countryCodePaymentAgreementUrlMap,
      paymentLinkPrivacyTextMap,
    };
  };

export default useLanguageCodeCountryCodeLogics;
