import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { DebtDetailsDto } from "api/generated/finastic";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";

export interface DebtDetailsState {
  featureState: FeatureState;
  debt?: DebtDetailsDto;
}

const initialState: DebtDetailsState = {
  featureState: FeatureState.Initial,
  debt: undefined,
};

const debtDetails = createSlice({
  name: "debtDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
    getDebtSuccess(state, { payload }: PayloadAction<DebtDetailsDto>) {
      state.debt = payload;
      state.featureState = FeatureState.Success;
    },
  },
});

const { reset, setState, getDebtSuccess } = debtDetails.actions;

export const debtDetailsReducer = debtDetails.reducer;

const getDebt =
  (debtId: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch(setState(FeatureState.Loading));

    return api.finastic.debt
      .apiFrontendV1DebtDebtIdGet({ debtId })
      .then((value) => {
        dispatch(getDebtSuccess(value));
      })
      .catch((error) => {
        dispatch(setState(FeatureState.Error));

        throw error;
      });
  };

export const debtDetailsActions = {
  getDebt,
  reset,
};
