import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { browserHistory } from "./history";
import store from "app/store";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((telemetryItem) => {
  const state = store.getState();
  const user = state.auth.user;
  if (telemetryItem.data && user) {
    telemetryItem.data.finasticCountryCode = user.countryCode;
  }
});

export { reactPlugin, appInsights };
