import Button from "components/ui/Button/Button";
import { H6 } from "components/ui/Headings/Headings";
import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { StepWizardChildProps } from "react-step-wizard";
import { debtPaymentPlanActions } from "../DebtPaymentPlan.slice";
import StepNavigation from "./StepNavigation";
import { useAppSelector } from "app/store";
import Modal from "components/ui/Modal/Modal";
import InstalmentDateModalContent from "./InstalmentDateModalContent";
import InstalmentDateData from "./InstalmentDateData";

export interface Step2FormData {
  firstInstalmentDueDate: Date;
}

const Step2: React.FC<Partial<StepWizardChildProps>> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { step2FormData } = useAppSelector(
    (state) => state.debts.debtPaymentPlan
  );

  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);

  return (
    <>
      <div className="md:px-8 px-4">
        <H6>{t("debt.createPaymentPlanWizard.step2Title")}</H6>
      </div>
      {step2FormData && (
        <Formik<Step2FormData>
          initialValues={step2FormData}
          onSubmit={(values, actions) => {
            nextStep?.();
          }}
        >
          {(formikProps) => (
            <>
              <div className="md:px-8 px-4 mt-7 pb-16">
                <InstalmentDateData
                  firstInstalmentDueDate={
                    formikProps.values.firstInstalmentDueDate
                  }
                />
                <div className="flex ml-8 mt-4">
                  <div className="min-w-cardButton">
                    <Button
                      variant="outline"
                      type="button"
                      block
                      onClick={() => setIsDatePickerModalOpen(true)}
                    >
                      {t("debt.editDate")}
                    </Button>
                  </div>
                </div>
              </div>
              <StepNavigation
                firstButton={
                  <Button
                    variant="outline"
                    block
                    onClick={previousStep}
                    type="button"
                  >
                    {t("common.back")}
                  </Button>
                }
                secondButton={
                  <Button
                    block
                    type="button"
                    onClick={() => formikProps.submitForm()}
                  >
                    {t("common.next")}
                  </Button>
                }
              />
              <Modal
                isOpen={isDatePickerModalOpen}
                title={t("debt.instalmentDateModal.title")}
                description={t("debt.instalmentDateModal.description")}
                okText={t("debt.instalmentDateModal.okButtonText")}
                cancelText={t("common.cancel")}
                showCancelButton
                size="xlarge"
                onCancel={() => {
                  formikProps.resetForm({ values: step2FormData });

                  setIsDatePickerModalOpen(false);
                }}
                onOk={() => {
                  dispatch(
                    debtPaymentPlanActions.setForm2Data(formikProps.values)
                  );

                  setIsDatePickerModalOpen(false);
                }}
              >
                <InstalmentDateModalContent />
              </Modal>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default Step2;
