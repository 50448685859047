import React, { useEffect, useMemo, useState } from "react";
import PaginationButton from "./components/PaginationButton";
import {
  mdiChevronDoubleLeft,
  mdiChevronLeft,
  mdiChevronDoubleRight,
  mdiChevronRight,
} from "@mdi/js";
import MaterialIcon from "../MaterialIcon/MaterialIcon";

interface PaginationProps {
  onChange?: (newValue: number) => void;
  total?: number;
  pageSize?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (newValue: number) => {},
  total = 0,
  pageSize = 10,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const neededButtonsCount = useMemo(
    () => Math.ceil(total / pageSize),
    [pageSize, total]
  );

  useEffect(() => {
    onChange(currentPage);
  }, [currentPage, onChange]);

  return neededButtonsCount > 1 ? (
    <div className="space-x-2 flex items-start flex-wrap -my-2">
      <div className="my-2">
        <PaginationButton
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
        >
          <MaterialIcon style={{ width: "14px" }} path={mdiChevronDoubleLeft} />
        </PaginationButton>
      </div>
      <div className="my-2">
        <PaginationButton
          onClick={() => setCurrentPage((oldValue) => oldValue - 1)}
          disabled={currentPage === 1}
        >
          <MaterialIcon style={{ width: "14px" }} path={mdiChevronLeft} />
        </PaginationButton>
      </div>

      {Array.from(new Array(neededButtonsCount)).map((_, index) => (
        <div className="my-2">
          <PaginationButton
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            active={index + 1 === currentPage}
          >
            {index + 1}
          </PaginationButton>
        </div>
      ))}
      <div className="my-2">
        <PaginationButton
          onClick={() => setCurrentPage((oldValue) => oldValue + 1)}
          disabled={currentPage === neededButtonsCount}
        >
          <MaterialIcon style={{ width: "14px" }} path={mdiChevronRight} />
        </PaginationButton>
      </div>

      <div className="my-2">
        <PaginationButton
          onClick={() => setCurrentPage(neededButtonsCount)}
          disabled={currentPage === neededButtonsCount}
        >
          <MaterialIcon
            style={{ width: "14px" }}
            path={mdiChevronDoubleRight}
          />
        </PaginationButton>
      </div>
    </div>
  ) : null;
};

export default Pagination;
