import { combineReducers } from "@reduxjs/toolkit";
import { emailConfirmationReducer } from "./EmailConfirmation/emailConfirmation.slice";
import { oneTimePasscodeReducer } from "./OneTimePasscode/oneTimePasscode.slice";
import { profileDataReducer } from "./ProfileData/profileData.slice";
import { setPasswordReducer } from "./SetPassword/setPassword.slice";

const registrationReducers = combineReducers({
  profileData: profileDataReducer,
  oneTimePasscode: oneTimePasscodeReducer,
  setPassword: setPasswordReducer,
  emailConfirmation: emailConfirmationReducer,
});

export default registrationReducers;
