import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";
import { mapPhoneNumberFormGroupValueToString } from "utils/mappers";
import { OneTimePasscodeFormValues } from "./OneTimePasscode";

export interface OneTimePasscodeState {
  formValues?: OneTimePasscodeFormValues;
  featureState: FeatureState;
  token?: string | null;
}

const initialState: OneTimePasscodeState = {
  formValues: undefined,
  featureState: FeatureState.Initial,
  token: undefined,
};

const oneTimePasscode = createSlice({
  name: "oneTimePasscode",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
    setFormValues(
      state,
      { payload }: PayloadAction<OneTimePasscodeFormValues>
    ) {
      state.formValues = payload;
    },
    checkPasscodeSuccess(
      state,
      { payload }: PayloadAction<string | null | undefined>
    ) {
      state.token = payload;
      state.featureState = FeatureState.Success;
    },
  },
});

const { setFormValues, reset, setState, checkPasscodeSuccess } =
  oneTimePasscode.actions;

export const oneTimePasscodeReducer = oneTimePasscode.reducer;

const resendSMS = (): AppThunk<Promise<any>> => async (dispatch, getState) => {
  dispatch(setState(FeatureState.Loading));

  const state = getState();
  const dataFromPreviousStep = state.registration.profileData.formValues;

  if (
    !dataFromPreviousStep ||
    typeof dataFromPreviousStep.personIdentifier !== "string"
  ) {
    return;
  }

  return api.finastic.registration.apiFrontendV1RegistrationCheckCustomerPut({
    checkCustomerCommand: {
      countryCode: dataFromPreviousStep.countryCode,
      personIdentifier: dataFromPreviousStep.personIdentifier,
      accountIdentifier: dataFromPreviousStep.accountIdentifier,
      firstName: dataFromPreviousStep.firstName,
      lastName: dataFromPreviousStep.lastName,
      emailAddress: dataFromPreviousStep.emailAddress,
      phoneNumber: mapPhoneNumberFormGroupValueToString(
        dataFromPreviousStep.phoneNumber
      ),
    },
  });
};

const checkPasscode =
  (otp: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch(setState(FeatureState.Loading));

    const state = getState();
    const dataFromPreviousStep = state.registration.profileData.formValues;

    if (!dataFromPreviousStep) {
      return;
    }

    const phoneNumber = mapPhoneNumberFormGroupValueToString(
      dataFromPreviousStep.phoneNumber
    );

    return api.finastic.registration
      .apiFrontendV1RegistrationCheckOtpPut({
        checkRegistrationOtpCommand: {
          phoneNumber,
          otp,
        },
      })
      .then(({ token }) => {
        dispatch(checkPasscodeSuccess(token));
      })
      .catch((error) => {
        dispatch(setState(FeatureState.Error));

        throw error;
      });
  };

export const oneTimePasscodeActions = {
  setFormValues,
  reset,
  checkPasscode,
  resendSMS,
};
