/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface UserLoginDto
 */
export interface UserLoginDto {
    /**
     * 
     * @type {string}
     * @memberof UserLoginDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {Role}
     * @memberof UserLoginDto
     */
    role?: Role;
    /**
     * 
     * @type {string}
     * @memberof UserLoginDto
     */
    accessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginDto
     */
    refreshToken?: string | null;
}

export function UserLoginDtoFromJSON(json: any): UserLoginDto {
    return UserLoginDtoFromJSONTyped(json, false);
}

export function UserLoginDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLoginDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
    };
}

export function UserLoginDtoToJSON(value?: UserLoginDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'emailAddress': value.emailAddress,
        'role': RoleToJSON(value.role),
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
    };
}

