import { Route, Redirect } from "react-router-dom";
import { DefaultLayout } from "components/layout/DefaultLayout";
import useAuth from "features/auth/useAuth";
import DocumentTitle from "components/DocumentTitle";

interface PublicRouteProps {
  component: React.ElementType;
  layout?: React.ElementType;
  /** restricted meaning it can only be accessed, if the user is not logged in */
  restricted?: boolean;
  path?: string;
  title?: string;
  exact?: boolean;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({
  component: Component,
  layout: Layout = DefaultLayout,
  restricted,
  title,
  ...rest
}) => {
  const { userIsLoggedIn } = useAuth();

  return (
    <Route
      {...rest}
      render={(props: any) => {
        const userCameFrom =
          props.location.state && props.location.state.from
            ? props.location.state.from.pathname +
              props.location.state.from.search +
              props.location.state.from.hash
            : "/";

        const redirectTo = userCameFrom;

        // restricted meaning it can only be accessed, if the user is not logged in
        return userIsLoggedIn && restricted ? (
          <Redirect to={redirectTo} />
        ) : (
          <Layout>
            <DocumentTitle title={title} />
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};
