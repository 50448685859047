import { Middleware, RequestContext } from "api/generated/finastic";
import store from "app/store";
import { getLocalStorageValue, LocalStorageKeys } from "utils/storageHandlers";

export const getAuthorizationHeader = (): string =>
  `Bearer ${getLocalStorageValue(LocalStorageKeys.AccessToken)}`;

export const requestHeadersMiddleware: Middleware[] = [
  {
    pre: async (ctx: RequestContext): Promise<void> => {
      const state = store.getState();

      ctx.init.headers = {
        ...ctx.init.headers,
        Authorization: getAuthorizationHeader(),
        "X-App-Version": JSON.stringify(state.appVersion.appVersion),
      };

      return Promise.resolve();
    },
  },
];
