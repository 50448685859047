import { useAppSelector } from "app/store";
import Content from "components/layout/Content";
import { H3 } from "components/ui/Headings/Headings";
import Pagination from "components/ui/Pagination/Pagination";
import { FeatureState } from "models/FeatureState";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MyDebtCard from "./components/MyDebtCard";
import MyDebtsOrderBy, { DebtsOrderBy } from "./components/MyDebtsOrderBy";
import { debtsListActions } from "./debtsList.slice";

const itemsPerPage = 10;

const DebtsList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedOrderBy, setSelectedOrderBy] = useState<DebtsOrderBy>(
    DebtsOrderBy.PaymentDateAsc
  );

  const { debts, featureState } = useAppSelector(
    (state) => state.debts.debtsList
  );

  const debtsInOrder = useMemo(
    () =>
      debts.slice().sort((a, b) => {
        switch (selectedOrderBy) {
          case DebtsOrderBy.OutstandingBalanceAsc:
            return (a.outstandingBalance || 0) - (b.outstandingBalance || 0);
          case DebtsOrderBy.OutstandingBalanceDesc:
            return (b.outstandingBalance || 0) - (a.outstandingBalance || 0);
          case DebtsOrderBy.PaymentDateDesc:
            // Debts that has no instalment should appear at the end of the list

            if (!a.nextInstalment?.dueDate) {
              return 1;
            }

            if (!b.nextInstalment?.dueDate) {
              return -1;
            }

            return (
              b?.nextInstalment?.dueDate?.getTime() -
              a?.nextInstalment?.dueDate?.getTime()
            );

          case DebtsOrderBy.PaymentDateAsc:
          default:
            // Debts that has no instalment should appear at the end of the list

            if (!a.nextInstalment?.dueDate) {
              return 1;
            }

            if (!b.nextInstalment?.dueDate) {
              return -1;
            }

            return (
              a.nextInstalment?.dueDate?.getTime() -
              b.nextInstalment?.dueDate?.getTime()
            );
        }
      }),
    [debts, selectedOrderBy]
  );

  const debtsOnCurrentPage = useMemo(
    () =>
      debtsInOrder.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      ),
    [currentPage, debtsInOrder]
  );

  useEffect(() => {
    dispatch(debtsListActions.getDebts());
  }, [dispatch]);

  return (
    <Content>
      <div className="flex justify-between items-center">
        <H3>{t("debts.title")}</H3>
        <MyDebtsOrderBy
          handleValueChange={setSelectedOrderBy}
          selectedValue={selectedOrderBy}
        />
      </div>

      {featureState === FeatureState.Loading &&
      debtsOnCurrentPage.length === 0 ? (
        <MyDebtCard isLoading />
      ) : (
        <div className="space-y-4">
          {debtsOnCurrentPage.map((debt) => (
            <MyDebtCard {...debt} key={debt.debtId} />
          ))}
        </div>
      )}

      <div className="mt-8 flex justify-end">
        <Pagination
          pageSize={itemsPerPage}
          onChange={(newValue) => setCurrentPage(newValue)}
          total={debts.length}
        />
      </div>
    </Content>
  );
};

export default DebtsList;
