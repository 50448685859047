/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentStatus,
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './PaymentStatus';

/**
 * 
 * @export
 * @interface LinkPaymentStatusDto
 */
export interface LinkPaymentStatusDto {
    /**
     * 
     * @type {string}
     * @memberof LinkPaymentStatusDto
     */
    debtId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LinkPaymentStatusDto
     */
    uniqueId?: string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof LinkPaymentStatusDto
     */
    status?: PaymentStatus;
    /**
     * 
     * @type {boolean}
     * @memberof LinkPaymentStatusDto
     */
    isFinasticUser?: boolean;
}

export function LinkPaymentStatusDtoFromJSON(json: any): LinkPaymentStatusDto {
    return LinkPaymentStatusDtoFromJSONTyped(json, false);
}

export function LinkPaymentStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkPaymentStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'debtId': !exists(json, 'debtId') ? undefined : json['debtId'],
        'uniqueId': !exists(json, 'uniqueId') ? undefined : json['uniqueId'],
        'status': !exists(json, 'status') ? undefined : PaymentStatusFromJSON(json['status']),
        'isFinasticUser': !exists(json, 'isFinasticUser') ? undefined : json['isFinasticUser'],
    };
}

export function LinkPaymentStatusDtoToJSON(value?: LinkPaymentStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'debtId': value.debtId,
        'uniqueId': value.uniqueId,
        'status': PaymentStatusToJSON(value.status),
        'isFinasticUser': value.isFinasticUser,
    };
}

