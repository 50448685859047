import { InputHTMLAttributes } from "react";
import { Switch } from "@headlessui/react";
import { useField } from "formik";
import { mdiToggleSwitch, mdiToggleSwitchOff } from "@mdi/js";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";

const Toggle: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ name }) => {
  const [field, meta, helpers] = useField(name || "");

  const { setValue, setTouched } = helpers;

  const handleOnChange = (data: boolean) => {
    setValue(data);

    // need setTimeout to make touched after value was set (in the next render)
    setTimeout(() => {
      setTouched(true);
    }, 0);
  };

  const isChecked = field.value;

  return (
    <Switch {...field} checked={isChecked} onChange={handleOnChange}>
      <MaterialIcon
        className={`${
          isChecked ? "text-primary" : "text-disabled"
        } h-9 relative -top-1`}
        path={isChecked ? mdiToggleSwitch : mdiToggleSwitchOff}
      />
    </Switch>
  );
};

export default Toggle;
