/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryCode,
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';
import {
    InstalmentDto,
    InstalmentDtoFromJSON,
    InstalmentDtoFromJSONTyped,
    InstalmentDtoToJSON,
} from './InstalmentDto';

/**
 * 
 * @export
 * @interface DebtDetailsDto
 */
export interface DebtDetailsDto {
    /**
     * 
     * @type {CountryCode}
     * @memberof DebtDetailsDto
     */
    countryCode?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    debtId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    creditorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    currencyCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DebtDetailsDto
     */
    outstandingBalance?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DebtDetailsDto
     */
    hasInstalmentPlan?: boolean;
    /**
     * 
     * @type {InstalmentDto}
     * @memberof DebtDetailsDto
     */
    nextInstalment?: InstalmentDto;
    /**
     * 
     * @type {number}
     * @memberof DebtDetailsDto
     */
    nextPaymentAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DebtDetailsDto
     */
    isDueToBePaid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DebtDetailsDto
     */
    isPaymentInProgress?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DebtDetailsDto
     */
    canCreatePaymentPlan?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    originalCreditorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    currentCreditorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    caseId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    customerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    productDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    caseStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    signature?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DebtDetailsDto
     */
    entryKrd?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof DebtDetailsDto
     */
    disbursmentDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DebtDetailsDto
     */
    loanTermMonthCount?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof DebtDetailsDto
     */
    terminationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DebtDetailsDto
     */
    originalDebtAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DebtDetailsDto
     */
    principalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DebtDetailsDto
     */
    interestAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DebtDetailsDto
     */
    feeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DebtDetailsDto
     */
    paidAmount?: number | null;
    /**
     * 
     * @type {Array<InstalmentDto>}
     * @memberof DebtDetailsDto
     */
    remainingInstalments?: Array<InstalmentDto> | null;
    /**
     * 
     * @type {string}
     * @memberof DebtDetailsDto
     */
    payPointBarcode?: string | null;
}

export function DebtDetailsDtoFromJSON(json: any): DebtDetailsDto {
    return DebtDetailsDtoFromJSONTyped(json, false);
}

export function DebtDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebtDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': !exists(json, 'countryCode') ? undefined : CountryCodeFromJSON(json['countryCode']),
        'debtId': !exists(json, 'debtId') ? undefined : json['debtId'],
        'creditorName': !exists(json, 'creditorName') ? undefined : json['creditorName'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
        'outstandingBalance': !exists(json, 'outstandingBalance') ? undefined : json['outstandingBalance'],
        'hasInstalmentPlan': !exists(json, 'hasInstalmentPlan') ? undefined : json['hasInstalmentPlan'],
        'nextInstalment': !exists(json, 'nextInstalment') ? undefined : InstalmentDtoFromJSON(json['nextInstalment']),
        'nextPaymentAmount': !exists(json, 'nextPaymentAmount') ? undefined : json['nextPaymentAmount'],
        'isDueToBePaid': !exists(json, 'isDueToBePaid') ? undefined : json['isDueToBePaid'],
        'isPaymentInProgress': !exists(json, 'isPaymentInProgress') ? undefined : json['isPaymentInProgress'],
        'canCreatePaymentPlan': !exists(json, 'canCreatePaymentPlan') ? undefined : json['canCreatePaymentPlan'],
        'originalCreditorName': !exists(json, 'originalCreditorName') ? undefined : json['originalCreditorName'],
        'currentCreditorName': !exists(json, 'currentCreditorName') ? undefined : json['currentCreditorName'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'productDescription': !exists(json, 'productDescription') ? undefined : json['productDescription'],
        'caseStatus': !exists(json, 'caseStatus') ? undefined : json['caseStatus'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'entryKrd': !exists(json, 'entryKrd') ? undefined : json['entryKrd'],
        'disbursmentDate': !exists(json, 'disbursmentDate') ? undefined : (json['disbursmentDate'] === null ? null : new Date(json['disbursmentDate'])),
        'loanTermMonthCount': !exists(json, 'loanTermMonthCount') ? undefined : json['loanTermMonthCount'],
        'terminationDate': !exists(json, 'terminationDate') ? undefined : (json['terminationDate'] === null ? null : new Date(json['terminationDate'])),
        'originalDebtAmount': !exists(json, 'originalDebtAmount') ? undefined : json['originalDebtAmount'],
        'principalAmount': !exists(json, 'principalAmount') ? undefined : json['principalAmount'],
        'interestAmount': !exists(json, 'interestAmount') ? undefined : json['interestAmount'],
        'feeAmount': !exists(json, 'feeAmount') ? undefined : json['feeAmount'],
        'paidAmount': !exists(json, 'paidAmount') ? undefined : json['paidAmount'],
        'remainingInstalments': !exists(json, 'remainingInstalments') ? undefined : (json['remainingInstalments'] === null ? null : (json['remainingInstalments'] as Array<any>).map(InstalmentDtoFromJSON)),
        'payPointBarcode': !exists(json, 'payPointBarcode') ? undefined : json['payPointBarcode'],
    };
}

export function DebtDetailsDtoToJSON(value?: DebtDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': CountryCodeToJSON(value.countryCode),
        'debtId': value.debtId,
        'creditorName': value.creditorName,
        'currencyCode': value.currencyCode,
        'outstandingBalance': value.outstandingBalance,
        'hasInstalmentPlan': value.hasInstalmentPlan,
        'nextInstalment': InstalmentDtoToJSON(value.nextInstalment),
        'nextPaymentAmount': value.nextPaymentAmount,
        'isDueToBePaid': value.isDueToBePaid,
        'isPaymentInProgress': value.isPaymentInProgress,
        'canCreatePaymentPlan': value.canCreatePaymentPlan,
        'originalCreditorName': value.originalCreditorName,
        'currentCreditorName': value.currentCreditorName,
        'caseId': value.caseId,
        'customerId': value.customerId,
        'productDescription': value.productDescription,
        'caseStatus': value.caseStatus,
        'signature': value.signature,
        'entryKrd': value.entryKrd,
        'disbursmentDate': value.disbursmentDate === undefined ? undefined : (value.disbursmentDate === null ? null : value.disbursmentDate.toISOString()),
        'loanTermMonthCount': value.loanTermMonthCount,
        'terminationDate': value.terminationDate === undefined ? undefined : (value.terminationDate === null ? null : value.terminationDate.toISOString()),
        'originalDebtAmount': value.originalDebtAmount,
        'principalAmount': value.principalAmount,
        'interestAmount': value.interestAmount,
        'feeAmount': value.feeAmount,
        'paidAmount': value.paidAmount,
        'remainingInstalments': value.remainingInstalments === undefined ? undefined : (value.remainingInstalments === null ? null : (value.remainingInstalments as Array<any>).map(InstalmentDtoToJSON)),
        'payPointBarcode': value.payPointBarcode,
    };
}

