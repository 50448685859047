import Button from "components/ui/Button/Button";
import { FormikProps } from "formik";

type CardFooterProps<T> = {
  formikProps: FormikProps<T>;
  cancelButtonText: string;
  nextButtonText: string;
  handleCardCancelClick: () => void;
};

function CardFooter<T>({
  formikProps,
  cancelButtonText,
  nextButtonText,
  handleCardCancelClick,
}: CardFooterProps<T>): JSX.Element {
  const handleNextClick = () => {
    formikProps.submitForm();
  };

  return (
    <>
      <div className="flex justify-between flex-wrap -my-2">
        <div className="my-2 min-w-cardButton">
          <Button variant="outline" onClick={handleCardCancelClick} block>
            {cancelButtonText}
          </Button>
        </div>
        <div className="my-2 min-w-cardButton">
          <Button
            disabled={
              !formikProps.isValid ||
              (!Object.prototype.hasOwnProperty.call(
                formikProps.touched,
                "isPushMarketingAllowed"
              ) &&
                !Object.prototype.hasOwnProperty.call(
                  formikProps.touched,
                  "isSmsMarketingAllowed"
                ) &&
                !Object.prototype.hasOwnProperty.call(
                  formikProps.touched,
                  "isEmailMarketingAllowed"
                ))
            }
            isLoading={formikProps.isSubmitting}
            onClick={handleNextClick}
            block
          >
            {nextButtonText}
          </Button>
        </div>
      </div>
    </>
  );
}

export default CardFooter;
