import React, { useMemo } from "react";
import DisplayData from "./DisplayData";
import { useTranslation } from "react-i18next";
import Button from "components/ui/Button/Button";
import { mdiUpload } from "@mdi/js";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import Alert from "components/ui/Alert/Alert";
import { CountryCode, DebtSummaryDto } from "api/generated/finastic";
import { translateBooleanValue } from "utils/common";
import Skeleton from "components/ui/Skeleton/Skeleton";
import useDateFormatter, { DateFormat } from "hooks/useDateFormatter";
import { Link } from "react-router-dom";
import DebtCardBg from "features/debts/components/DebtCardBg";
import DisplayBigSum from "features/debts/components/DisplayBigSum";
import DisplayNullableData from "components/DisplayNullableData";
import { TabKey } from "features/debts/DebtDetails/components/DebtDetailsCard";
import DisplayNumberFormat from "components/ui/DisplayNumberFormat/DisplayNumberFormat";
import useLanguageCodeCountryCodeLogics from "hooks/useLanguageCodeCountryCodeLogics";

interface MyDebtCardProps extends DebtSummaryDto {
  isLoading?: boolean;
}

const MyDebtCard: React.FC<MyDebtCardProps> = ({
  creditorName,
  hasInstalmentPlan,
  outstandingBalance,
  currencyCode,
  isLoading,
  isDueToBePaid,
  isPaymentInProgress,
  nextInstalment,
  nextPaymentAmount,
  debtId,
  canCreatePaymentPlan,
  countryCode,
}) => {
  const { t } = useTranslation();
  const { formatter } = useDateFormatter();

  const { countryCodeAccountIdentifierLabelMap } =
    useLanguageCodeCountryCodeLogics();

  const nextPaymentDiffersFromNextInstalment = useMemo(
    () => nextInstalment?.amount !== nextPaymentAmount,
    [nextInstalment?.amount, nextPaymentAmount]
  );

  return (
    <div className="bg-debtCard rounded-xl shadow relative">
      <DebtCardBg />
      <div className="relative">
        {isLoading ? (
          <div className="p-4">
            <Skeleton />
          </div>
        ) : (
          <>
            <div className="flex items-end p-4">
              <div className="mr-4">
                <MaterialIcon
                  className="h-5 text-label mb-1"
                  path={mdiUpload}
                />
              </div>
              <div className="flex-1">
                <div className="space-y-4">
                  <div className="grid gap-4 md:grid-cols-3 grid-cols-1">
                    <div className="col-span-2">
                      <DisplayData label={t("debt.creditor")}>
                        <DisplayNullableData value={creditorName} isOnDarkBg />
                      </DisplayData>
                    </div>
                    <DisplayData
                      label={
                        countryCodeAccountIdentifierLabelMap[
                          countryCode || CountryCode.Invalid
                        ]
                      }
                    >
                      <DisplayNullableData value={debtId} isOnDarkBg />
                    </DisplayData>
                  </div>

                  <div className="grid gap-4 md:grid-cols-3 grid-cols-1">
                    {hasInstalmentPlan && (
                      <DisplayData label={t("debt.outstandingBalance")}>
                        <DisplayNullableData
                          value={outstandingBalance}
                          isOnDarkBg
                        >
                          <DisplayNumberFormat value={outstandingBalance} />
                          <span className="inline-block ml-2">
                            {currencyCode}
                          </span>
                        </DisplayNullableData>
                      </DisplayData>
                    )}
                    {hasInstalmentPlan && (
                      <DisplayData label={t("debt.nextPaymentDate")}>
                        <DisplayNullableData
                          value={nextInstalment?.dueDate}
                          isOnDarkBg
                        >
                          {nextInstalment?.dueDate && (
                            <span>
                              {formatter(
                                nextInstalment.dueDate,
                                DateFormat.Date
                              )}
                            </span>
                          )}
                        </DisplayNullableData>
                      </DisplayData>
                    )}
                    <DisplayData label={t("debt.instalmentPlan")}>
                      <DisplayNullableData value={hasInstalmentPlan} isOnDarkBg>
                        {translateBooleanValue(hasInstalmentPlan)}
                      </DisplayNullableData>
                    </DisplayData>
                    {nextPaymentDiffersFromNextInstalment && hasInstalmentPlan && (
                      <DisplayData label={t("debt.nextInstalment")}>
                        <DisplayNullableData
                          value={nextInstalment?.amount}
                          isOnDarkBg
                        >
                          <DisplayNumberFormat value={nextInstalment?.amount} />
                          <span className="inline-block ml-2">
                            {currencyCode}
                          </span>
                        </DisplayNullableData>
                      </DisplayData>
                    )}
                  </div>
                </div>
                <div className="my-4 border border-debtCard" />
                <div className="flex justify-between items-center flex-wrap">
                  {!hasInstalmentPlan ? (
                    <div className="mb-3 md:mb-0">
                      <DisplayData label={t("debt.outstandingBalance")}>
                        <DisplayNullableData
                          value={outstandingBalance}
                          isOnDarkBg
                        >
                          {typeof outstandingBalance === "number" && (
                            <DisplayBigSum
                              value={outstandingBalance}
                              currency={currencyCode}
                            />
                          )}
                        </DisplayNullableData>
                      </DisplayData>
                    </div>
                  ) : nextPaymentDiffersFromNextInstalment ? (
                    <div className="mb-3 md:mb-0">
                      <DisplayData label={t("debt.nextPayment")}>
                        <DisplayNullableData
                          value={nextPaymentAmount}
                          isOnDarkBg
                        >
                          {typeof nextPaymentAmount === "number" && (
                            <DisplayBigSum
                              value={nextPaymentAmount}
                              currency={currencyCode}
                            />
                          )}
                        </DisplayNullableData>
                      </DisplayData>
                    </div>
                  ) : (
                    <div className="mb-3 md:mb-0">
                      <DisplayData label={t("debt.nextInstalment")}>
                        <DisplayNullableData
                          value={nextInstalment?.amount}
                          isOnDarkBg
                        >
                          {typeof nextInstalment?.amount === "number" && (
                            <DisplayBigSum
                              value={nextInstalment.amount}
                              currency={currencyCode}
                            />
                          )}
                        </DisplayNullableData>
                      </DisplayData>
                    </div>
                  )}
                  <div className="flex flex-wrap -mx-3 -my-2">
                    {!isPaymentInProgress && !!nextPaymentAmount && (
                      <div className="min-w-debtCardButton mx-3 my-2">
                        <Button
                          as={Link}
                          to={`/debts/${debtId}/repayment`}
                          variant="darkCard"
                          block
                          size="medium"
                        >
                          {t("debt.payButtonText")}
                        </Button>
                      </div>
                    )}
                    <div className="min-w-debtCardButton mx-3 my-2">
                      <Button
                        as={Link}
                        to={`/debts/${debtId}/details${
                          canCreatePaymentPlan ? `#${TabKey.Schedule}` : ""
                        }`}
                        variant="darkCard"
                        block
                        size="medium"
                      >
                        {t("debt.detailsButtonText")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-2 pb-2">
              {canCreatePaymentPlan && (
                <Alert type="infoAlt" size="small">
                  {t("debt.canCreatePaymentPlan")}
                </Alert>
              )}
              {isPaymentInProgress && (
                <Alert type="infoAlt" size="small">
                  {t("debt.processingPayment")}
                </Alert>
              )}
              {!isPaymentInProgress && isDueToBePaid && (
                <Alert type="errorAlt" size="small">
                  {t("debt.paymentIsDue")}
                </Alert>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyDebtCard;
