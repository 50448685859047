import Button from "components/ui/Button/Button";
import { H6 } from "components/ui/Headings/Headings";
import { Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { StepWizardChildProps } from "react-step-wizard";
import { debtPaymentPlanActions } from "../DebtPaymentPlan.slice";
import StepNavigation from "./StepNavigation";
import { useAppSelector } from "app/store";
import Modal from "components/ui/Modal/Modal";
import InstalmentDateData from "./InstalmentDateData";
import InstalmentNumberData from "./InstalmentNumberData";
import Checkbox from "components/forms/Checkbox";
import Alert from "components/ui/Alert/Alert";
import { mdiInformation } from "@mdi/js";
import * as Yup from "yup";
import { FeatureState } from "models/FeatureState";
import { debtDetailsActions } from "features/debts/DebtDetails/debtDetails.slice";
import InputNumber from "components/forms/InputNumber";
import useLanguageCodeCountryCodeLogics from "hooks/useLanguageCodeCountryCodeLogics";
import { CountryCode } from "api/generated/finastic";

export interface Step3FormData {
  isTacAccepted: boolean;
  outstandingBalance?: number;
}

const Step3: React.FC<Partial<StepWizardChildProps>> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    step1FormData,
    step2FormData,
    paymentPlanOptionsData,
    createPlaymentPlanState,
  } = useAppSelector((state) => state.debts.debtPaymentPlan);
  const debt = useAppSelector((state) => state.debts.debtDetails.debt);
  const { user } = useAppSelector((state) => state.auth);
  const formRef = useRef<FormikProps<Step3FormData>>(null);
  const { countryCodePaymentAgreementUrlMap } =
    useLanguageCodeCountryCodeLogics();

  const termsLink =
    countryCodePaymentAgreementUrlMap[debt?.countryCode || CountryCode.Invalid];

  const refreshDebtDataAndCloseWizard = useCallback(() => {
    if (debt?.debtId) {
      dispatch(debtDetailsActions.getDebt(debt.debtId));
    }

    dispatch(debtPaymentPlanActions.setShowWizard(false));
  }, [debt?.debtId, dispatch]);

  useEffect(() => {
    formRef.current?.setFieldValue(
      "outstandingBalance",
      debt?.outstandingBalance
    );
  }, [debt?.outstandingBalance]);

  return (
    <>
      <div className="md:px-8 px-4">
        <H6>{t("debt.createPaymentPlanWizard.step3Title")}</H6>
      </div>
      {step1FormData && step2FormData && (
        <Formik<Step3FormData>
          initialValues={{
            isTacAccepted: false,
            outstandingBalance: debt?.outstandingBalance,
          }}
          validationSchema={Yup.object({
            isTacAccepted: Yup.bool().oneOf(
              [true],
              t("validationErrors.requiredCheckbox")
            ),
          })}
          onSubmit={(values, actions) => {
            dispatch(debtPaymentPlanActions.createPlaymentPlan(values));
          }}
          innerRef={formRef}
        >
          {(formikProps) => (
            <>
              <InputNumber name="outstandingBalance" hidden />
              <div className="md:px-8 px-4">
                <div className="my-6">
                  <InstalmentNumberData
                    instalmentCount={step1FormData.instalmentCount}
                    currencyCode={debt?.currencyCode}
                    paymentPlanOptions={
                      paymentPlanOptionsData?.paymentPlanOptions
                    }
                  />
                </div>
                <div className="mb-6">
                  <InstalmentDateData
                    firstInstalmentDueDate={
                      step2FormData.firstInstalmentDueDate
                    }
                  />
                </div>

                <Checkbox name="isTacAccepted" required noIndent>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("debt.createPaymentPlanWizard.isTacAccepted", {
                        termsLink,
                      }),
                    }}
                  />
                </Checkbox>

                <div className="my-6">
                  <Alert icon={mdiInformation}>
                    {t("debt.createPaymentPlanWizard.infoAlert")}
                  </Alert>
                </div>
              </div>
              <StepNavigation
                firstButton={
                  <Button
                    variant="outline"
                    block
                    onClick={previousStep}
                    type="button"
                    disabled={createPlaymentPlanState === FeatureState.Loading}
                  >
                    {t("common.back")}
                  </Button>
                }
                secondButton={
                  <Button
                    block
                    type="button"
                    onClick={() => formikProps.submitForm()}
                    isLoading={createPlaymentPlanState === FeatureState.Loading}
                  >
                    {t("common.confirm")}
                  </Button>
                }
              />
            </>
          )}
        </Formik>
      )}

      {user && (
        <Modal
          isOpen={createPlaymentPlanState === FeatureState.Success}
          title={t("debt.createPaymentPlanWizard.successModal.title")}
          description={t(
            "debt.createPaymentPlanWizard.successModal.description",
            { emailAddress: user.emailAddress }
          )}
          okText={t(
            "debt.createPaymentPlanWizard.successModal.viewPaymentPlanButton"
          )}
          type="alert"
          alertType="success"
          onOk={refreshDebtDataAndCloseWizard}
        />
      )}
    </>
  );
};

export default Step3;
