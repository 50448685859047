import React, { useMemo } from "react";
import { Menu } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { getEnumValues } from "utils/common";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import clsx from "clsx";
import enFlag from "assets/images/flags/gb.svg";
import roFlag from "assets/images/flags/ro.svg";
import plFlag from "assets/images/flags/pl.svg";
import { mdiCheck } from "@mdi/js";
import { LanguageCode } from "api/generated/finastic";
import useLanguageSwitcher from "hooks/useLanguageSwitcher";

type LanguageFlags = {
  [key in LanguageCode]: string;
};

// PL flag is from https://catamphetamine.github.io/country-flag-icons/3x2/
const languageFlags: LanguageFlags = {
  [LanguageCode.Invalid]: enFlag,
  [LanguageCode.En]: enFlag,
  [LanguageCode.Ro]: roFlag,
  [LanguageCode.Pl]: plFlag,
};

const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { changeLanguage } = useLanguageSwitcher();

  const currentLanguage = i18n.language as LanguageCode;

  const languageTextsMap = useMemo<
    {
      [key in LanguageCode]: string;
    }
  >(
    () => ({
      [LanguageCode.Invalid]: t("languageSwitcher.EN"),
      [LanguageCode.En]: t("languageSwitcher.EN"),
      [LanguageCode.Ro]: t("languageSwitcher.RO"),
      [LanguageCode.Pl]: t("languageSwitcher.PL"),
    }),
    [t]
  );

  return (
    <Menu>
      <div className="relative h-8 w-8 ml-8">
        <Menu.Button className="outline-none">
          <img
            className="h-8 w-8 object-cover rounded-full border-4 border-white"
            src={languageFlags[currentLanguage]}
            alt=""
          />
        </Menu.Button>

        <Menu.Items className="absolute -right-2 shadow bg-white rounded-xl border border-main dropdown-menu outline-none">
          <div className="divide-y divide-main">
            {getEnumValues(LanguageCode).map((language) => (
              <Menu.Item
                key={language}
                onClick={() => changeLanguage(language as LanguageCode)}
              >
                {({ active }) => (
                  <div className="p-4 flex cursor-pointer whitespace-nowrap items-center outline-none">
                    <MaterialIcon
                      path={mdiCheck}
                      className={clsx("h-3 text-primary pr-4", {
                        "text-transparent": language !== currentLanguage,
                      })}
                    />
                    {languageTextsMap[language]}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </div>
    </Menu>
  );
};

export default LanguageSwitcher;
