import Skeleton from "components/ui/Skeleton/Skeleton";
import DebtCardBg from "features/debts/components/DebtCardBg";
import React from "react";

const LoadingDebtCardSkeleton: React.FC = () => {
  return (
    <div className="bg-debtCard rounded-xl shadow relative">
      <DebtCardBg />
      <div className="relative">
        <div className="p-4">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};

export default LoadingDebtCardSkeleton;
