import { SubRouteLayout } from "components/layout/SubRouteLayout";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { PublicRoute } from "router/PublicRoute";
import { RouteItem, RouteItemTypes } from "router/useRoutes";
import EmailValidationRouteSwitch from "./EmailValidation/EmailValidationRouteSwitch";
import ForgotPasswordHome from "./Home/ForgotPasswordHome";
import SMSPasscodeRouteSwitch from "./SMSPasscode/SMSPasscodeRouteSwitch";

const ForgotPasswordRouteSwitch: React.FC = () => {
  const { path: currentPath } = useRouteMatch();
  const { t } = useTranslation();

  const routes = useMemo<RouteItem[]>(
    () => [
      {
        path: "",
        title: t("pageTitle.forgotPassword.home"),
        component: ForgotPasswordHome,
        type: RouteItemTypes.Public,
        exact: true,
      },
      {
        path: "/sms-passcode",
        title: t("pageTitle.forgotPassword.smsPasscode"),
        component: SMSPasscodeRouteSwitch,
        type: RouteItemTypes.Public,
        restricted: true,
      },
      {
        path: "/email-validation",
        title: t("pageTitle.forgotPassword.emailValidation"),
        component: EmailValidationRouteSwitch,
        type: RouteItemTypes.Public,
      },
    ],
    [t]
  );

  return (
    <Switch>
      {routes.map(({ path, ...rest }) => (
        <PublicRoute
          {...rest}
          key={path}
          path={`${currentPath}${path}`}
          layout={SubRouteLayout}
        />
      ))}
      <Redirect to={`${currentPath}${routes[0].path}`} />
    </Switch>
  );
};

export default ForgotPasswordRouteSwitch;
