import { useAppSelector } from "app/store";
import Content from "components/layout/Content";
import Breadcrumbs, {
  BreadcrumbsItem,
} from "components/ui/Breadcrumbs/Breadcrumbs";
import { FeatureState } from "models/FeatureState";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router";
import DebtDetailsCard from "./components/DebtDetailsCard";
import { debtDetailsActions } from "./debtDetails.slice";

interface DebtDetailsProps {
  id: string;
}

const DebtDetails: React.FC = () => {
  const { id } = useParams<DebtDetailsProps>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const breadcrumbsItems: BreadcrumbsItem[] = [
    {
      label: t("debts.title"),
      link: "/",
    },
  ];

  const { featureState, debt } = useAppSelector(
    (state) => state.debts.debtDetails
  );

  useEffect(() => {
    dispatch(debtDetailsActions.getDebt(id));

    return () => {
      dispatch(debtDetailsActions.reset());
    };
  }, [dispatch, id]);

  if (featureState === FeatureState.Error) {
    return <Redirect to="/404" />;
  }

  return (
    <Content>
      <Breadcrumbs items={breadcrumbsItems} />
      <DebtDetailsCard
        {...debt}
        isLoading={featureState === FeatureState.Loading && !debt}
      />
    </Content>
  );
};

export default DebtDetails;
