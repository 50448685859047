import { useAppSelector } from "app/store";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import RegistrationTimedOut from "../components/RegistrationTimedOut";
import Card from "components/ui/Card/Card";
import { H3, H6 } from "components/ui/Headings/Headings";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CardFooter from "../components/CardFooter";
import { setPasswordActions } from "./setPassword.slice";
import InputFormGroup from "components/forms/InputFormGroup";
import Modal from "components/ui/Modal/Modal";
import { useHistory } from "react-router";
import Alert from "components/ui/Alert/Alert";
import { FeatureState } from "models/FeatureState";
import useFormValidations from "hooks/useFormValidations";
import Content from "components/layout/Content";
import Input from "components/forms/Input";
import useEventLogger from "hooks/useEventLogger";

export interface SetPasswordFormValues {
  password: string;
  confirmPassword: string;
  languageCode: string;
}

const SetPassword: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef<FormikProps<SetPasswordFormValues>>(null);

  const { formValues: previousStepFormValues } = useAppSelector(
    (state) => state.registration.oneTimePasscode
  );

  const { featureState } = useAppSelector(
    (state) => state.registration.setPassword
  );

  const feedbackModalIsOpen = useMemo(
    () => featureState === FeatureState.Success,
    [featureState]
  );

  const { passwordValidation, confirmPasswordValidation } =
    useFormValidations();

  const { trackAction } = useEventLogger("RegistrationSetPassword");

  const handleSubmit: (
    values: SetPasswordFormValues,
    formikHelpers: FormikHelpers<SetPasswordFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    dispatch(setPasswordActions.setFormValues(values));

    trackAction({});

    await dispatch(setPasswordActions.register(values));
  };

  useEffect(() => {
    dispatch(setPasswordActions.reset());
  }, [dispatch]);

  useEffect(() => {
    formRef.current?.setFieldValue("languageCode", i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return previousStepFormValues ? (
    <>
      <Content>
        <Formik<SetPasswordFormValues>
          initialValues={{
            password: "",
            confirmPassword: "",
            languageCode: i18n.language,
          }}
          validationSchema={Yup.object({
            password: passwordValidation,
            confirmPassword: confirmPasswordValidation,
          })}
          onSubmit={handleSubmit}
          validateOnMount
          innerRef={formRef}
        >
          {(formikProps) => (
            <Card
              footer={
                <CardFooter<SetPasswordFormValues>
                  formikProps={formikProps}
                  cancelButtonText={t(
                    "registration.setPassword.cancelButtonText"
                  )}
                  nextButtonText={t("registration.setPassword.nextButtonText")}
                />
              }
              isDense
            >
              <H6>{t("registration.step3")}</H6>
              <H3>{t("registration.setPassword.title")}</H3>

              <Alert type="success">
                {t("registration.setPassword.passcodeSucessfullyValidated")}
              </Alert>

              <p className="my-4">
                {t("registration.setPassword.description")}
              </p>

              <Form noValidate>
                <Input name="languageCode" hidden />

                <InputFormGroup
                  name="password"
                  type="password"
                  label={t("registration.setPassword.password")}
                  placeholder={t(
                    "registration.setPassword.passwordPlaceholder"
                  )}
                  required
                />
                <InputFormGroup
                  name="confirmPassword"
                  type="password"
                  label={t("registration.setPassword.confirmPassword")}
                  placeholder={t(
                    "registration.setPassword.confirmPasswordPlaceholder"
                  )}
                  required
                />
              </Form>
            </Card>
          )}
        </Formik>
      </Content>
      <Modal
        isOpen={feedbackModalIsOpen}
        title={t("registration.setPassword.successModal.title")}
        description={t("registration.setPassword.successModal.description")}
        okText={t("registration.setPassword.successModal.logInButtonText")}
        type="alert"
        alertType="success"
        onOk={() => history.push("/login")}
      />
    </>
  ) : (
    <RegistrationTimedOut />
  );
};

export default SetPassword;
