import { useAppSelector } from "app/store";
import Card from "components/ui/Card/Card";
import { H4 } from "components/ui/Headings/Headings";
import DisplayData from "features/debts/DebtsList/components/DisplayData";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { translateEnumValue } from "utils/common";
import {
  mdiAsterisk,
  mdiCheckBold,
  mdiClose,
  mdiInformationOutline,
  mdiPencil,
} from "@mdi/js";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import Modal from "components/ui/Modal/Modal";
import Button from "components/ui/Button/Button";
import clsx from "clsx";
import { Link } from "react-router-dom";
import DeleteAccountButton from "./components/DeleteAccountButton";
import useLanguageCodeCountryCodeLogics from "hooks/useLanguageCodeCountryCodeLogics";
import { CountryCode } from "api/generated/finastic";

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const { personIdentifierLabelMap } = useLanguageCodeCountryCodeLogics();

  const { user } = useAppSelector((state) => state.auth);

  return (
    <div className="max-w-3xl mx-auto">
      <Card noPadding>
        <div className="grid grid-cols-12">
          <div className="lg:col-span-7 md:col-span-6 col-span-full xs:p-8 p-4">
            <H4>
              <span className="flex justify-between items-center">
                {t("settings.profileData")}
                <button onClick={() => setIsInfoModalOpen(true)}>
                  <MaterialIcon
                    path={mdiInformationOutline}
                    className="h-5 text-primary"
                  />
                </button>
              </span>
            </H4>
            <div className="space-y-4">
              <DisplayData label={t("settings.name")} darkText>
                {user?.firstName} {user?.lastName}
              </DisplayData>
              <DisplayData label={t("settings.emailAddress")} darkText>
                <span className="break-all">{user?.emailAddress}</span>
              </DisplayData>
              <DisplayData label={t("settings.phoneNumber")} darkText>
                {user?.phoneNumber}
              </DisplayData>
              <hr className="border-main" />
              <DisplayData label={t("settings.country")} darkText>
                {translateEnumValue(user?.countryCode, "CountryCode")}
              </DisplayData>
              <DisplayData
                label={
                  personIdentifierLabelMap[
                    user?.countryCode || CountryCode.Invalid
                  ]
                }
                darkText
              >
                {user?.identificationNumber}
              </DisplayData>
            </div>
          </div>
          <div
            className={clsx(
              "lg:col-span-5 md:col-span-6 col-span-full xs:p-8 p-4 lg:border-l lg:border-t-0 border-t border-main",
              "bg-darkerCard lg:rounded-tr-xl lg:rounded-br-xl rounded-bl-xl md:rounded-bl-none",
              "rounded-br-xl md:rounded-br-none"
            )}
          >
            <H4>{t("settings.profileSettings")}</H4>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <DisplayData
                  label={t("settings.preferredCommunication")}
                  darkText
                >
                  {translateEnumValue(
                    user?.preferences?.communicationChannel,
                    "CommunicationChannel"
                  )}
                </DisplayData>
                <div className="pl-2">
                  <Button
                    variant="outline"
                    iconButton
                    as={Link}
                    to="/settings/preferred-communication"
                  >
                    <MaterialIcon path={mdiPencil} className="h-4" />
                  </Button>
                </div>
              </div>
              <hr className="border-main" />
              <div className="flex justify-between items-center">
                <DisplayData label={t("settings.password")} darkText>
                  <div className="flex pt-1">
                    <MaterialIcon path={mdiAsterisk} className="h-3" />
                    <MaterialIcon path={mdiAsterisk} className="h-3" />
                    <MaterialIcon path={mdiAsterisk} className="h-3" />
                    <MaterialIcon path={mdiAsterisk} className="h-3" />
                    <MaterialIcon path={mdiAsterisk} className="h-3" />
                    <MaterialIcon path={mdiAsterisk} className="h-3" />
                    <MaterialIcon path={mdiAsterisk} className="h-3" />
                    <MaterialIcon path={mdiAsterisk} className="h-3" />
                  </div>
                </DisplayData>
                <div className="pl-2">
                  <Button
                    variant="outline"
                    iconButton
                    as={Link}
                    to="/settings/change-password"
                  >
                    <MaterialIcon path={mdiPencil} className="h-4" />
                  </Button>
                </div>
              </div>
              <hr className="border-main" />
              <div className="flex justify-between items-start">
                <DisplayData label={t("settings.privacyConsent")} darkText>
                  <div
                    className={clsx("flex py-2 items-center", {
                      "text-dataNotAvailable":
                        !user?.preferences?.isPushMarketingAllowed,
                    })}
                  >
                    <MaterialIcon
                      path={
                        user?.preferences?.isPushMarketingAllowed
                          ? mdiCheckBold
                          : mdiClose
                      }
                      className="h-4 pr-2 -mt-2px"
                    />
                    {t("settings.pushMarketing")}
                  </div>
                  <div
                    className={clsx("flex py-2 items-center", {
                      "text-dataNotAvailable":
                        !user?.preferences?.isSmsMarketingAllowed,
                    })}
                  >
                    <MaterialIcon
                      path={
                        user?.preferences?.isSmsMarketingAllowed
                          ? mdiCheckBold
                          : mdiClose
                      }
                      className="h-4 pr-2 -mt-2px"
                    />
                    {t("settings.smsMarketing")}
                  </div>
                  <div
                    className={clsx("flex py-2 items-center", {
                      "text-dataNotAvailable":
                        !user?.preferences?.isEmailMarketingAllowed,
                    })}
                  >
                    <MaterialIcon
                      path={
                        user?.preferences?.isEmailMarketingAllowed
                          ? mdiCheckBold
                          : mdiClose
                      }
                      className="h-4 pr-2 -mt-2px"
                    />
                    {t("settings.emailMarketing")}
                  </div>
                </DisplayData>
                <div className="pl-2 pt-4">
                  <Button
                    variant="outline"
                    iconButton
                    as={Link}
                    to="/settings/privacy-consent"
                  >
                    <MaterialIcon path={mdiPencil} className="h-4" />
                  </Button>
                </div>
              </div>
              <hr className="border-main" />
              <div className="flex justify-between items-center">
                <DisplayData label={t("settings.manageProfile")} darkText>
                  {t("settings.deleteAccount")}
                </DisplayData>
                <div className="pl-2">
                  <DeleteAccountButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Modal
        isOpen={isInfoModalOpen}
        okText={t("settings.infoModal.closeButtonText")}
        type="alert"
        title={t("settings.infoModal.title")}
        description={t("settings.infoModal.description")}
        onOk={() => setIsInfoModalOpen(false)}
      />
    </div>
  );
};

export default Settings;
