import { useCallback } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

export enum DateFormat {
  Date = "YYYY-MM-DD",
  DateTime = "YYYY-MM-DD HH:mm",
  DayOfMonthOrdinal = "Do",
  DayOfMonth = "D",
}

interface UseDateFormatter {
  formatter: (
    value: string | number | Date | dayjs.Dayjs | null | undefined,
    format: DateFormat
  ) => string;
}

const useDateFormatter = (): UseDateFormatter => {
  const formatter = useCallback(
    (
      value: string | number | Date | dayjs.Dayjs | null | undefined,
      format: DateFormat
    ) => dayjs(value).format(format),
    []
  );

  return { formatter };
};

export default useDateFormatter;
