import Container from "./Container";
import Footer from "./Footer/Footer";
import Header from "./Header";
import Main from "./Main";

interface DefaultLayoutProps {
  children: React.ReactNode;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <Main sidebarIsToggled>
        <Container>{children}</Container>
      </Main>
      <Footer />
    </div>
  );
};
