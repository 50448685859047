import Content from "components/layout/Content";
import Alert from "components/ui/Alert/Alert";
import Card from "components/ui/Card/Card";
import { H3 } from "components/ui/Headings/Headings";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mdiInformation } from "@mdi/js";
import RouterPrompt from "components/RouterPrompt";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/store";
import { paymentActions } from "./payment.slice";
import { FeatureState } from "models/FeatureState";
import useQuery from "hooks/useQuery";
import Button from "components/ui/Button/Button";
import { Link } from "react-router-dom";
import ContentLoadingSpinner from "components/ui/LoadingSpinner/ContentLoadingSpinner";

const Payment: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const paymentId = query.get("paymentId");
  const [remainintTryCount, setRemainintTryCount] = useState(24); // 24 * 5 sec = 120 sec = 2 min

  const { featureState } = useAppSelector((state) => state.payment);

  const needToPoll = remainintTryCount > 0;

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (featureState === FeatureState.Loading && paymentId && needToPoll) {
      dispatch(paymentActions.getPaymentStatus(paymentId));

      interval = setInterval(() => {
        dispatch(paymentActions.getPaymentStatus(paymentId));
        setRemainintTryCount((oldValue) => oldValue - 1);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [dispatch, featureState, needToPoll, paymentId]);

  return (
    <Content>
      <RouterPrompt
        when={
          featureState === FeatureState.Loading && !!paymentId && needToPoll
        }
        title={t("payment.leaveModal.title")}
        cancelText={t("payment.leaveModal.cancelText")}
        okText={t("payment.leaveModal.okText")}
        onOK={() => true}
        onCancel={() => false}
      />
      <Card
        footer={
          (featureState !== FeatureState.Loading ||
            !paymentId ||
            !needToPoll) && (
            <div className="flex justify-end">
              <div className="my-2 min-w-cardButton">
                <Button as={Link} to="/" block>
                  {t("payment.backToMyDebtsButtonText")}
                </Button>
              </div>
            </div>
          )
        }
      >
        <div className="md:w-80 mx-auto">
          <H3>{t("payment.title")}</H3>

          {!needToPoll ? (
            <Alert type="error">{t("payment.paymentStatusTimeout")}</Alert>
          ) : !paymentId ? (
            <Alert type="error">{t("payment.noOrder")}</Alert>
          ) : featureState === FeatureState.Loading ? (
            <>
              <Alert icon={mdiInformation}>
                {t("payment.paymentInProgress")}
              </Alert>
              <ContentLoadingSpinner />
            </>
          ) : featureState === FeatureState.Success ? (
            <Alert type="success">{t("payment.paymentSuccessful")}</Alert>
          ) : (
            <Alert type="error">{t("payment.paymentFailed")}</Alert>
          )}
        </div>
      </Card>
    </Content>
  );
};

export default Payment;
