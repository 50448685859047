/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LanguageCode,
    LanguageCodeFromJSON,
    LanguageCodeFromJSONTyped,
    LanguageCodeToJSON,
} from './LanguageCode';

/**
 * 
 * @export
 * @interface StartLinkPaymentCommand
 */
export interface StartLinkPaymentCommand {
    /**
     * 
     * @type {string}
     * @memberof StartLinkPaymentCommand
     */
    backendCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartLinkPaymentCommand
     */
    debtId: string;
    /**
     * 
     * @type {number}
     * @memberof StartLinkPaymentCommand
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof StartLinkPaymentCommand
     */
    currencyCode: string;
    /**
     * 
     * @type {LanguageCode}
     * @memberof StartLinkPaymentCommand
     */
    languageCode: LanguageCode;
}

export function StartLinkPaymentCommandFromJSON(json: any): StartLinkPaymentCommand {
    return StartLinkPaymentCommandFromJSONTyped(json, false);
}

export function StartLinkPaymentCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartLinkPaymentCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backendCode': !exists(json, 'backendCode') ? undefined : json['backendCode'],
        'debtId': json['debtId'],
        'amount': json['amount'],
        'currencyCode': json['currencyCode'],
        'languageCode': LanguageCodeFromJSON(json['languageCode']),
    };
}

export function StartLinkPaymentCommandToJSON(value?: StartLinkPaymentCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backendCode': value.backendCode,
        'debtId': value.debtId,
        'amount': value.amount,
        'currencyCode': value.currencyCode,
        'languageCode': LanguageCodeToJSON(value.languageCode),
    };
}

