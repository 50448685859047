import React, { useEffect, useMemo } from "react";
import Card from "components/ui/Card/Card";
import { H3 } from "components/ui/Headings/Headings";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FeatureState } from "models/FeatureState";
import { useAppSelector } from "app/store";
import { changePasswordActions } from "./changePassword.slice";
import useFormValidations from "hooks/useFormValidations";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import InputFormGroup from "components/forms/InputFormGroup";
import Modal from "components/ui/Modal/Modal";
import Content from "components/layout/Content";
import CardFooter from "./components/CardFooter";
import Alert from "components/ui/Alert/Alert";
import Breadcrumbs, {
  BreadcrumbsItem,
} from "components/ui/Breadcrumbs/Breadcrumbs";
import useEventLogger from "hooks/useEventLogger";

export interface ChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const breadcrumbsItems: BreadcrumbsItem[] = [
    {
      label: t("settings.home.title"),
      link: "/settings",
    },
  ];

  const { featureState } = useAppSelector(
    (state) => state.settings.changePassword
  );

  const feedbackModalIsOpen = useMemo(
    () => featureState === FeatureState.Success,
    [featureState]
  );

  const hasBELoginError = useMemo(
    () => featureState === FeatureState.Error,
    [featureState]
  );

  const { passwordValidation, confirmPasswordValidation } = useFormValidations({
    passwordFieldName: "newPassword",
  });

  const { trackAction } = useEventLogger("SettingsSubmitChangePassword");

  const handleSubmit: (
    values: ChangePasswordFormValues,
    formikHelpers: FormikHelpers<ChangePasswordFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    trackAction({});

    await dispatch(changePasswordActions.setPassword(values));
  };

  useEffect(() => {
    return () => {
      dispatch(changePasswordActions.reset());
    };
  }, [dispatch]);

  return (
    <>
      <Content>
        <Breadcrumbs items={breadcrumbsItems} />
        <Formik<ChangePasswordFormValues>
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={Yup.object({
            currentPassword: passwordValidation,
            newPassword: passwordValidation.notOneOf(
              [Yup.ref("currentPassword"), null],
              t("validationErrors.newPasswordCannotMatchPrevious")
            ),
            confirmNewPassword: confirmPasswordValidation,
          })}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formikProps) => (
            <Card
              footer={
                <CardFooter<ChangePasswordFormValues>
                  formikProps={formikProps}
                  cancelButtonText={t(
                    "settings.changePassword.cancelButtonText"
                  )}
                  nextButtonText={t("settings.changePassword.changeButtonText")}
                  handleCardCancelClick={() => history.push("/settings")}
                />
              }
            >
              <div className="md:w-80 mx-auto">
                <H3>{t("settings.changePassword.title")}</H3>

                <p className="mb-4">
                  {t("settings.changePassword.description")}
                </p>

                {hasBELoginError && (
                  <div className="mb-6">
                    <Alert type="error">
                      {t("settings.changePassword.invalidCurrentPassword")}
                    </Alert>
                  </div>
                )}
                <Form noValidate>
                  <InputFormGroup
                    name="currentPassword"
                    type="password"
                    label={t("settings.changePassword.currentPassword")}
                    placeholder={t(
                      "settings.changePassword.currentPasswordPlaceholder"
                    )}
                    required
                    forceError={hasBELoginError}
                  />
                  <hr className="border-main mb-4" />
                  <InputFormGroup
                    name="newPassword"
                    type="password"
                    label={t("settings.changePassword.newPassword")}
                    placeholder={t(
                      "settings.changePassword.newPasswordPlaceholder"
                    )}
                    required
                  />
                  <div className="-mb-4">
                    <InputFormGroup
                      name="confirmNewPassword"
                      type="password"
                      label={t("settings.changePassword.confirmPassword")}
                      placeholder={t(
                        "settings.changePassword.confirmPasswordPlaceholder"
                      )}
                      required
                    />
                  </div>
                </Form>
              </div>
            </Card>
          )}
        </Formik>
      </Content>
      <Modal
        isOpen={feedbackModalIsOpen}
        title={t("settings.changePassword.successModal.title")}
        okText={t(
          "settings.changePassword.successModal.backToProfileButtonText"
        )}
        type="alert"
        alertType="success"
        onOk={() => history.push("/settings")}
      />
    </>
  );
};

export default ChangePassword;
