// Used to display global modal when FE receives GlobalError from BE
export enum GlobalError {
  PaymentAlreadyInProgress = "PaymentAlreadyInProgress",
  DebtNotFound = "DebtNotFound",
  UnsupportedCurrency = "UnsupportedCurrency",
  BackendUnavailable = "BackendUnavailable",
  FinasticUnavailable = "FinasticUnavailable", // this doesn't exist on BE, only on FE
}

// Used to handle error from BE customly
export enum CustomError {
  UserAlreadyExists = "UserAlreadyExists",
  NoMatchingCustomer = "NoMatchingCustomer",
}

export type CustomErrorKey = keyof typeof CustomError;
export type GlobalErrorKey = keyof typeof GlobalError;

export type ErrorKey = CustomErrorKey | GlobalErrorKey;
