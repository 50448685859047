import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";
import { SMSResetPasswordFormValues } from "./SMSResetPassword";

export interface SmsResetPasswordState {
  formValues?: SMSResetPasswordFormValues;
  featureState: FeatureState;
}

const initialState: SmsResetPasswordState = {
  formValues: undefined,
  featureState: FeatureState.Initial,
};

const smsResetPassword = createSlice({
  name: "smsResetPassword",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
    setFormValues(
      state,
      { payload }: PayloadAction<SMSResetPasswordFormValues>
    ) {
      state.formValues = payload;
    },
  },
});

const { setFormValues, reset, setState } = smsResetPassword.actions;

export const smsResetPasswordReducer = smsResetPassword.reducer;

const resetPassword =
  (values: SMSResetPasswordFormValues): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    const state = getState();

    const dataFromFirstStep = state.forgotPassword.smsPasscode;

    if (typeof dataFromFirstStep.token !== "string") {
      return;
    }

    dispatch(setState(FeatureState.Loading));

    return api.finastic.authentication
      .apiFrontendV1AuthenticationSetPasswordWithTokenPost({
        setPasswordWithTokenCommand: {
          password: values.password,
          token: dataFromFirstStep.token,
        },
      })
      .then(() => dispatch(setState(FeatureState.Success)));
  };

export const smsResetPasswordActions = {
  setFormValues,
  reset,
  resetPassword,
};
