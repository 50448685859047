/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePrivacyConsentCommand
 */
export interface UpdatePrivacyConsentCommand {
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePrivacyConsentCommand
     */
    isEmailMarketingAllowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePrivacyConsentCommand
     */
    isPushMarketingAllowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePrivacyConsentCommand
     */
    isSmsMarketingAllowed: boolean;
}

export function UpdatePrivacyConsentCommandFromJSON(json: any): UpdatePrivacyConsentCommand {
    return UpdatePrivacyConsentCommandFromJSONTyped(json, false);
}

export function UpdatePrivacyConsentCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePrivacyConsentCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEmailMarketingAllowed': json['isEmailMarketingAllowed'],
        'isPushMarketingAllowed': json['isPushMarketingAllowed'],
        'isSmsMarketingAllowed': json['isSmsMarketingAllowed'],
    };
}

export function UpdatePrivacyConsentCommandToJSON(value?: UpdatePrivacyConsentCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isEmailMarketingAllowed': value.isEmailMarketingAllowed,
        'isPushMarketingAllowed': value.isPushMarketingAllowed,
        'isSmsMarketingAllowed': value.isSmsMarketingAllowed,
    };
}

