import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { FeatureState } from "models/FeatureState";

export interface EmailConfirmationState {
  featureState: FeatureState;
}

const initialState: EmailConfirmationState = {
  featureState: FeatureState.Loading,
};

const emailConfirmation = createSlice({
  name: "emailConfirmation",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
  },
});

const { reset, setState } = emailConfirmation.actions;

export const emailConfirmationReducer = emailConfirmation.reducer;

const confirmEmail =
  (token: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch(setState(FeatureState.Loading));

    return api.finastic.authentication
      .apiFrontendV1AuthenticationCheckResetPasswordTokenPut({
        checkResetPasswordTokenCommand: {
          token,
        },
      })
      .then(() => {
        dispatch(setState(FeatureState.Success));
      })
      .catch((error) => {
        dispatch(setState(FeatureState.Error));

        throw error;
      });
  };

export const emailConfirmationActions = {
  reset,
  confirmEmail,
};
