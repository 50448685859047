import { combineReducers } from "redux";
import { debtDetailsReducer } from "./DebtDetails/debtDetails.slice";
import { debtPaymentReducer } from "./DebtPayment/debtPayment.slice";
import { debtPaymentPlanReducer } from "./DebtPaymentPlanCreationWizard/DebtPaymentPlan.slice";
import { debtsListReducer } from "./DebtsList/debtsList.slice";

const debtsReducers = combineReducers({
  debtsList: debtsListReducer,
  debtDetails: debtDetailsReducer,
  debtPayment: debtPaymentReducer,
  debtPaymentPlan: debtPaymentPlanReducer,
});

export default debtsReducers;
