import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api";
import { AppThunk } from "app/store";
import { authSliceActions } from "features/auth/authSlice";
import { FeatureState } from "models/FeatureState";
import { PrivacyConsentFormValues } from "./PrivacyConsent";

export interface PrivacyConsentState {
  featureState: FeatureState;
}

const initialState: PrivacyConsentState = {
  featureState: FeatureState.Initial,
};

const privacyConsent = createSlice({
  name: "privacyConsent",
  initialState,
  reducers: {
    reset: () => initialState,
    setState(state, { payload }: PayloadAction<FeatureState>) {
      state.featureState = payload;
    },
  },
});

const { reset, setState } = privacyConsent.actions;

export const privacyConsentReducer = privacyConsent.reducer;

const setPrivacyConsent =
  ({
    isEmailMarketingAllowed,
    isPushMarketingAllowed,
    isSmsMarketingAllowed,
  }: PrivacyConsentFormValues): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    dispatch(setState(FeatureState.Loading));

    return api.finastic.userPreference
      .apiFrontendV1UserPreferencePrivacyConsentPut({
        updatePrivacyConsentCommand: {
          isEmailMarketingAllowed,
          isPushMarketingAllowed,
          isSmsMarketingAllowed,
        },
      })
      .then(() => {
        dispatch(setState(FeatureState.Success));

        const state = getState();

        dispatch(
          authSliceActions.setUser({
            ...state.auth.user,
            preferences: {
              ...state.auth.user?.preferences,
              isEmailMarketingAllowed,
              isPushMarketingAllowed,
              isSmsMarketingAllowed,
            },
          })
        );
      })
      .catch(() => dispatch(setState(FeatureState.Error)));
  };

export const privacyConsentActions = {
  reset,
  setPrivacyConsent,
};
