/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryCode,
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';
import {
    LanguageCode,
    LanguageCodeFromJSON,
    LanguageCodeFromJSONTyped,
    LanguageCodeToJSON,
} from './LanguageCode';

/**
 * 
 * @export
 * @interface RegisterCustomerCommand
 */
export interface RegisterCustomerCommand {
    /**
     * 
     * @type {CountryCode}
     * @memberof RegisterCustomerCommand
     */
    countryCode?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerCommand
     */
    personIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerCommand
     */
    accountIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerCommand
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerCommand
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerCommand
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerCommand
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerCommand
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterCustomerCommand
     */
    isTacAndPublicNoticeAccepted?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterCustomerCommand
     */
    isPrivacyAndCookiePolicyAccepted?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterCustomerCommand
     */
    isEmailMarketingAllowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterCustomerCommand
     */
    isSmsMarketingAllowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterCustomerCommand
     */
    isPushMarketingAllowed: boolean;
    /**
     * 
     * @type {LanguageCode}
     * @memberof RegisterCustomerCommand
     */
    languageCode?: LanguageCode;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerCommand
     */
    token: string;
}

export function RegisterCustomerCommandFromJSON(json: any): RegisterCustomerCommand {
    return RegisterCustomerCommandFromJSONTyped(json, false);
}

export function RegisterCustomerCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterCustomerCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': !exists(json, 'countryCode') ? undefined : CountryCodeFromJSON(json['countryCode']),
        'personIdentifier': json['personIdentifier'],
        'accountIdentifier': json['accountIdentifier'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'emailAddress': json['emailAddress'],
        'phoneNumber': json['phoneNumber'],
        'password': json['password'],
        'isTacAndPublicNoticeAccepted': !exists(json, 'isTacAndPublicNoticeAccepted') ? undefined : json['isTacAndPublicNoticeAccepted'],
        'isPrivacyAndCookiePolicyAccepted': !exists(json, 'isPrivacyAndCookiePolicyAccepted') ? undefined : json['isPrivacyAndCookiePolicyAccepted'],
        'isEmailMarketingAllowed': json['isEmailMarketingAllowed'],
        'isSmsMarketingAllowed': json['isSmsMarketingAllowed'],
        'isPushMarketingAllowed': json['isPushMarketingAllowed'],
        'languageCode': !exists(json, 'languageCode') ? undefined : LanguageCodeFromJSON(json['languageCode']),
        'token': json['token'],
    };
}

export function RegisterCustomerCommandToJSON(value?: RegisterCustomerCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': CountryCodeToJSON(value.countryCode),
        'personIdentifier': value.personIdentifier,
        'accountIdentifier': value.accountIdentifier,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
        'password': value.password,
        'isTacAndPublicNoticeAccepted': value.isTacAndPublicNoticeAccepted,
        'isPrivacyAndCookiePolicyAccepted': value.isPrivacyAndCookiePolicyAccepted,
        'isEmailMarketingAllowed': value.isEmailMarketingAllowed,
        'isSmsMarketingAllowed': value.isSmsMarketingAllowed,
        'isPushMarketingAllowed': value.isPushMarketingAllowed,
        'languageCode': LanguageCodeToJSON(value.languageCode),
        'token': value.token,
    };
}

