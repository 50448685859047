import DataLine from "components/ui/DataLine/DataLine";
import { mdiCalendarToday, mdiCalendarMultiple } from "@mdi/js";
import useDateFormatter, { DateFormat } from "hooks/useDateFormatter";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { LanguageCode } from "api/generated/finastic";

interface InstalmentDateDataProps {
  firstInstalmentDueDate: Date;
}

const InstalmentDateData: React.FC<InstalmentDateDataProps> = ({
  firstInstalmentDueDate,
}) => {
  const { t, i18n } = useTranslation();
  const { formatter } = useDateFormatter();

  const dayOfMonthValue = useMemo(
    () =>
      (i18n.language as LanguageCode) === "PL"
        ? formatter(firstInstalmentDueDate, DateFormat.DayOfMonth) // Polish "DayOfMonthOrdinal" return "{day}." and we only need "{day}" in translation
        : formatter(firstInstalmentDueDate, DateFormat.DayOfMonthOrdinal),
    [firstInstalmentDueDate, formatter, i18n.language]
  );

  return (
    <div className="space-y-4">
      <DataLine
        iconPath={mdiCalendarMultiple}
        label={t("debt.yourInstalmentPaymentDate")}
        value={
          formatter(firstInstalmentDueDate, DateFormat.DayOfMonth) === "1"
            ? (t("common.firstDayOfTheMonth") as string)
            : (t("common.dayOfTheMonth", {
                value: dayOfMonthValue,
              }) as string)
        }
      />
      <DataLine
        iconPath={mdiCalendarToday}
        label={t("debt.firstInstalmentDueOn")}
        value={formatter(firstInstalmentDueDate, DateFormat.Date)}
      />
    </div>
  );
};

export default InstalmentDateData;
