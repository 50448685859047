/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UpdatePasswordCommand,
    UpdatePasswordCommandFromJSON,
    UpdatePasswordCommandToJSON,
    UserProfileDto,
    UserProfileDtoFromJSON,
    UserProfileDtoToJSON,
} from '../models';

export interface ApiFrontendV1UserPasswordPutRequest {
    updatePasswordCommand?: UpdatePasswordCommand;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async apiFrontendV1UserPasswordPutRaw(requestParameters: ApiFrontendV1UserPasswordPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/User/Password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePasswordCommandToJSON(requestParameters.updatePasswordCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1UserPasswordPut(requestParameters: ApiFrontendV1UserPasswordPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1UserPasswordPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFrontendV1UserProfileDeleteRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/User/Profile`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1UserProfileDelete(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1UserProfileDeleteRaw(initOverrides);
    }

    /**
     */
    async apiFrontendV1UserProfileGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserProfileDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/User/Profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1UserProfileGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserProfileDto> {
        const response = await this.apiFrontendV1UserProfileGetRaw(initOverrides);
        return await response.value();
    }

}
