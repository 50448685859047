import Button from "components/ui/Button/Button";
import MaterialIcon from "components/ui/MaterialIcon/MaterialIcon";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { mdiTrafficCone } from "@mdi/js";
import { useAppSelector } from "app/store";
import useDateFormatter, { DateFormat } from "hooks/useDateFormatter";
import useRetryTimeCounter from "hooks/useRetryTimeCounter";
import { useDispatch } from "react-redux";
import { maintenanceActions } from "./maintenanceSlice";

const Maintenance: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { scheduledMaintenanceEndTime } = useAppSelector(
    (state) => state.maintenance
  );
  const { remainingTimeCountingInProgress, secondsRemainingToRetry } =
    useRetryTimeCounter(60);
  const dispatch = useDispatch();

  const { formatter } = useDateFormatter();

  const handleRetryButtonClick = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    return () => {
      dispatch(maintenanceActions.setScheduledMaintenanceEndTime(undefined));
    };
  }, [dispatch]);

  return (
    <div className="h-full flex justify-center items-center flex-col">
      <MaterialIcon path={mdiTrafficCone} className="h-12 text-warning my-4" />
      <div className="mb-4">
        <h1 className="text-base md:text-3xl font-semibold md:font-normal font-exo2">
          {t("maintenance.title")}
        </h1>
      </div>
      {scheduledMaintenanceEndTime && (
        <h2 className="text-base md:text-xl font-exo2">
          {t("maintenance.description", {
            datetime: formatter(
              scheduledMaintenanceEndTime,
              DateFormat.DateTime
            ),
          })}
        </h2>
      )}

      <div className="mt-7 min-w-wideButton">
        <Button
          onClick={handleRetryButtonClick}
          block
          disabled={remainingTimeCountingInProgress}
        >
          {t("maintenance.button")}{" "}
          {remainingTimeCountingInProgress && <>({secondsRemainingToRetry})</>}
        </Button>
      </div>
    </div>
  );
};

export default Maintenance;
