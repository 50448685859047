import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { MixedSchema } from "yup/lib/mixed";
import { RequiredStringSchema } from "yup/lib/string";
import { RequiredNumberSchema } from "yup/lib/number";
import { PhomeNumberFormGroupValue } from "components/forms/PhoneNumberFormGroup";

interface UseFormValidations {
  phoneNumberValidation: MixedSchema<any, Record<string, any>, any>;
  passwordValidation: RequiredStringSchema<
    string | undefined,
    Record<string, any>
  >;
  confirmPasswordValidation: RequiredStringSchema<
    string | undefined,
    Record<string, any>
  >;
  otpValidation: RequiredNumberSchema<
    number | null | undefined,
    Record<string, any>
  >;
}

interface UseFormValidationsProps {
  passwordFieldName?: string;
}

const useFormValidations = ({
  passwordFieldName = "password",
}: UseFormValidationsProps = {}): UseFormValidations => {
  const { t } = useTranslation();

  const phoneNumberValidation = Yup.mixed()
    .test(
      "phoneNumber.prefix.required",
      t("validationErrors.required"),
      async function (value: PhomeNumberFormGroupValue) {
        const schema = Yup.number().required();

        return await schema.isValid(value.prefix);
      }
    )
    .test(
      "phoneNumber.number.required",
      t("validationErrors.required"),
      async function (value: PhomeNumberFormGroupValue) {
        const schema = Yup.number().required();

        return await schema.isValid(value.number);
      }
    );

  const passwordValidation = Yup.string()
    .required(t("validationErrors.required"))
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&.:,;-]{8,}$/, // at least one Uppercase, one Lowercase and on number. A-Za-z\d@$!%*#?&.:,;- values are allowed
      t("validationErrors.passwordStrength")
    );

  const confirmPasswordValidation = Yup.string()
    .required(t("validationErrors.required"))
    .oneOf(
      [Yup.ref(passwordFieldName), null],
      t("validationErrors.passwordsMustMatch")
    );

  const otpValidation = Yup.number()
    .nullable()
    .required(t("validationErrors.required"))
    .test(
      "otp.length",
      t("validationErrors.length", { length: 6 }),
      function (value?: number | null) {
        return !!value && value.toString().length === 6;
      }
    );

  return {
    phoneNumberValidation,
    passwordValidation,
    confirmPasswordValidation,
    otpValidation,
  };
};

export default useFormValidations;
