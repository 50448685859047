import React, { useCallback, useMemo } from "react";
import { useField } from "formik";
import FormGroup from "./FormGroup";
import FormGroupLabel from "./FormGroupLabel";
import FormGroupError from "./FormGroupError";
import InputNumber from "./InputNumber";
import { NumberFormatProps } from "react-number-format";

interface PhoneNumberFormGroupProps extends NumberFormatProps {
  label?: string;
  prefixPlaceholder?: string;
  prefixIsDisabled?: boolean;
  numberIsDisabled?: boolean;
}

export interface PhomeNumberFormGroupValue {
  prefix?: number;
  number?: number;
}

const PhoneNumberFormGroup: React.FC<PhoneNumberFormGroupProps> = ({
  name,
  label,
  required,
  prefixPlaceholder,
  disabled,
  prefixIsDisabled,
  numberIsDisabled,
  ...rest
}) => {
  const [field, meta, helpers] = useField<PhomeNumberFormGroupValue>(
    name || ""
  );

  const hasError = useMemo(
    () => !!meta.touched && !!meta.error,
    [meta.error, meta.touched]
  );

  const handleOnPrefixChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      helpers.setValue({
        prefix: event.target.value === "" ? undefined : +event.target.value,
        number: field.value?.number,
      });

      helpers.setTouched(true);
    },
    [field.value?.number, helpers]
  );

  const handleOnNumberChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      helpers.setValue({
        prefix: field.value?.prefix,
        number: event.target.value === "" ? undefined : +event.target.value,
      });

      helpers.setTouched(true);
    },
    [field.value?.prefix, helpers]
  );

  return (
    <FormGroup>
      {label && (
        <FormGroupLabel required={required} hasError={hasError}>
          {label}
        </FormGroupLabel>
      )}
      <div className="flex">
        <div className="w-16 flex-shrink-0 mr-4 relative">
          <InputNumber
            {...rest}
            required={required}
            onChange={handleOnPrefixChange}
            value={field.value?.prefix}
            placeholder={prefixPlaceholder}
            onBlur={() => helpers.setTouched(true)}
            thousandSeparator={false}
            maxLength={3}
            hasPrependText
            disabled={prefixIsDisabled || disabled}
          />
          <span className="absolute top-1/2 left-3 transform -translate-y-1/2 mt-px">
            +
          </span>
        </div>
        <div className="w-full">
          <InputNumber
            {...rest}
            name={name}
            required={required}
            onChange={handleOnNumberChange}
            value={field.value?.number}
            thousandSeparator={false}
            onBlur={() => helpers.setTouched(true)}
            disabled={numberIsDisabled || disabled}
          />
        </div>
      </div>

      {hasError ? (
        <FormGroupError fieldName={name}>{meta.error}</FormGroupError>
      ) : null}
    </FormGroup>
  );
};

export default PhoneNumberFormGroup;
