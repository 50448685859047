/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CheckResetPasswordOtpCommand,
    CheckResetPasswordOtpCommandFromJSON,
    CheckResetPasswordOtpCommandToJSON,
    CheckResetPasswordTokenCommand,
    CheckResetPasswordTokenCommandFromJSON,
    CheckResetPasswordTokenCommandToJSON,
    InitiateResetPasswordWithOtpCommand,
    InitiateResetPasswordWithOtpCommandFromJSON,
    InitiateResetPasswordWithOtpCommandToJSON,
    InitiateResetPasswordWithTokenCommand,
    InitiateResetPasswordWithTokenCommandFromJSON,
    InitiateResetPasswordWithTokenCommandToJSON,
    LoginCommand,
    LoginCommandFromJSON,
    LoginCommandToJSON,
    RefreshTokenCommand,
    RefreshTokenCommandFromJSON,
    RefreshTokenCommandToJSON,
    ResetPasswordTokenDto,
    ResetPasswordTokenDtoFromJSON,
    ResetPasswordTokenDtoToJSON,
    SetPasswordWithTokenCommand,
    SetPasswordWithTokenCommandFromJSON,
    SetPasswordWithTokenCommandToJSON,
    UserLoginDto,
    UserLoginDtoFromJSON,
    UserLoginDtoToJSON,
} from '../models';

export interface ApiFrontendV1AuthenticationCheckResetPasswordOtpPutRequest {
    checkResetPasswordOtpCommand?: CheckResetPasswordOtpCommand;
}

export interface ApiFrontendV1AuthenticationCheckResetPasswordTokenPutRequest {
    checkResetPasswordTokenCommand?: CheckResetPasswordTokenCommand;
}

export interface ApiFrontendV1AuthenticationInitiateResetPasswordWithOtpPutRequest {
    initiateResetPasswordWithOtpCommand?: InitiateResetPasswordWithOtpCommand;
}

export interface ApiFrontendV1AuthenticationInitiateResetPasswordWithTokenPutRequest {
    initiateResetPasswordWithTokenCommand?: InitiateResetPasswordWithTokenCommand;
}

export interface ApiFrontendV1AuthenticationLoginPostRequest {
    loginCommand?: LoginCommand;
}

export interface ApiFrontendV1AuthenticationRefreshTokenPostRequest {
    refreshTokenCommand?: RefreshTokenCommand;
}

export interface ApiFrontendV1AuthenticationSetPasswordWithTokenPostRequest {
    setPasswordWithTokenCommand?: SetPasswordWithTokenCommand;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     */
    async apiFrontendV1AuthenticationCheckResetPasswordOtpPutRaw(requestParameters: ApiFrontendV1AuthenticationCheckResetPasswordOtpPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ResetPasswordTokenDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Authentication/CheckResetPasswordOtp`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CheckResetPasswordOtpCommandToJSON(requestParameters.checkResetPasswordOtpCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetPasswordTokenDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1AuthenticationCheckResetPasswordOtpPut(requestParameters: ApiFrontendV1AuthenticationCheckResetPasswordOtpPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ResetPasswordTokenDto> {
        const response = await this.apiFrontendV1AuthenticationCheckResetPasswordOtpPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1AuthenticationCheckResetPasswordTokenPutRaw(requestParameters: ApiFrontendV1AuthenticationCheckResetPasswordTokenPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Authentication/CheckResetPasswordToken`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CheckResetPasswordTokenCommandToJSON(requestParameters.checkResetPasswordTokenCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1AuthenticationCheckResetPasswordTokenPut(requestParameters: ApiFrontendV1AuthenticationCheckResetPasswordTokenPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1AuthenticationCheckResetPasswordTokenPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFrontendV1AuthenticationInitiateResetPasswordWithOtpPutRaw(requestParameters: ApiFrontendV1AuthenticationInitiateResetPasswordWithOtpPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Authentication/InitiateResetPasswordWithOtp`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InitiateResetPasswordWithOtpCommandToJSON(requestParameters.initiateResetPasswordWithOtpCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1AuthenticationInitiateResetPasswordWithOtpPut(requestParameters: ApiFrontendV1AuthenticationInitiateResetPasswordWithOtpPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1AuthenticationInitiateResetPasswordWithOtpPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFrontendV1AuthenticationInitiateResetPasswordWithTokenPutRaw(requestParameters: ApiFrontendV1AuthenticationInitiateResetPasswordWithTokenPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Authentication/InitiateResetPasswordWithToken`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InitiateResetPasswordWithTokenCommandToJSON(requestParameters.initiateResetPasswordWithTokenCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1AuthenticationInitiateResetPasswordWithTokenPut(requestParameters: ApiFrontendV1AuthenticationInitiateResetPasswordWithTokenPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1AuthenticationInitiateResetPasswordWithTokenPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFrontendV1AuthenticationLoginPostRaw(requestParameters: ApiFrontendV1AuthenticationLoginPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserLoginDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Authentication/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginCommandToJSON(requestParameters.loginCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLoginDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1AuthenticationLoginPost(requestParameters: ApiFrontendV1AuthenticationLoginPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserLoginDto> {
        const response = await this.apiFrontendV1AuthenticationLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1AuthenticationRefreshTokenPostRaw(requestParameters: ApiFrontendV1AuthenticationRefreshTokenPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<UserLoginDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Authentication/RefreshToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenCommandToJSON(requestParameters.refreshTokenCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserLoginDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1AuthenticationRefreshTokenPost(requestParameters: ApiFrontendV1AuthenticationRefreshTokenPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserLoginDto> {
        const response = await this.apiFrontendV1AuthenticationRefreshTokenPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1AuthenticationSetPasswordWithTokenPostRaw(requestParameters: ApiFrontendV1AuthenticationSetPasswordWithTokenPostRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Authentication/SetPasswordWithToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPasswordWithTokenCommandToJSON(requestParameters.setPasswordWithTokenCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1AuthenticationSetPasswordWithTokenPost(requestParameters: ApiFrontendV1AuthenticationSetPasswordWithTokenPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1AuthenticationSetPasswordWithTokenPostRaw(requestParameters, initOverrides);
    }

}
