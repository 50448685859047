/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommunicationChannel,
    CommunicationChannelFromJSON,
    CommunicationChannelToJSON,
    UpdateCommunicationChannelCommand,
    UpdateCommunicationChannelCommandFromJSON,
    UpdateCommunicationChannelCommandToJSON,
    UpdateLanguageCommand,
    UpdateLanguageCommandFromJSON,
    UpdateLanguageCommandToJSON,
    UpdatePrivacyConsentCommand,
    UpdatePrivacyConsentCommandFromJSON,
    UpdatePrivacyConsentCommandToJSON,
} from '../models';

export interface ApiFrontendV1UserPreferenceCommunicationChannelPutRequest {
    updateCommunicationChannelCommand?: UpdateCommunicationChannelCommand;
}

export interface ApiFrontendV1UserPreferenceLanguagePutRequest {
    updateLanguageCommand?: UpdateLanguageCommand;
}

export interface ApiFrontendV1UserPreferencePrivacyConsentPutRequest {
    updatePrivacyConsentCommand?: UpdatePrivacyConsentCommand;
}

/**
 * 
 */
export class UserPreferenceApi extends runtime.BaseAPI {

    /**
     */
    async apiFrontendV1UserPreferenceCommunicationChannelGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CommunicationChannel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/UserPreference/CommunicationChannel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommunicationChannelFromJSON));
    }

    /**
     */
    async apiFrontendV1UserPreferenceCommunicationChannelGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CommunicationChannel>> {
        const response = await this.apiFrontendV1UserPreferenceCommunicationChannelGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1UserPreferenceCommunicationChannelPutRaw(requestParameters: ApiFrontendV1UserPreferenceCommunicationChannelPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/UserPreference/CommunicationChannel`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCommunicationChannelCommandToJSON(requestParameters.updateCommunicationChannelCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1UserPreferenceCommunicationChannelPut(requestParameters: ApiFrontendV1UserPreferenceCommunicationChannelPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1UserPreferenceCommunicationChannelPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFrontendV1UserPreferenceLanguagePutRaw(requestParameters: ApiFrontendV1UserPreferenceLanguagePutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/UserPreference/Language`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLanguageCommandToJSON(requestParameters.updateLanguageCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1UserPreferenceLanguagePut(requestParameters: ApiFrontendV1UserPreferenceLanguagePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1UserPreferenceLanguagePutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFrontendV1UserPreferencePrivacyConsentPutRaw(requestParameters: ApiFrontendV1UserPreferencePrivacyConsentPutRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/UserPreference/PrivacyConsent`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePrivacyConsentCommandToJSON(requestParameters.updatePrivacyConsentCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFrontendV1UserPreferencePrivacyConsentPut(requestParameters: ApiFrontendV1UserPreferencePrivacyConsentPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.apiFrontendV1UserPreferencePrivacyConsentPutRaw(requestParameters, initOverrides);
    }

}
