import { useCallback, useEffect, useMemo, useState } from "react";

interface UseRetryTimeCounter {
  remainingTimeCountingInProgress: boolean;
  secondsRemainingToRetry: number;
  resetTimer: () => void;
}

const useRetryTimeCounter = (
  initialRemainingTime = 30 // in seconds
): UseRetryTimeCounter => {
  const [secondsRemainingToRetry, setSecondsRemainingToRetry] =
    useState(initialRemainingTime);

  const remainingTimeCountingEnabled = useMemo(
    () => secondsRemainingToRetry > 0,
    [secondsRemainingToRetry]
  );

  const remainingTimeCountingInProgress = useMemo(
    () => secondsRemainingToRetry > 0,
    [secondsRemainingToRetry]
  );

  const resetTimer = useCallback(() => {
    setSecondsRemainingToRetry(initialRemainingTime);
  }, [initialRemainingTime]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (remainingTimeCountingEnabled) {
      interval = setInterval(() => {
        setSecondsRemainingToRetry((seconds) => seconds - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [remainingTimeCountingEnabled]);

  return {
    remainingTimeCountingInProgress,
    secondsRemainingToRetry,
    resetTimer,
  };
};

export default useRetryTimeCounter;
