import { RequestErrorBody } from "api/middleware/errorHandlingMiddleware";
import i18n from "app/i18n";
import { GlobalError, GlobalErrorKey } from "models/Error";
import { GroupOption } from "models/GroupOption";

export const translateBooleanValue = (
  value?: boolean | null,
  trueTranslation?: string,
  falseTranslation?: string
): string =>
  value
    ? trueTranslation || i18n.t(`common.yes`)
    : falseTranslation || i18n.t(`common.no`);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const translateEnumValue = (value: any, enumName: string): string =>
  i18n.t(`enums.${enumName}.${value}`);

export function getEnumValues<T>(object: { [s: string]: T }): T[] {
  return Object.values(object).filter((value) => value !== ("Invalid" as any));
}

// enum translation keys must be inserted into src/app/i18n/enums.ts MANUALLY
// if enumValuesInOrder param is passed function takes values only from that array in order
export function getEnumValuesOptions<T>(
  object: { [s: string]: T },
  enumName: string,
  enumValuesInOrder?: T[]
): GroupOption<T>[] {
  return (enumValuesInOrder || getEnumValues(object)).map((value) => ({
    label: translateEnumValue(value, enumName),
    value: value,
  }));
}

export const getCurrentGlobalErrorKey = (
  errorJson: RequestErrorBody
): GlobalErrorKey | undefined => {
  if (errorJson.errors) {
    const foundErrorKey = Object.keys(errorJson.errors).find((errorKey) =>
      Object.keys(GlobalError).includes(errorKey)
    );

    return foundErrorKey as GlobalErrorKey;
  }
};
