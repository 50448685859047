import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Switch, useRouteMatch } from "react-router-dom";
import { PublicRoute } from "router/PublicRoute";
import { RouteItem, RouteItemTypes } from "router/useRoutes";
import { SubRouteLayout } from "components/layout/SubRouteLayout";
import PayDebt from "./PayDebt";
import { PaymentLinkLayout } from "components/layout/PaymentLinkLayout";
import Error404 from "features/error/Error404";
import PaymentResult from "./PaymentResult";

const PaymentLinkRouteSwitch: React.FC = () => {
  const { path: currentPath } = useRouteMatch();
  const { t } = useTranslation();

  const routes = useMemo<RouteItem[]>(
    () => [
      {
        path: `/pay/:backendCode(${process.env.REACT_APP_SUPPORTED_BACKEND_CODES?.split(
          ","
        ).join("|")})/:debtId`, // :backendCode(c|o|SOME_FUTURE_BACKEND_CODE)
        title: t("pageTitle.debtRepayment"),
        component: PayDebt,
        layout: PaymentLinkLayout,
        type: RouteItemTypes.Public,
      },
      {
        path: "/result", // BE redirects to this route after payment
        title: t("pageTitle.debtRepayment"),
        component: PaymentResult,
        layout: PaymentLinkLayout,
        type: RouteItemTypes.Public,
      },
    ],
    [t]
  );

  return (
    <Switch>
      {routes.map(({ path, ...rest }) => (
        <PublicRoute
          {...rest}
          key={path}
          path={`${currentPath}${path}`}
          layout={SubRouteLayout}
        />
      ))}
      <PublicRoute
        component={Error404}
        title={t("pageTitle.pageNotFound")}
        layout={SubRouteLayout}
      />
    </Switch>
  );
};

export default PaymentLinkRouteSwitch;
