import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeatureState } from "models/FeatureState";
import { AppThunk } from "app/store";
import {
  CommunicationChannel,
  LanguageCode,
  UserProfileDto,
} from "api/generated/finastic";
import { LoginFormValues } from "./Login";
import {
  getLocalStorageValue,
  LocalStorageKeys,
  removeLocalStorageValue,
  setLocalStorageValue,
} from "utils/storageHandlers";
import { api } from "api";
import { resetStore } from "app/storeUtils";

export interface AuthState {
  user?: UserProfileDto;
  loginState: FeatureState;
  userState: FeatureState;
}

const getInitialUserValue = (): UserProfileDto | undefined => {
  const userValueInStorage = getLocalStorageValue(LocalStorageKeys.User);

  // If user has loged in previously on other tab, make the app starting experience better:
  // we assume that he is logged in
  const initialUserValue =
    userValueInStorage && userValueInStorage !== ""
      ? (JSON.parse(userValueInStorage) as UserProfileDto)
      : undefined;

  return initialUserValue;
};

const getInitialState = (): AuthState => ({
  user: getInitialUserValue(),
  loginState: FeatureState.Initial,
  userState: FeatureState.Initial,
});

const authSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {
    reset: () => getInitialState(),
    setLoginState(state, { payload }: PayloadAction<FeatureState>) {
      state.loginState = payload;
    },
    getUserSuccess(state, { payload }: PayloadAction<UserProfileDto>) {
      state.user = payload;
      state.userState = FeatureState.Success;
    },
    setUserState(state, { payload }: PayloadAction<FeatureState>) {
      state.userState = payload;
    },
    setUser(state, { payload }: PayloadAction<UserProfileDto>) {
      state.user = payload;

      // keep storage up to date
      setLocalStorageValue(LocalStorageKeys.User, JSON.stringify(state.user));
    },
  },
});

export const authSliceReducer = authSlice.reducer;

/**
 * ACTIONS
 */

const { reset, setLoginState, setUserState, getUserSuccess, setUser } =
  authSlice.actions;

const logIn =
  (payload: LoginFormValues): AppThunk =>
  async (dispatch) => {
    dispatch(setLoginState(FeatureState.Loading));

    return api.finastic.authentication
      .apiFrontendV1AuthenticationLoginPost({
        loginCommand: payload,
      })
      .then((responseDTO) => {
        setLocalStorageValue(
          LocalStorageKeys.AccessToken,
          responseDTO.accessToken || ""
        );
        setLocalStorageValue(
          LocalStorageKeys.RefreshToken,
          responseDTO.refreshToken || ""
        );
        setLocalStorageValue(LocalStorageKeys.UserId, responseDTO.userId || "");

        dispatch(setLoginState(FeatureState.Success));
      })
      .catch((error) => {
        dispatch(setLoginState(FeatureState.Error));

        throw error;
      });
  };

const getUser = (): AppThunk => async (dispatch) => {
  dispatch(setUserState(FeatureState.Loading));

  return api.finastic.user
    .apiFrontendV1UserProfileGet()
    .then((responseDTO) => {
      setLocalStorageValue(LocalStorageKeys.User, JSON.stringify(responseDTO));

      dispatch(getUserSuccess(responseDTO));
    })
    .catch((error) => {
      dispatch(setUserState(FeatureState.Error));

      throw error;
    });
};

const logOut = (): AppThunk<Promise<any>> => async (dispatch, getState) => {
  const { auth } = getState();

  if (!auth.user) {
    return;
  }

  removeLocalStorageValue(LocalStorageKeys.AccessToken);
  removeLocalStorageValue(LocalStorageKeys.RefreshToken);
  removeLocalStorageValue(LocalStorageKeys.User);
  removeLocalStorageValue(LocalStorageKeys.UserId);

  // Reset whole store (all slices)
  dispatch(resetStore());
};

const changeLanguage =
  (languageCode: LanguageCode): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    return api.finastic.userPreference
      .apiFrontendV1UserPreferenceLanguagePut({
        updateLanguageCommand: {
          languageCode,
        },
      })
      .then(() => {
        const state = getState();

        dispatch(
          setUser({
            ...state.auth.user,
            preferences: {
              ...state.auth.user?.preferences,
              languageCode,
            },
          })
        );
      });
  };

const setPreferredCommunicationChannel =
  (comminucationChannel: CommunicationChannel): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    return api.finastic.userPreference
      .apiFrontendV1UserPreferenceCommunicationChannelPut({
        updateCommunicationChannelCommand: {
          comminucationChannel,
        },
      })
      .then(() => {
        const state = getState();

        dispatch(
          setUser({
            ...state.auth.user,
            preferences: {
              ...state.auth.user?.preferences,
              communicationChannel: comminucationChannel,
            },
          })
        );
      });
  };

const refreshAccessToken =
  (): AppThunk<Promise<any>> => async (dispatch, getState) => {
    const refreshToken = getLocalStorageValue(LocalStorageKeys.RefreshToken);

    if (!refreshToken) {
      throw new Error("No refresh token");
    }

    return api.finastic.authentication
      .apiFrontendV1AuthenticationRefreshTokenPost({
        refreshTokenCommand: {
          refreshToken,
        },
      })
      .then((responseDTO) => {
        setLocalStorageValue(
          LocalStorageKeys.AccessToken,
          responseDTO.accessToken || ""
        );
        setLocalStorageValue(
          LocalStorageKeys.RefreshToken,
          responseDTO.refreshToken || ""
        );
      })
      .catch((error) => {
        dispatch(logOut());

        throw error; // Important! Without is if refresh call fails it enters into endless loop
      });
  };

export const authSliceActions = {
  logIn,
  logOut,
  reset,
  changeLanguage,
  getUser,
  setPreferredCommunicationChannel,
  refreshAccessToken,
  setUser,
};
