import AxfinaLogo from "assets/images/axfina_logo_long_white.svg";
import { useMemo } from "react";
import { BackendCode } from "models/BackendCode";

interface UseLogosProps {
  backendCode?: BackendCode;
  userIsFinasticUser?: boolean;
}

interface UseLogos {
  Logo: string;
}

const useLogos = ({
  backendCode,
  userIsFinasticUser,
}: UseLogosProps): UseLogos => {
  // Commented our code for handling temporary logo for provider if needed

  /* const ProviderLogoForFinasticUser = useMemo(
    () => (backendCode === "o" ? RaportLogoForFinasticUser : AxfinaLogo),
    [backendCode]
  );

  const ProviderLogoForGuest = useMemo(
    () => (backendCode === "o" ? RaportLogoForGuest : AxfinaLogo),
    [backendCode]
  ); */

  const Logo = useMemo(() => {
    return AxfinaLogo;

    // Commented our code for handling temporary logo for provider if needed

    /* if (typeof backendCode !== "string") {
      return AxfinaLogo;
    }

    return userIsFinasticUser
      ? ProviderLogoForFinasticUser
      : ProviderLogoForGuest; */
  }, []);

  return {
    Logo,
  };
};

export default useLogos;
