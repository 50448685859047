import { useAppSelector } from "app/store";
import DatepickerFormik from "components/forms/DatepickerFormik";
import dayjs from "dayjs";
import React, { useMemo } from "react";

const InstalmentDateModalContent: React.FC = () => {
  const { paymentPlanOptionsData } = useAppSelector(
    (state) => state.debts.debtPaymentPlan
  );

  const monthsShown = useMemo(() => {
    if (
      !paymentPlanOptionsData?.firstPossibleInstalmentDueDate ||
      !paymentPlanOptionsData?.lastPossibleInstalmentDueDate
    ) {
      return 0;
    }

    const firstDatesMonth = dayjs(
      paymentPlanOptionsData.firstPossibleInstalmentDueDate
    ).month();

    const lastDatesMonth = dayjs(
      paymentPlanOptionsData.lastPossibleInstalmentDueDate
    ).month();

    return firstDatesMonth === lastDatesMonth ? 1 : 2;
  }, [
    paymentPlanOptionsData?.firstPossibleInstalmentDueDate,
    paymentPlanOptionsData?.lastPossibleInstalmentDueDate,
  ]);

  const availableDates = useMemo(() => {
    if (
      !paymentPlanOptionsData?.firstPossibleInstalmentDueDate ||
      !paymentPlanOptionsData?.lastPossibleInstalmentDueDate
    ) {
      return [];
    }

    const firstDate = paymentPlanOptionsData.firstPossibleInstalmentDueDate;
    const lastDate = paymentPlanOptionsData.lastPossibleInstalmentDueDate;

    const diffBetween2Dates = dayjs(lastDate).diff(firstDate, "days");

    const datesBetween2Dates = [...Array(diffBetween2Dates + 1)]
      .map((_, index) => dayjs(firstDate).add(index, "day").toDate())
      .filter((value) => value.getDate() < 29);

    return datesBetween2Dates;
  }, [
    paymentPlanOptionsData?.firstPossibleInstalmentDueDate,
    paymentPlanOptionsData?.lastPossibleInstalmentDueDate,
  ]);

  return (
    <div className="mt-4">
      <DatepickerFormik
        monthsShown={monthsShown}
        inline
        includeDates={availableDates}
        name="firstInstalmentDueDate"
        hideDayNames
        hideNavigation
        openToDate={paymentPlanOptionsData?.firstPossibleInstalmentDueDate}
      />
    </div>
  );
};

export default InstalmentDateModalContent;
