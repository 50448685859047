import Card from "components/ui/Card/Card";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import Button from "components/ui/Button/Button";
import { H3 } from "components/ui/Headings/Headings";
import { Link } from "react-router-dom";
import InputFormGroup from "components/forms/InputFormGroup";
import { useDispatch } from "react-redux";
import { authSliceActions } from "./authSlice";
import { useAppSelector } from "app/store";
import { FeatureState } from "models/FeatureState";
import Alert from "components/ui/Alert/Alert";
import { registrationParentPath } from "features/registration/RegistrationRouteSwitch";
import Content from "components/layout/Content";
import { appVersionDtoToString } from "features/appVersion/utils";
import useEventLogger from "hooks/useEventLogger";

export interface LoginFormValues {
  email: string;
  password: string;
}

const CardBottom: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center text-center">
      <div className="md:w-72 w-full">
        <span>{t("login.notRegisteredYet")}</span>
        <div className="mt-6">
          <Button
            as={Link}
            block
            to={`/${registrationParentPath}`}
            variant="outline"
          >
            {t("login.registerButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const CardFooter: React.FC = () => {
  const { t } = useTranslation();
  const { appVersion } = useAppSelector((state) => state.appVersion);

  return (
    <div className="text-center">
      <span className="text-xs">
        {t("login.version")} {appVersionDtoToString(appVersion)}
      </span>
    </div>
  );
};

const Login: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { trackAction: trackLoggedIn } = useEventLogger("LoggedIn");

  const { loginState, userState } = useAppSelector((state) => state.auth);

  const hasBELoginError = useMemo(
    () => loginState === FeatureState.Error || userState === FeatureState.Error,
    [loginState, userState]
  );

  const handleSubmit: (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    try {
      trackLoggedIn({});
      await dispatch(authSliceActions.logIn(values));

      await dispatch(authSliceActions.getUser());
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(authSliceActions.reset());
  }, [dispatch]);

  return (
    <Content>
      <div className="my-8">
        <Card bottom={<CardBottom />} footer={<CardFooter />}>
          <div className="flex flex-col items-center">
            <div className="py-8">
              <H3>{t("login.title")}</H3>
            </div>

            <div className="md:w-72 w-full">
              {hasBELoginError && (
                <div className="mb-6">
                  <Alert type="error">
                    {t("login.emailOrPasswordInvalid")}
                  </Alert>
                </div>
              )}
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email(t("validationErrors.email"))
                    .required(t("validationErrors.required")),
                  password: Yup.string()
                    .min(8, t("validationErrors.min", { min: 8 }))
                    .required(t("validationErrors.required")),
                })}
                onSubmit={handleSubmit}
                validateOnMount
              >
                {(formikProps) => (
                  <Form noValidate>
                    <InputFormGroup
                      name="email"
                      type="email"
                      placeholder={t("login.email.placeholder")}
                      forceError={hasBELoginError}
                      autoFocus
                    />

                    <InputFormGroup
                      name="password"
                      type="password"
                      placeholder={t("login.password.placeholder")}
                      forceError={hasBELoginError}
                    />

                    <Button
                      type="submit"
                      isLoading={formikProps.isSubmitting}
                      disabled={!formikProps.isValid}
                      block
                    >
                      {t("login.submitButton")}
                    </Button>

                    <div className="mt-8 text-center">
                      <Link to="forgot-password">
                        {t("login.forgotPassword")}
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </Content>
  );
};

export default Login;
