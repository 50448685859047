import React from "react";
import LoadingSpinner from "./LoadingSpinner";

const ContentLoadingSpinner: React.FC = () => {
  return (
    <div className="text-center">
      <LoadingSpinner className="mt-6 inline-block text-primary text-3xl" />
    </div>
  );
};

export default ContentLoadingSpinner;
