import { useAppSelector } from "app/store";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import RegistrationTimedOut from "../components/RegistrationTimedOut";
import Card from "components/ui/Card/Card";
import { H3, H6 } from "components/ui/Headings/Headings";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Yup from "yup";
import {
  registrationParentPath,
  registrationPaths,
} from "../RegistrationRouteSwitch";
import CardFooter from "../components/CardFooter";
import Button from "components/ui/Button/Button";
import InputNumberFormGroup from "components/forms/InputNumberFormGroup";
import { oneTimePasscodeActions } from "./oneTimePasscode.slice";
import useRetryTimeCounter from "hooks/useRetryTimeCounter";
import Modal from "components/ui/Modal/Modal";
import useFormValidations from "hooks/useFormValidations";
import Alert from "components/ui/Alert/Alert";
import Content from "components/layout/Content";
import { mapPhoneNumberFormGroupValueToString } from "utils/mappers";
import useEventLogger from "hooks/useEventLogger";

export interface OneTimePasscodeFormValues {
  otp: number | null;
}

const OneTimePasscode: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    remainingTimeCountingInProgress,
    secondsRemainingToRetry,
    resetTimer,
  } = useRetryTimeCounter();
  const [passcodeNotMatchModalIsOpen, setPasscodeNotMatchModalIsOpen] =
    useState(false);
  const { otpValidation } = useFormValidations();
  const formRef = useRef<FormikProps<OneTimePasscodeFormValues>>(null);

  const { formValues: previousStepFormValues } = useAppSelector(
    (state) => state.registration.profileData
  );

  const { trackAction } = useEventLogger("RegistrationSubmitOTP");

  const handleSubmit: (
    values: OneTimePasscodeFormValues,
    formikHelpers: FormikHelpers<OneTimePasscodeFormValues>
  ) => void | Promise<any> = async (values, { setSubmitting }) => {
    dispatch(oneTimePasscodeActions.setFormValues(values));

    trackAction({});

    try {
      await dispatch(oneTimePasscodeActions.checkPasscode(values.otp + ""));

      history.push(`/${registrationParentPath}${registrationPaths[2]}`);
    } catch (error) {
      console.error(error);
      setPasscodeNotMatchModalIsOpen(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleResendSMSClick = () => {
    resetTimer();

    formRef.current?.setFieldValue("otp", "");

    dispatch(oneTimePasscodeActions.resendSMS());
  };

  const handlePasscodeNotMatchModalOk = () => {
    handleResendSMSClick();

    setPasscodeNotMatchModalIsOpen(false);
  };

  const handlePasscodeNotMatchModalCancel = () => {
    setPasscodeNotMatchModalIsOpen(false);
  };

  useEffect(() => {
    dispatch(oneTimePasscodeActions.reset());
  }, [dispatch]);

  return previousStepFormValues ? (
    <>
      <Content>
        <Formik<OneTimePasscodeFormValues>
          initialValues={{
            otp: null,
          }}
          validationSchema={Yup.object({
            otp: otpValidation,
          })}
          onSubmit={handleSubmit}
          validateOnMount
          innerRef={formRef}
        >
          {(formikProps) => (
            <Card
              footer={
                <CardFooter<OneTimePasscodeFormValues>
                  formikProps={formikProps}
                  cancelButtonText={t(
                    "registration.oneTimePasscode.cancelButtonText"
                  )}
                  nextButtonText={t(
                    "registration.oneTimePasscode.nextButtonText"
                  )}
                />
              }
              isDense
            >
              <H6>{t("registration.step2")}</H6>
              <H3>{t("registration.oneTimePasscode.title")}</H3>

              <Alert type="success">
                {t("registration.oneTimePasscode.smsSentToNumber")}
                <div className="font-bold">
                  {mapPhoneNumberFormGroupValueToString(
                    previousStepFormValues.phoneNumber
                  )}
                </div>
              </Alert>

              <p className="mb-6 mt-4">
                {t("registration.oneTimePasscode.description1")}
              </p>
              <p className="mb-4">
                {t("registration.oneTimePasscode.description2")}
              </p>

              <Form noValidate>
                <div className="divide-y divide-main">
                  <div>
                    <InputNumberFormGroup
                      name="otp"
                      label={t("registration.oneTimePasscode.otp")}
                      placeholder={t(
                        "registration.oneTimePasscode.otpPlaceholder"
                      )}
                      required
                      thousandSeparator={false}
                      format="######"
                    />
                  </div>
                  <div className="pt-4">
                    <p className="text-center w-44 max-w-full mx-auto mb-4">
                      {t("registration.oneTimePasscode.didntReceiveSMS")}
                    </p>
                    <div className="text-center">
                      <Button
                        type="button"
                        variant="outline"
                        onClick={handleResendSMSClick}
                        disabled={remainingTimeCountingInProgress}
                      >
                        {t("registration.oneTimePasscode.resendSMSButtonText")}{" "}
                        {remainingTimeCountingInProgress && (
                          <>({secondsRemainingToRetry})</>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
          )}
        </Formik>
      </Content>
      <Modal
        isOpen={passcodeNotMatchModalIsOpen}
        title={t("registration.oneTimePasscode.passcodeNotMatchModal.title")}
        description={t(
          "registration.oneTimePasscode.passcodeNotMatchModal.description"
        )}
        okText={t(
          "registration.oneTimePasscode.passcodeNotMatchModal.resendSMSButtonText"
        )}
        cancelText={t(
          "registration.oneTimePasscode.passcodeNotMatchModal.cancelButtonText"
        )}
        type="alert"
        alertType="error"
        onOk={handlePasscodeNotMatchModalOk}
        onCancel={handlePasscodeNotMatchModalCancel}
        showCancelButton
        size="large"
      />
    </>
  ) : (
    <RegistrationTimedOut />
  );
};

export default OneTimePasscode;
