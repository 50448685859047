import Modal from "components/ui/Modal/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const InvalidPaymentIdModal: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Modal
      isOpen
      title={t("paymentLink.paymentLinkInvalidIdModal.title")}
      description={t("paymentLink.paymentLinkInvalidIdModal.description")}
      okText={t("paymentLink.paymentLinkInvalidIdModal.backToDashboardText")}
      type="alert"
      alertType="error"
      onOk={() => history.push("/")}
    />
  );
};

export default InvalidPaymentIdModal;
