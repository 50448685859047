import "./app/i18n";

import React from "react";
import store from "./app/store"; // make sure it's second import to avoid circular dependency issue in api/index.ts
import ReactDOM from "react-dom";
import "./assets/scss/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "app/appInsights";
import { Router } from "react-router";
import { browserHistory } from "app/history";

ReactDOM.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <Router history={browserHistory}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </AppInsightsContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
