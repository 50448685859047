import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";
import Container from "./Container";
import { Link } from "react-router-dom";
import useLogos from "hooks/useLogos";
import { BackendCode } from "models/BackendCode";

interface HeaderProps {
  hideLogo?: boolean;
  userIsFinasticUser?: boolean;
  backendCode?: BackendCode;
}

const Header: React.FC<HeaderProps> = ({
  hideLogo,
  userIsFinasticUser,
  backendCode,
}) => {
  const { Logo } = useLogos({
    backendCode,
    userIsFinasticUser,
  });

  return (
    <header className="fixed w-full bg-secondary z-10">
      <Container>
        <div className="flex items-center h-12">
          {!hideLogo && (
            <Link to="/">
              <img src={Logo} alt="Axfina" className="h-6 mr-8" />
            </Link>
          )}

          <div className="ml-auto">
            <LanguageSwitcher />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
