import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

const getCurrentBreakpoint = (): Breakpoint => {
  const width = window.innerWidth;

  if (width < 576) {
    return "xs";
  } else if (width >= 576 && width < 768) {
    return "sm";
  } else if (width >= 768 && width < 992) {
    return "md";
  } else if (width >= 992 && width < 1140) {
    return "lg";
  } else if (width >= 1140 && width < 1536) {
    return "xl";
  }

  return "2xl";
};

interface UseBreakpoint {
  currentBreakpoint: Breakpoint;
  getCurrentBreakpoint: () => Breakpoint;
}

const useBreakpoint = (): UseBreakpoint => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(() =>
    getCurrentBreakpoint()
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setCurrentBreakpoint(getCurrentBreakpoint());
    }, 200);

    window.addEventListener("resize", calcInnerWidth);

    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return { currentBreakpoint, getCurrentBreakpoint };
};
export default useBreakpoint;
