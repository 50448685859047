import useEventLogger from "hooks/useEventLogger";
import React, { AnchorHTMLAttributes, useCallback } from "react";

interface OuterLinkProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "id"> {
  id: string; // required for analytics
}

const OuterLink: React.FC<OuterLinkProps> = ({ children, ...rest }) => {
  const { trackAction: trackClicked } = useEventLogger("OuterLinkClicked", {
    href: "",
    id: "",
  });

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      trackClicked({ href: rest.href + "", id: rest.id });

      rest.onClick?.(e);

      if (!rest.href) {
        return;
      }

      if (rest.target === "_blank") {
        window.open(rest.href);
      } else {
        window.location.href = rest.href;
      }
    },
    [rest, trackClicked]
  );

  return (
    <a {...rest} onClick={handleClick}>
      {children}
    </a>
  );
};

export default OuterLink;
