import { VersionDto } from "api/generated/finastic";
import semverCompare from "semver-compare";

export const appVersionStringToDto = (value: string): VersionDto => {
  const parsedString = value.split(".");

  return {
    major: Number(parsedString[0]),
    minor: Number(parsedString[1]),
    patch: Number(parsedString[2]),
  };
};

export const appVersionDtoToString = (value: VersionDto): string =>
  `${value.major}.${value.minor}.${value.patch}`;

export const hasAppVersionMismatch = (
  appVersion: VersionDto,
  minimumAppVersionRequiredByApi: VersionDto = { major: 0, minor: 0, patch: 0 }
): boolean =>
  semverCompare(
    appVersionDtoToString(minimumAppVersionRequiredByApi),
    appVersionDtoToString(appVersion)
  ) === 1;
