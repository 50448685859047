import clsx from "clsx";
import React from "react";

interface CardProps {
  children: React.ReactNode;
  bottom?: React.ReactNode; // space above footer etc in Login card "Not registered yet"
  footer?: React.ReactNode;
  isDense?: boolean; // content doesn't expend to borders
  noPadding?: boolean;
}

const Card: React.FC<CardProps> = ({
  children,
  footer,
  bottom,
  isDense,
  noPadding,
}) => {
  return (
    <div className="bg-white shadow rounded-xl w-full border border-main mb-8">
      <div
        className={clsx({ "p-8": !noPadding }, { "max-w-xs mx-auto": isDense })}
      >
        {children}
      </div>
      {bottom && <div className="px-8 py-6 border-t border-main">{bottom}</div>}
      {footer && <div className="px-8 py-4 border-t border-main">{footer}</div>}
    </div>
  );
};

export default Card;
