import { UserProfileDto } from "api/generated/finastic";
import { useAppSelector } from "app/store";
import { FeatureState } from "models/FeatureState";
import { useCallback } from "react";

interface UseAuth {
  userIsLoggedIn: boolean;
  user?: UserProfileDto;
  hasUserRole: (contentRoles?: any[]) => boolean;
  userState: FeatureState;
  loginState: FeatureState;
}

const useAuth = (): UseAuth => {
  const { user, userState, loginState } = useAppSelector((state) => state.auth);

  const userIsLoggedIn = !!user;

  const hasUserRole = useCallback((contentRoles?: any[]) => {
    /* const userRoles = user?.roles || [];

      return (
        !contentRoles ||
        (!!contentRoles.length &&
          contentRoles.some((routeRole) => userRoles.includes(routeRole)))
      ); */

    return true;
  }, []);

  return {
    userIsLoggedIn,
    user,
    hasUserRole,
    userState,
    loginState,
  };
};

export default useAuth;
