import Icon from "@mdi/react";
import { CSSProperties, RefObject, AriaAttributes } from "react";
export interface HTMLProps extends AriaAttributes {
  className?: string;
  role?: string;
}
export interface IconProps extends HTMLProps {
  id?: string;
  path: string;
  ref?: RefObject<SVGSVGElement>;
  title?: string | null;
  description?: string | null;
  size?: number | string | null;
  color?: string | null;
  horizontal?: boolean;
  vertical?: boolean;
  rotate?: number;
  spin?: boolean | number;
  style?: CSSProperties;
  inStack?: boolean;
}

// icon paths from https://materialdesignicons.com/
const MaterialIcon: React.FC<IconProps> = (props) => {
  return <Icon {...props} />;
};

export default MaterialIcon;
