import clsx from "clsx";
import { useField } from "formik";
import React, { InputHTMLAttributes, useMemo } from "react";
import FormGroupError from "./FormGroupError";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  noIndent?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  value,
  required,
  name,
  noIndent,
  ...rest
}) => {
  const [field, meta] = useField(name || "");

  const inCheckboxGroup = useMemo(
    () => Array.isArray(field.value),
    [field.value]
  );

  const hasError = useMemo(
    () => !!meta.touched && !!meta.error,
    [meta.error, meta.touched]
  );

  return (
    <div className={clsx("my-4", { "ml-4": !noIndent })}>
      <div className="flex">
        <label className="inline-flex items-start cursor-pointer">
          <input
            {...field}
            {...rest}
            value={value}
            required={required}
            checked={
              inCheckboxGroup ? field.value.includes(value) : field.value
            }
            type="checkbox"
            className="h-4.5 w-4.5 rounded shadow-formControl border-formControl text-primary cursor-pointer"
          />
          <span className="ml-4 leading-none mt-2px relative top-px">
            {required && (
              <span className="inline-block mr-1 text-error">*</span>
            )}
            {children}
          </span>
        </label>
      </div>

      {!inCheckboxGroup && hasError ? (
        <FormGroupError fieldName={name}>{meta.error}</FormGroupError>
      ) : null}
    </div>
  );
};

export default Checkbox;
