/* tslint:disable */
/* eslint-disable */
/**
 * Finastic Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DebtDetailsDto,
    DebtDetailsDtoFromJSON,
    DebtDetailsDtoToJSON,
    DebtSummaryDto,
    DebtSummaryDtoFromJSON,
    DebtSummaryDtoToJSON,
} from '../models';

export interface ApiFrontendV1DebtDebtIdGetRequest {
    debtId: string;
}

/**
 * 
 */
export class DebtApi extends runtime.BaseAPI {

    /**
     */
    async apiFrontendV1DebtDebtIdGetRaw(requestParameters: ApiFrontendV1DebtDebtIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DebtDetailsDto>> {
        if (requestParameters.debtId === null || requestParameters.debtId === undefined) {
            throw new runtime.RequiredError('debtId','Required parameter requestParameters.debtId was null or undefined when calling apiFrontendV1DebtDebtIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Debt/{debtId}`.replace(`{${"debtId"}}`, encodeURIComponent(String(requestParameters.debtId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DebtDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFrontendV1DebtDebtIdGet(requestParameters: ApiFrontendV1DebtDebtIdGetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DebtDetailsDto> {
        const response = await this.apiFrontendV1DebtDebtIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFrontendV1DebtGetRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DebtSummaryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Frontend/v1/Debt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DebtSummaryDtoFromJSON));
    }

    /**
     */
    async apiFrontendV1DebtGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DebtSummaryDto>> {
        const response = await this.apiFrontendV1DebtGetRaw(initOverrides);
        return await response.value();
    }

}
