import React, { InputHTMLAttributes, useMemo } from "react";
import { useField } from "formik";
import FormGroup from "./FormGroup";
import FormGroupLabel from "./FormGroupLabel";
import FormGroupError from "./FormGroupError";
import Input from "./Input";

interface InputFormGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: "text" | "email" | "password" | "tel" | "number" | "url";
  label?: string;
  forceError?: boolean;
  help?: string;
}

const InputFormGroup: React.FC<InputFormGroupProps> = ({
  name,
  label,
  required,
  help,
  ...rest
}) => {
  const [field, meta] = useField(name || "");

  const hasError = useMemo(
    () => !!meta.touched && !!meta.error,
    [meta.error, meta.touched]
  );

  return (
    <FormGroup>
      {label && (
        <FormGroupLabel required={required} hasError={hasError}>
          {label}
        </FormGroupLabel>
      )}
      <Input
        {...rest}
        field={field}
        required={required}
        name={name}
        hasError={hasError}
      />
      {hasError ? (
        <FormGroupError fieldName={name}>{meta.error}</FormGroupError>
      ) : null}
      {help && (
        <div
          className="text-xs mt-1 text-dataNotAvailableDark"
          dangerouslySetInnerHTML={{
            __html: help,
          }}
        />
      )}
    </FormGroup>
  );
};

export default InputFormGroup;
