import {
  Middleware,
  ResponseContext,
  VersionDto,
} from "api/generated/finastic";
import { browserHistory } from "app/history";
import store from "app/store";
import { appVersionActions } from "features/appVersion/appVersionSlice";
import { authSliceActions } from "features/auth/authSlice";
import { errorActions } from "features/error/errorSlice";
import { maintenanceActions } from "features/maintenance/maintenanceSlice";
import { ErrorKey } from "models/Error";
import { getCurrentGlobalErrorKey } from "utils/common";
import { fetchApiRefreshTokenHandler } from "utils/fetchApiRefreshTokenHandler";

const exampleError = {
  type: "https://tools.ietf.org/html/rfc7231#section-6.5.1",
  title: "FinasticException",
  status: 400,
};

const handleGlobalErrors = async (error: RequestErrorBody) => {
  try {
    const modalErrorKey = getCurrentGlobalErrorKey(error);

    store.dispatch(errorActions.setCurrentGlobalErrorKey(modalErrorKey));
    store.dispatch(errorActions.setCurrentGlobalErrorHappened(true));
  } catch (error) {
    console.error(error);
  }
};

const isRequestErrorBodyBackendUnavailable = (
  error: RequestErrorBody
): error is RequestErrorBodyBackendUnavailable => {
  return Object.prototype.hasOwnProperty.call(
    error.errors,
    "BackendUnavailable"
  );
};

const isRequestErrorBodyUnsupportedCurrency = (error: RequestErrorBody) => {
  return Object.prototype.hasOwnProperty.call(
    error.errors,
    "UnsupportedCurrency"
  );
};

const handleBackendUnavailableError = async (
  errorJson: RequestErrorBodyBackendUnavailable
) => {
  if (errorJson.isScheduledMaintenance) {
    // If it's a scheduled maintenance, redirect user to maintenance page
    store.dispatch(
      maintenanceActions.setScheduledMaintenanceEndTime(
        errorJson.scheduledMaintenanceEndTime
      )
    );
    browserHistory.push("/maintenance");
  } else {
    // If it's not a scheduled maintenance, display global modal
    store.dispatch(errorActions.setCurrentGlobalErrorKey("BackendUnavailable"));
    store.dispatch(errorActions.setCurrentGlobalErrorHappened(true));
  }

  return Promise.resolve();
};

export type RequestErrorBody = typeof exampleError & {
  errors: { [key in ErrorKey]: any[] };
};

export type RequestErrorBodyBackendUnavailable = RequestErrorBody & {
  isScheduledMaintenance: boolean;
  scheduledMaintenanceEndTime: string;
};

export const errorHandlingMiddleware: Middleware[] = [
  {
    post: async (ctx: ResponseContext): Promise<void> => {
      const responseText = await ctx.response.text();

      let errorJson:
        | RequestErrorBody
        | RequestErrorBodyBackendUnavailable
        | undefined;

      if (responseText !== "") {
        errorJson = JSON.parse(responseText);
      }

      if (
        ctx.response.status === 401 &&
        !ctx.response.url.endsWith("Authentication/RefreshToken")
      ) {
        return fetchApiRefreshTokenHandler({
          ctx,
          asyncTokenRefresher: () =>
            store.dispatch(authSliceActions.refreshAccessToken()),
        }).catch((error) => console.error(error));
      }

      if (
        ctx.response.status >= 500 &&
        ctx.response.status !== 503 &&
        (!errorJson ||
          (errorJson && !isRequestErrorBodyUnsupportedCurrency(errorJson)))
      ) {
        store.dispatch(errorActions.setCriticalErrorHappened(true));

        return Promise.resolve();
      }

      if (
        ctx.response.status === 503 &&
        errorJson &&
        isRequestErrorBodyBackendUnavailable(errorJson)
      ) {
        return handleBackendUnavailableError(errorJson);
      }

      // If 503 but not BackendUnavailable
      if (ctx.response.status === 503) {
        store.dispatch(
          errorActions.setCurrentGlobalErrorKey("FinasticUnavailable")
        );
        store.dispatch(errorActions.setCurrentGlobalErrorHappened(true));

        return Promise.resolve();
      }

      if (
        ctx.response.status === 400 &&
        ctx.response.headers.get("x-minimum-app-version")
      ) {
        store.dispatch(
          appVersionActions.setMinimumAppVersionRequiredByApi(
            JSON.parse(
              ctx.response.headers.get("x-minimum-app-version") || ""
            ) as VersionDto
          )
        );
      }

      if (ctx.response.status >= 400 && errorJson) {
        await handleGlobalErrors(errorJson);
      }

      return Promise.resolve();
    },
  },
];
