import { combineReducers } from "@reduxjs/toolkit";
import { emailConfirmationReducer } from "./EmailValidation/EmalConfirmation/emailConfirmation.slice";
import { resetPasswordReducer } from "./EmailValidation/EmalConfirmation/resetPassword.slice";
import { forgotPasswordHomeReducer } from "./Home/forgotPasswordHome.slice";
import { smsPasscodeReducer } from "./SMSPasscode/smsPasscode.slice";
import { smsResetPasswordReducer } from "./SMSPasscode/SMSResetPassword/smsResetPassword.slice";

const forgotPasswordReducers = combineReducers({
  home: forgotPasswordHomeReducer,
  smsPasscode: smsPasscodeReducer,
  smsResetPassword: smsResetPasswordReducer,
  emailConfirmation: emailConfirmationReducer,
  resetPassword: resetPasswordReducer,
});

export default forgotPasswordReducers;
