import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "components/ui/Button/Button";

export interface PayPointPaymentProps {
  barcodeId: string;
}

const PayPointPayment: React.FC<PayPointPaymentProps> = ({ barcodeId }) => {
  const { t } = useTranslation();
  return (
    <>
      <div id="PayPointTab" className="md:px-12 p-4 md:pb-4 pb-0 pt-0">
        {t("debt.repayment.payPointTabInfo")}
      </div>
      <div className="py-4 md:px-8 px-4 border-t border-main">
        <div className="flex justify-between -my-2 sm:flex-row flex-col-reverse items-start">
          <div className="my-2 min-w-cardButton">
            <Button variant="outline" as={Link} to="/" block>
              {t("debt.repayment.cancelButtonText")}
            </Button>
          </div>

          <div className="my-2 min-w-cardButton">
            <Button block as={Link} to={`/barcode/${barcodeId}`}>
              {t("debt.repayment.showBarcodeButtonText")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default PayPointPayment;
