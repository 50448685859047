import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GlobalErrorKey } from "models/Error";

export interface ErrorState {
  criticalErrorHappened: boolean;
  currentGlobalErrorHappened: boolean;
  currentGlobalErrorKey?: GlobalErrorKey;
}

const initialState: ErrorState = {
  criticalErrorHappened: false,
  currentGlobalErrorHappened: false,
  currentGlobalErrorKey: undefined,
};

const error = createSlice({
  name: "error",
  initialState,
  reducers: {
    reset: () => initialState,
    setCriticalErrorHappened(state, { payload }: PayloadAction<boolean>) {
      state.criticalErrorHappened = payload;
    },
    setCurrentGlobalErrorHappened(state, { payload }: PayloadAction<boolean>) {
      state.currentGlobalErrorHappened = payload;
    },
    setCurrentGlobalErrorKey(
      state,
      { payload }: PayloadAction<GlobalErrorKey | undefined>
    ) {
      state.currentGlobalErrorKey = payload;
    },
  },
});

const {
  reset,
  setCriticalErrorHappened,
  setCurrentGlobalErrorKey,
  setCurrentGlobalErrorHappened,
} = error.actions;

export const errorReducer = error.reducer;

export const errorActions = {
  reset,
  setCriticalErrorHappened,
  setCurrentGlobalErrorKey,
  setCurrentGlobalErrorHappened,
};
